import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import AcademicInfo from "./Form-components/AcademicInfo";
import AdditionalInfo from "./Form-components/AdditionalInfo";
import PersonalInfo from "./Form-components/PersonalInfo";
import { Formik, Form } from "formik";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loading from "../../components/loading/loading";
import Subjects from "./Form-components/subject";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NextButton = styled(Button)({
  backgroundColor: "#FFC107",
  color: "white",
  padding: "5px 35px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#FFCC39",
  },
});

const PrevButton = styled(Button)({
  backgroundColor: "white",
  color: "#FFC107",
  padding: "5px 35px",
  borderColor: "#FFC107",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "white",
    borderColor: "#FFCC39",
  },
});

const RenewalEnroll = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { keycloak } = useKeycloak();
  const [page, setPage] = useState(0);
  const [incomingDate, setIncomingDate] = useState([]);
  const [acceptUseTerms, setAcceptUseTerms] = useState(false);
  const [files, setFiles] = useState({});
  

  const [open, setOpen] = useState(false);
  const [toast, setToast] = useState({ message: "", severity: "success" });
  const handleClick = (m, s) => {
    setToast({ message: m, severity: s });
    setTimeout(() => setOpen(true), 200);
  };
  const userId = keycloak.idTokenParsed.sub;
console.log("fo",userId )
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const [uploadedImage, setUploadedImage] = useState(null);
  const [formData, setFormData] = useState({
    year: "1º Ano",
    graduation: "",
    "course": "",
    "documentType": "",
    "documentNumber": "",
    "name": "",
    "birthdate": "",
    "motherName": "",
    "fatherName": "",
    "gender": "",
    "maritalStatus": "",
    "nationality": "",
    "province": "",
    "county": "",
    "householdSize": "",
    "specialNeeds":false,
    "schoolOrigen": "",
    "natureoftheschoolofprovenance": "",
    "nameOfTheHighSchoolCourse": "",
    "highSchoolFinalGrade": "",
    "studyFinancing": "",
    "studentRegime": "",
    "yearsOfEntryTheCourse": " ",
    "studyInclusively": true,
    "phone": "",
    "email": "",
 

    entryType: "",
       schoolIssuingProfessionalDegree: "",
        stateAgency: "",
     
        profession: "",
        functionPerformed: "",
     
        workingInStateAgency: false,
        professionalDegreeConferredBySchool: false,
        deaf: false,
        motorDeficiency: false,
        mediumTechnician: false,
        seniorTechnician: false,
        police: false,
        military: false,
        blind: false,
        otherDeficiency:false,
       
        teacher:false,
        administrationEmployee:false,
});
  const fetchStudentData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/students/user-account/${userId}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          userAcountID: userId,
        },
      });
  
      console.log("Resposta da API:", response.data);
      const studentData = response.data;
  console.log("stu",studentData )
  setIncomingDate(studentData);
  console.log("studa",incomingDate)
  const enrollId = studentData.enroll.id;
      console.log("ID do enroll:", enrollId);
    } catch (error) {
      console.error("Erro ao buscar dados do aluno:", error);
    }
  };
  
  useEffect(() => {
    // Chame a função de busca de dados do aluno ao montar o componente
    fetchStudentData();
  }, []);
  
  const renewEnrollment = async (enrollId, studentData) => {
    try {
     
      // Array com as propriedades permitidas
      const allowedProperties = [
        "name",
        "maritalStatus",
        "householdSize",
        "phone",
        "email",
        "studyInclusively",
        // Adicione outras propriedades permitidas conforme necessário
      ];
  
      // Remova as propriedades indesejadas do objeto studentData e converta phone para um número inteiro
      const cleanStudentData = Object.keys(studentData)
        .filter(key => allowedProperties.includes(key))
        .reduce((obj, key) => {
          if (key === "phone") {
            obj[key] = parseInt(studentData[key], 10); // Converte para número inteiro
          } else {
            obj[key] = studentData[key];
          }
          return obj;
        }, {});
  
      console.log("Clean Student Data:", cleanStudentData);
  
      const response = await axios.post(
        `${apiUrl}/enroll/${enrollId}/renew`,
        { student: cleanStudentData },
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        }
      );
  
      console.log("Resposta da renovação:", response.data);
      handleClick("Matrícula renovada com sucesso", "success");
    } catch (error) {
      console.error("Erro ao renovar a matrícula:", error);
      handleClick("Erro ao renovar a matrícula", "error");
    }
  };
  

  return (
<ThemeProvider theme={localCustomTheme}>

    <div style={{ backgroundColor: "white", padding: "80px", paddingTop: "55px" }}>
      <Snackbar open={open} anchorOrigin={{ vertical: "top  ", horizontal: "center" }} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={toast?.severity} sx={{ width: '100%' }}>
          {toast?.message}
        </Alert>
      </Snackbar>

      <Formik
      
        initialValues={{  ...formData }}
        
        onSubmit={(values, { setSubmitting }) => {
          // Chame a função de renovação passando o ID da matrícula (você precisa obter esse ID de algum lugar)
          renewEnrollment(incomingDate.enroll.id, values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form>
            {page === 0 && (
              
                <AcademicInfo incomingDate={incomingDate}  setFiles={setFiles} setFormData={setFormData} errors={errors} touched={touched} values={values}/>
              
            )}
            {page === 1 && (
              
                <PersonalInfo  incomingDate={incomingDate} 
                  uploadedImage={uploadedImage} setUploadedImage={setUploadedImage}
                  setFormData={setFormData} setFiles={setFiles} files={files} values={values} errors={errors} touched={touched}/>
              
            )}
            {page === 2 && (
              
                <AdditionalInfo id={incomingDate.id} 
                  errors={errors} touched={touched}
                  files={files}
                  acceptUserTermsProps={{acceptUseTerms, setAcceptUseTerms}}
                  values={values}
                  setFormData={setFormData}
                  setFiles={setFiles}
                />
              
            )}
            {page === 3 && (
              
                <Subjects id={incomingDate.id}  setFiles={setFiles} setFormData={setFormData} errors={errors} touched={touched} values={values}/>
              
            )}
            <div className="button-group" style={{ paddingTop: "100px" }}>
              <PrevButton
                variant="outlined"
                onClick={() => setPage(page - 1)}
                disabled={page === 0}
              >
                Anterior
              </PrevButton>
              {page !==  2 && (
                <NextButton
                  type="button"
                  variant="contained"
                  onClick={() => setPage(page + 1)}
                >
                  Próximo
                </NextButton>
              )}
              {page === 2 && (
                <NextButton
                  type="submit"
                  disabled={!acceptUseTerms || isSubmitting}
                  variant="contained"
                >
                  Submeter
                </NextButton>
              )}
            </div>
            {isSubmitting && <Loading />}
          </Form>
        )}
      </Formik>
    </div>
    </ThemeProvider>
  );
};

export default RenewalEnroll;
 
const localCustomTheme = createTheme({
  typography: {
    h1: {
      fontSize: 20,
      fontWeight: 600,
      color: "#003B76",
    },
    h3: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: "18.77px",
      color: "#42474B",
    },
    h4: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "normal",
      color: "#42474B",
    },
    fontFamily: ["Work Sans", "sans-serif"].join(","),
    color: "#42474B",
    fontWeight: 500,
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
    
          borderColor: "black",
          borderWidth: "1px",
          height: "40px",
          "&.Mui-focused": {
            outline: "none",
            borderColor: "#C3CAD9",
          },
          "&:hover": {
            borderColor: "#C3CAD9", // set to the same color as the default state
          },
          "&.MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#C3CAD9", // set to the same color as the default state
            },
            "&:hover fieldset": {
              borderColor: "#C3CAD9", // set to the same color as the default state
            },
            "&.Mui-disabled": {
              backgroundColor: "#F6F6F6",
              opacity:"1",
              borderColor: "#F6F6F6", // set to the same color as the default state
          
            },
          },
        
          
          
        },
      },
    },
  }
})