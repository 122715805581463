import { Button, Grid, Typography } from "@mui/material";
import axios from "axios";
import "./styles/personal-info.css";
import profile from "../../../assets/profile.svg";
import Select from "../../../components/form-components/select";
import Textfield from "../../../components/form-components/text-field";
import DateTimePicker from "../../../components/form-components/date-time-picker";
import UploadButton from "../../../components/form-components/upload-button";
import { useKeycloak } from "@react-keycloak/web";
import { useState } from "react";
import { useEffect } from "react";

const inputStyles = {
  fontSize: "14px",
  fontWeight: "400",
  color: "#333", // Cor de texto desejada
  width: "470px", // Defina a largura desejada
  height: "35px", // Adicione outros estilos conforme necessário
};


const genders = {
  id_1: "Masculino",
  id_2: "Feminino",
};

const gendersValues = Object.values(genders);
const defaultGender = gendersValues[0];

const PersonalInfo = ({
  id,
  setFiles,
  files,
  errors,
  setFormData,
  values,
  disabled,
  edit,
  setUploadedImage,
  uploadedImage,
  incomingDate,
  handleClick,
}) => {
  console.log("numero", values);
  const [imageId, setImageId] = useState(null);
  const { keycloak } = useKeycloak();
  const [DocumentTypes, setDocumentTypes] = useState([]);
  const [province, setProvince] = useState([]);
  const [municipe, setMunicipe] = useState([]);
  const [gender, setGender] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [profilePic, setProfilePic] = useState(null);
  const isSecretaria =
    keycloak?.tokenParsed?.resource_access?.["sga-api"]?.roles?.includes(
      "Secretaria"
    );
  const isAluno =
    keycloak?.tokenParsed?.resource_access?.["sga-api"]?.roles?.includes(
      "Aluno"
    );
  const headingText = isAluno ? "Renovação de matrícula" : "Inscreva-se";
  const welcomeText = isAluno
    ? "Inscreva-se para garantir que tenhamos todas as informações necessárias para sua renovação."
    : "Inscreva-se para garantir que tenhamos todas as informações necessárias para sua matrícula, solicitamos que preencha o formulário de dados biográficos abaixo com precisão.";

  const getFile = async (id, name, set) => {
    if (id)
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/enrollAttachment/find/${name}/${id}`,
          {
            headers: {
              Authorization: "Bearer " + keycloak.token, // token de autenticação
            },
          }
        );
        console.log("Profile PIC:", response);

        set(response.data?.data ? response.data.data : response.data);
      } catch (error) {
        console.error(error);
      }
  };

  useEffect(() => {
  
    getFile(id, "ProfilePic", setProfilePic);

  }, [keycloak.token, id]);
  const uploadFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append("ProfilePic", file); // O nome 'file' deve corresponder ao esperado pela API

      const response = await axios.post(
        `${apiUrl}/enrollAttachment`,
        formData,
        {
          "Content-Type": "multipart/form-data", // Importante para enviar arquivos
        }
      );

      // A resposta da API pode conter informações úteis, dependendo do seu caso
      console.log("Resposta da Requisição de Upload:", response);

      // Você pode atualizar o estado com o ID da imagem ou outras informações relevantes da resposta
      setImageId(response.data.imageId);
    } catch (error) {
      console.error("Erro ao fazer upload do arquivo:", error);
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFiles((o) => {
        o.ProfilePic = file;
        return o;
      });
    }
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setUploadedImage(e.target.result);
      };

      reader.readAsDataURL(file);

    }
  };

  const getList = async (type, set) => {
    try {
      const response = await axios.get(`${apiUrl}/${type}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token, // token de autenticação
        },
      });
      console.log("Resposta da Requisição:", response);
      set(response.data?.data ? response.data.data : response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getStudent(values.acessExam, values.course, values.graduation);
  }, [values.acessExam, values.course, values.graduation]);

  useEffect(() => {
    getList("document-types", setDocumentTypes);
    getList("province", setProvince);
    getList("municipe", setMunicipe);
    getList("gender", setGender);
    getList("marital-status", setMaritalStatus);
  }, []);
  const getStudent = async (id, course, graduation) => {
    await axios
      .get(
        `${apiUrl}/student-evaluated/process/${id}/course/${course}/graduation/${graduation}`
      )
      .then((data) => {
        values.name = data.data?.studentName;
        values.gender = data.data?.gender;
        setFormData({ ...values });
      })
      .catch((error) => {
       
        setFormData({ ...values });
      });
  };
  const [updatingMechanographic, setUpdatingMechanographic] = useState("");

  const handleUpdateMechanographic = async (mechanographicNumber, id) => {
    try {
      setUpdatingMechanographic(true);

      // Convert the mechanographic number to an integer before making the API call
      const mechanographicValue = parseInt(mechanographicNumber, 10);
      const studentId = values?.id;

      await axios.put(
        `${apiUrl}/students/${studentId}/mechanographic-number`,
        { mechanographicNumber: mechanographicValue },
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        }
      );

      // Optionally, you can update the state or take other actions upon successful update
      // For example, you can show a success notification
      handleClick("Nº Mecanográfico Atualizado", "success");
    } catch (error) {
      // Handle the error, show an error notification, etc.
      handleClick(
        error.response?.data?.message?.message ||
          "Erro ao atualizar Nº Mecanográfico",
        "error"
      );
    } finally {
      setUpdatingMechanographic(false);
    }
  };

  useEffect(() => {
    // Use os valores de incomingDate conforme necessário
    if (incomingDate) {
      values.name = incomingDate.name;
      values.gender = incomingDate.gender;
      values.fatherName = incomingDate.fatherName;
      values.motherName = incomingDate.motherName;
      values.birthdate = incomingDate.birthdate;
      values.documentType = incomingDate.documentType;
      values.documentNumber = incomingDate.documentNumber;
      values.acessExam = incomingDate.acessExam;
      values.mechanographicNumber = incomingDate.mechanographicNumber;
      values.maritalStatus = incomingDate.maritalStatus;
      values.phone = incomingDate.phone;
      values.email = incomingDate.email;
      values.nationality = incomingDate.nationality;
      values.province = incomingDate.province;
      values.county = incomingDate.county;
      values.householdSize = incomingDate.householdSize;
      values.mechanographicNumber = incomingDate.mechanographicNumber;
      // Continue atribuindo os valores conforme necessário...

      // Certifique-se de chamar setFormData para atualizar os valores
      setFormData({ ...values });
    }
  }, [incomingDate]);

  return (
    <div className="personal-info">
      {disabled ? null : (
        <>
          <h2>{headingText}</h2>
          <Typography
            sx={{ textAlign: "justify", color: "#949494" }}
            variant="p"
          >
            {welcomeText}
          </Typography>{" "}
        </>
      )}
      <Typography
        sx={{ paddingTop: "38px", paddingBottom: "20px", color: "#003B76" }}
        variant="h3"
      >
        Dados Biográficos
      </Typography>

      <label htmlFor="profilePicInput" className="profile-pic">
        {uploadedImage ? (
          <img width="200px" src={uploadedImage} alt="Uploaded Profile Pic" />
        ) : profilePic ? (
          <img
            width="200px"
            src={`${process.env.REACT_APP_API_URL}/enrollAttachment/download/${profilePic.id}`}
            alt="Downloaded Profile Pic"
          />
        ) : (
          <img width="200px" src={profile} alt="Profile Pic" />
        )}
      </label>
      <input
        onClick={(e) => (disabled ? e.preventDefault() : null)}
        type="file"
        id="profilePicInput"
        accept="image/*"
        onChange={handleImageUpload}
        style={{ display: "none" }}
      />

   

      <Grid container spacing={4}>
        <Grid item sm={3} md={2.3} xs={8}>
          <Select
            name="documentType"
            disabled={disabled || isAluno}
            options={DocumentTypes}
            label={
              <span>
                Tipo documento<span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
        <Grid item sm={3} md={2.3} xs={8}>
          <Textfield
            name="documentNumber"
            disabled={disabled || isAluno}
            label={
              <span>
                Nº documento<span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
        {!isAluno && (
          <Grid item sm={3} md={3} xs={8}>
            <Textfield
              name="acessExam"
              disabled={disabled || isAluno}
              type="number"
              label={
                <span>
                  Nº de Prova de Acesso<span className="red-asterisk">*</span>
                </span>
              }
            />
          </Grid>
        )}
        <Grid item sm={3} md={4} xs={8} sx={{ display: "flex" }}>
          <Grid item sm={3} md={9} xs={8}>
            {keycloak?.authenticated && (
              <Textfield
                name="mechanographicNumber"
                disabled={isAluno || !isSecretaria}
                label={
                  <span>
                    Nº Mecanográfico
                    <span className="red-asterisk">*</span>
                  </span>
                }
              />
            )}
          </Grid>
          <Grid
            item
            sm={3}
            md={2}
            xs={8}
            style={{ marginTop: "25px", marginLeft: "7px" }}
          >
            {isSecretaria && (
              <Button
                variant="contained"
                disabled={updatingMechanographic}
                onClick={() => {
                  if (!updatingMechanographic)
                    handleUpdateMechanographic(
                      values.mechanographicNumber,
                      values.id
                    );
                }}
              >
                {updatingMechanographic ? "Enviado" : "Enviar"}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item sm={12} md={5} xs={8}>
          <Textfield
            disabled={!isAluno}
            name="name"
            label={
              <span>
                Nome<span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
        <Grid item sm={3} md={3} xs={8}>
          <DateTimePicker
            name="birthdate"
            disabled={disabled || isAluno}
            label={
              <span>
                Data Nascimento<span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} style={{ marginTop: "5px" }}>
        <Grid item sm={12} md={5} xs={8}>
          <Textfield
            disabled={disabled || isAluno}
            name="fatherName"
            label={
              <span>
                Nome Pai<span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
        <Grid item sm={12} md={5} xs={8}>
          <Textfield
            disabled={disabled || isAluno}
            name="motherName"
            label={
              <span>
                Nome Mãe<span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={4.5} style={{ marginTop: "5px" }}>
        <Grid item sm={3} md={2.3} xs={8}>
          <Select
            disabled
            name="gender"
            options={gender}
            label={
              <span>
                Sexo<span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
        <Grid item sm={3} md={2.1} xs={8}>
          <Select
            disabled={disabled}
            options={maritalStatus}
            name="maritalStatus"
            label={
              <span>
                Estado Civil<span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>

        <Grid item sm={12} md={5} xs={8}>
          <Textfield
            disabled={disabled || isAluno}
            name="nationality"
            label={
              <span>
                Nacionalidade<span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} style={{ marginTop: "5px" }}>
        <Grid item sm={12} md={5} xs={8}>
          <Select
            name="province"
            disabled={disabled || isAluno}
            onChange={(e) => {
              setMunicipe(
                province?.find((p) => p.id == e.target.value)?.municipes
              );
            }}
            options={province}
            label={
              <span>
                Província<span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
        <Grid item sm={12} md={5} xs={8}>
          <Select
            name="county"
            disabled={disabled || isAluno}
            options={municipe}
            label={
              <span>
                Município<span className="red-asterisk">*</span>
              </span>
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} style={{ marginTop: "5px" }}>
        <Grid item sm={3} md={3} xs={8}>
          <Textfield
            name="householdSize"
            disabled={disabled}
            label={
              <span>
                Dimensão de Agregado Familiar
                <span className="red-asterisk">*</span>
              </span>
            }
            type="number"
            className="your-textfield-css-class" // Add your desired CSS class here
            style={inputStyles}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} style={{ marginTop: "5px" }}>
        <Grid item sm={3} md={3} xs={8}>
          <p
            style={{
              fontSize: "13px",
              fontWeight: "600",
              color: "#42474B",
              margin: "0px",
            }}
          >
            <span>
              Documento de Identificação<span className="red-asterisk">*</span>
            </span>
          </p>

          <UploadButton
            edit={edit}
            disabled={disabled}
            id={id}
            set={setFiles}
            name="DocIdentification"
            files={files}
            apiEndpoint={`${process.env.REACT_APP_API_URL}/enrollAttachment`}
            entityParamFirst={false}
          />
        </Grid>
      </Grid>
     
    </div>
  );
};

export default PersonalInfo;
