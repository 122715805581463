import { Grid, Paper, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import TimeSlot from './TimeSlot';
import { removeAccents } from '../functions';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';
import PropTypes from 'prop-types';
export default function CardSchedules({ 
  schedule,
  groups,
  classrooms,
  disciplines,
  setSchedule,
  updateScheduleWithTeacher,
  selectedStrand,
  selectedPeriod,
  viewOnly
}) {
  const { keycloak } = useKeycloak();
  const apiUrl= process.env.REACT_APP_API_URL;
  const [days, setDays] = useState([]);
  const [times, setTimes] = useState([]);

  const getList = useCallback(async (type, set) => {
    try {
      const response = await axios.get(`${apiUrl}/${type}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });

      set(response.data?.data ? response.data.data : response.data);
      return response.data?.data ? response.data.data : response.data;
    } catch (error) {
      console.error(error);
    }
  });

  useEffect(() => {
    getList("teaching-days", setDays);
    getList("teaching-time", setTimes);
  }, [apiUrl]);

  return (
    <div>
      <Grid  style={{ marginTop: "20px", marginLeft: "-10px", border: "1px solid #ccc", borderRadius: "4px", backgroundColor: "#fff" }} container>
        {/* Coluna para os horários */}
        <Grid style={{ marginTop: "60px"}} item xs={1}>
          {times.filter(elemento => removeAccents(elemento.period) === removeAccents(selectedPeriod[0]?.name)).map((time) => (
            <span key={time.id} style={{ minHeight: 70, borderCollapse: "collapse", padding: 8, display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: 'center' }}>
              <Typography>{time.name}</Typography>
            </span>
          ))}
        </Grid>
        {/* Colunas para cada dia da semana */}
        {days.map((day) => (
          <Grid item xs key={day.id}>
            <h2 style={{ fontSize: "14px", margin: "5px 0px", color: "#212529" }}>
              {day.name}
            </h2>
            {times.filter(elemento => removeAccents(elemento.period) === removeAccents(selectedPeriod[0]?.name)).map((time) => (
              <Paper key={`${day.id}-${time.id}`} style={{ textAlign: 'center' }}>
                <TimeSlot
                  key={`${day.id}#${time.id}`}
                  id={`${day.id}#${time.id}`}
                  schedule={schedule}
                  groups={groups}
                  classrooms={classrooms}
                  disciplines={disciplines}
                  setSchedule={setSchedule}
                  updateScheduleWithTeacher={updateScheduleWithTeacher}
                  selectedStrand={selectedStrand}
                  selectedPeriod={selectedPeriod}
                  viewOnly={viewOnly}
                />
              </Paper>
            ))}
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
CardSchedules.propTypes = {
  schedule: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  classrooms: PropTypes.array.isRequired,
  disciplines: PropTypes.array.isRequired,
  setSchedule: PropTypes.func.isRequired,
  updateScheduleWithTeacher: PropTypes.func.isRequired,
  selectedStrand: PropTypes.string.isRequired,
  selectedPeriod: PropTypes.string.isRequired,
  viewOnly: PropTypes.bool.isRequired, // Adjust the type according to your needs
};
