import { Grid, styled } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import axios from "axios";
import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ActionButtons from "../../../components/actions-buttom/ActionButtons";
import CustomPagination from "../../../components/pagination";
import { i18n } from "../../../translate/i18n";
import { useKeycloak } from "@react-keycloak/web";
import Loading from "../../../components/loading/loading";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
   [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F8FAFD",
      color: "#939a9f",
   },
   [`&.${tableCellClasses.body}`]: {},
}));

export default function CoordinatorCourseListingPage() {
   const [incomingData, setIncomingData] = useState([]);
   const [page, setPage] = useState(localStorage.getItem("coursePage") || 1);
   let totalPages =0;
   const apiUrl = process.env.REACT_APP_API_URL;
   const { keycloak } = useKeycloak();
   const [loading, setLoading] = useState(false);
   const navigate = useNavigate();

   const handleEditCourse = (id) => {
      navigate(`/courses/${id}/edit`);
   };

   const handleDisplayDataToPreview = (id) => {
      navigate(`/courses/${id}/?disabled=true`);
   };

   const pageSeter = (page) => {
      setPage(parseInt(page));
      localStorage.setItem("coursePage", page);
   };

   console.log("keycloak.token:", keycloak.token);

   useEffect(() => {
      setLoading(true);
      if (!keycloak) {
         return;
      }

      const getCoordinatorByUserId = async () => {
         const params = new URLSearchParams({ page: page });
         const userId = keycloak.idTokenParsed.sub;

         try {
            const response = await axios.get(`${apiUrl}/employees/user-account/${userId}?` + params.toString(), {
               headers: { Authorization: "Bearer " + keycloak.token },
            });

            const coordinatorData = response?.data;
            console.log("coordinatorData:", coordinatorData);

            const profileResponse = await axios.get(`${apiUrl}/coordination/${coordinatorData?.id}/courses?`, {
               headers: {
                  Authorization: "Bearer " + keycloak.token,
               },
            });

            const profileData = profileResponse?.data || profileResponse?.data?.data || {};
            setIncomingData(profileData);

            if (localStorage.getItem("coursePage")) pageSeter(response.data.page);
         } catch (error) {
            console.error("Erro ao obter dados do coordenador:", error);
         } finally {
            setLoading(false);
         }
      };

      getCoordinatorByUserId();
   }, [keycloak]);

   const solveDate = (incomingData) => {
      let d = new Date(incomingData[0]?.schoolYear?.startDate);
      let EndDate = new Date(incomingData[0]?.schoolYear?.endDate);
      return (
         incomingData[0]?.schoolYear?.startDate &&
         d.getFullYear() + (incomingData[0]?.schoolYear?.endDate && "-" + EndDate.getFullYear())
      );
   };

   return (
      <>
         <Grid container component={Paper}>
            {loading && <Loading />}
            <Grid item xs={12}>
               {loading ? null : (
                  <TableContainer sx={{ overflow: "hidden" }}>
                     <Table sx={{ minWidth: 650, border: "1px solid #E9ECF5" }} size="small" aria-label="a dense table">
                        <TableHead>
                           <TableRow>
                              <TableCell align="left" colSpan={12} style={{ height: "59px" }}>
                                 <Typography
                                    variant="h3"
                                    sx={{
                                       display: "flex",
                                       alignItems: "center",
                                       justifyContent: "space-between",
                                    }}
                                 >
                                    {i18n.t("course.title")}{" "}
                                 </Typography>
                              </TableCell>
                           </TableRow>
                           <TableRow height="56px" colSpan={12} sx={{ backgroundColor: "#F8FAFD", width: "100%" }}>
                              <StyledTableCell align="center"> {i18n.t("others.schoolYear")} </StyledTableCell>
                              <StyledTableCell align="center"> {i18n.t("others.name")} </StyledTableCell>
                              <StyledTableCell align="center"> {i18n.t("others.graduationType")} </StyledTableCell>
                              <StyledTableCell align="center"> {i18n.t("others.year")} </StyledTableCell>
                              <StyledTableCell align="center"> {i18n.t("others.options")} </StyledTableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {incomingData?.map(
                              (item, i) => (
                                 
                                 (
                                    <TableRow  key={item.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                       <TableCell align="center">{solveDate(incomingData)}</TableCell>
                                       <TableCell align="center">{item.course[0]?.name}</TableCell>
                                       <TableCell align="center">{item.course[0]?.graduation}</TableCell>
                                       <TableCell align="center">{item.course[0]?.totalNumberOfYears}</TableCell>
                                       <TableCell align="center">
                                          {
                                             <ActionButtons
                                                id={item.course[0]?.id}
                                                handleVisualize={handleDisplayDataToPreview}
                                                handleEdit={handleEditCourse}
                                             />
                                          }
                                       </TableCell>
                                    </TableRow>
                                 )
                              )
                           )}
                        </TableBody>
                     </Table>
                  </TableContainer>
               )}
            </Grid>
         </Grid>
         {!loading && (
            <Grid item xs={12}>
               <CustomPagination
                  totalPages={totalPages}
                  setCurrentPage={pageSeter}
                  currentPage={localStorage.getItem("schedulesPage")}
               />
            </Grid>
         )}
      </>
   );
}
