import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ActionButtons from "../../components/actions-buttom/ActionButtons";
import CustomPagination from "../../components/pagination";
import { i18n } from "../../translate/i18n";
import { useKeycloak } from "@react-keycloak/web";
import AutoSearch from "../../components/autoSearch/AutoSearch";
import FilterList from "../../components/filterList/FilterList";
import { AddBoxRounded } from "@mui/icons-material";
import AlertDialogForConfirm from "../../components/confirmation/AlertDialog";
import Loading from "../../components/loading/loading";

export default function EmployeeListingPage() {
  const { keycloak } = useKeycloak();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const pathUrl = "/employees";
  const [employeeIncoming, setEmployeesIncomingData] = useState([]);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [roles, setRoles] = useState([]);
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const handleClickOpen = (id) => {
    console.log("Opening modal. ID:", id);
    setDeleteItemId(id);
    setOpen(true);
  };

  const handleClose = () => {
    console.log("Closing modal");
    setDeleteItemId(null);
    setOpen(false);
  };

  const handleDeleteItem = async (id) => {
    console.log("Deleting item with ID:", id);
    setDeleteItemId(id);
    try {
      const response = await axios.delete(`/employees/${id}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });

      if (response.status === 200) {
        setEmployeesIncomingData(
          employeeIncoming.filter((incomingDate) => incomingDate.id !== id)
        );
      } else {
        console.error(
          "Failed to delete employee. Status code:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      handleClose(); // Move handleClose to the end to ensure it's called even if there's an error
    }
  };

  const CreateEmployee = () => {
    navigate("/employees/create");
  };

  const handleSearch = async (searchTerm) => {
    setSearch(searchTerm);

    if (searchTerm.trim() === "") {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    const searchEmployee = async (searchTerm) => {
      try {
        const response = await axios.get(
          `${apiUrl}/employees?search=${encodeURIComponent(searchTerm)}`,
          {
            headers: {
              Authorization: "Bearer " + keycloak.token,
            },
          }
        );

        setSearchResults(
          response.data?.data.map((depart) => ({
            ...depart,
          }))
        );

        return response.data;
      } catch (error) {
        console.error("Error searching departments:", error);
        throw error;
      }
    };

    const delaySearch = setTimeout(() => {
      if (search.trim() !== "") {
        searchEmployee(search);
      }
    }, 500);

    return () => clearTimeout(delaySearch);
  }, [search, keycloak.token]);

  const getEmployee = async () => {
    setLoading(true);
    if (!page) {
      setLoading(false);
      return;
    }

    const params = new URLSearchParams({ page: page });

    try {
      const response = await axios.get(
        `${apiUrl}/employees?` + params.toString(),
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        }
      );
      setEmployeesIncomingData(response.data?.data);

      if (localStorage.getItem("employeesPage")) {
        setPage(localStorage.getItem("employeesPage") || response.data.page);
      }

      setTotalPages(response.data.meta.totalPages);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (keycloak.token) {
      getEmployee();
    }
  }, [keycloak.token, page]);

  // ActionButtons props functions
  const handleDisplayDataToVisualize = (id) => {
    navigate(`${pathUrl}/${id}?disabled=true`);
  };

  const handleEdit = (id) => {
    navigate(`${pathUrl}/${id}/edit`);
  };

  return (
    <>
      <Grid
        container
        component={Paper}
        sx={{
          // height: "100%", // Check if this line is causing the issue
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          position: "relative",
          alignItems: { xs: "left", md: "initial" },
          boxShadow: "none",
        }}
      >
        {loading && <Loading />}
        <Grid item xs={12}>
          {loading ? null : ( // Render nothing while loading
            <TableContainer
              sx={{ overflow: "hidden", border: "1px solid #E9ECF5" }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      colSpan={8}
                      style={{ height: "59px" }}
                    >
                      <Typography
                        variant="h3"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {i18n.t("employee.title")}

                        <Box sx={{ display: "flex", gap: "10px" }}>
                          <Box sx={{ width: "300px" }}>
                            <AutoSearch
                              placeholder={i18n.t("others.search")}
                              searchEndpoint={handleSearch}
                              search={search}
                              setSearch={setSearch}
                              sx={{ width: "300px" }}
                            />
                          </Box>

                          <Box sx={{ width: "100px", marginLeft: "20px" }}>
                            <Select
                              onChange={(e) => {}}
                              inputProps={{
                                "aria-label": "Page size",
                              }}
                              sx={{
                                width: "80px",
                                height: "40px",
                              }}
                            >
                              {[5, 10, 20, 50, 100].map((size) => (
                                <MenuItem key={size} value={size}>
                                  {size}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "120px",
                              height: "40px",
                              border: "1px solid #C3CAD9",
                              marginRight: "20px",
                              borderRadius: "5px",
                            }}
                          >
                            <FilterList
                              cardStyle={{
                                position: "absolute",
                                zIndex: "1",
                                top: "140px",
                                right: "323px",
                              }}
                              filters={[]}
                              setCanFilter={() => {}}
                              canFilter={false}
                            />
                            {i18n.t("others.filter")}
                          </Box>
                          <Box>
                            <Button
                              variant="outlined"
                              sx={{
                                height: "35px",
                                width: "80px",
                                color: "#FFC107",
                                backgroundColor: "#FFFFFF",
                                borderColor: "#FFC107",

                                "&:hover": {
                                  color: "#FFC107",
                                  backgroundColor: "#FFFFFF",
                                  borderColor: "#FFC107",
                                },
                              }}
                              onClick={CreateEmployee}
                            >
                              <AddBoxRounded />
                              {i18n.t("course.new")}
                            </Button>
                          </Box>
                        </Box>
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      backgroundColor: "#F8FAFD",
                      height: "56px",
                      color: "#939a9f",
                    }}
                  >
                    <TableCell sx={{ color: "#939a9f" }}>
                      {i18n.t("others.idCard")}
                    </TableCell>
                    <TableCell sx={{ color: "#939a9f" }} align="left">
                      {i18n.t("others.name")}
                    </TableCell>
                    <TableCell sx={{ color: "#939a9f" }} align="left">
                      {i18n.t("others.phone")}
                    </TableCell>
                    <TableCell sx={{ color: "#939a9f" }} align="left">
                      {i18n.t("others.email")}
                    </TableCell>
                    <TableCell sx={{ color: "#939a9f" }} align="left">
                      {i18n.t("others.hiredDate")}
                    </TableCell>
                    <TableCell sx={{ color: "#939a9f" }} align="left">
                      {i18n.t("others.department")}
                    </TableCell>
                    <TableCell sx={{ color: "#939a9f" }} align="left">
                      {i18n.t("others.role")}
                    </TableCell>
                    <TableCell sx={{ color: "#939a9f" }} align="center">
                      {i18n.t("others.options")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(search.trim() === ""
                    ? employeeIncoming
                    : searchResults
                  ).map((data, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        {" "}
                        {data.employeeNumber}
                      </TableCell>
                      <TableCell align="left">{`${data.firstName} ${data.lastName}`}</TableCell>
                      <TableCell align="left">{data.phone}</TableCell>
                      <TableCell align="left">
                        {data.institutionalEmail}
                      </TableCell>
                      <TableCell align="left">{data.startFunction}</TableCell>
                      <TableCell align="left">
                        {data.departments?.length > 0 && (
                          <List dense>
                            {data.departments.map((item) => (
                              <ListItem key={item.id} disableGutters>
                                <ListItemText
                                  primary={item.name}
                                  primaryTypographyProps={{
                                    sx: { fontWeight: 500 },
                                  }}
                                />
                              </ListItem>
                            ))}
                          </List>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {data.employeeRoles.length > 0
                          ? data.employeeRoles[0].name
                          : ""}
                      </TableCell>
                      <TableCell align="center">
                        <ActionButtons
                          id={data.id}
                          handleVisualize={handleDisplayDataToVisualize}
                          handleEdit={handleEdit}
                          handleDeleteItem={() => handleClickOpen(data.id)} // Ensure correct ID is passed
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
      {!loading && (
        <Grid item xs={12} sx={{ marginTop: 10 }}>
          <CustomPagination
            setPage={setPage}
            totalPages={totalPages}
            page={page}
          />
          {deleteItemId && (
            <AlertDialogForConfirm
              id={deleteItemId}
              open={open}
              handleClose={handleClose}
              handleDeleteItem={handleDeleteItem}
            />
          )}
        </Grid>
      )}
    </>
  );
}
