import React, { useEffect, useState } from "react";
import { Button, Grid, Typography, Select } from "@mui/material";
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";

export default function DisciplinesListPage() {
    const [enrolls, setEnrolls] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;
    const { keycloak } = useKeycloak();
    
    const handleLancarNotasClick = (subjectId, subjectName, studentId) => {
        navigate(`/subjects/students/view/${subjectId}/${encodeURIComponent(subjectName)}/${studentId}`);
    };
    
    const getList = async (type, set) => {
        try {
            const response = await axios.get(`${apiUrl}/${type}`, {
                headers: {
                    Authorization: "Bearer " + keycloak.token,
                },
            });
            console.log("Resposta da Requisição:", response);
            set(response.data?.data ? response.data.data : response.data);
        } catch (error) {
            console.error(error);
        }
    };
    
    useEffect(() => {
        getList("enroll", setEnrolls);
    }, []);

    useEffect(() => {
        let subjectsList = [];
        enrolls.forEach(enroll => {
            enroll.enrollSubjects.forEach(enrollSubject => {
                subjectsList.push({
                    id: enrollSubject.subject.id,
                    name: enrollSubject.subject.name,
                    studentId: enroll.student.id // Adicionando o studentId
                });
            });
        });
        setSubjects(subjectsList);
    }, [enrolls]);

    return (
        <Grid sx={{ backgroundColor: "white", padding: "80px", paddingTop: "30px" }}>  
            <Grid container display={"flex"} justifyContent="space-between" alignItems="center" sx={{ marginBottom: '10px' }}>
                <Grid item>
                    <Typography variant="h4" sx={{ color: '#212529', fontWeight: 'bold',fontSize: "20px" }}>
                        Minhas Disciplinas
                    </Typography>
                </Grid>
                <Grid item alignItems="center">
                    <Select label="Select 1" sx={{ width: '160px', height: '35px', marginRight: '10px' }} />
                    <Select label="Select 2" sx={{ width: '160px', height: '35px', marginRight: '10px' }} />
                </Grid>
            </Grid>
              
            {subjects.map((subject) => (
                <Grid
                    container
                    alignItems="center"
                    key={subject}
                    sx={{
                        border: '1px solid #fff',
                        borderTop: '1px solid #fff',
                        boxShadow: '0px 8px 16px 0px #ABBED133',
                        marginBottom: '5px',
                        padding: '20px',
                        maxWidth: '100%',
                        marginLeft: "-20px",
                        position: 'relative', // Add this line
                    }}
                >
                    <Typography variant="body1" sx={{ color: '#003B76', fontWeight: 'bold',fontSize: "14px" }}>{subject.name}</Typography>
                    <Button
                        variant="outlined"
                        sx={{
                            height: "30px",
                            width:"190px",
                            color: "#FFC107",
                            backgroundColor: "#EFEFEF",
                            borderColor: "#EFEFEF",
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            borderRadius: '0',
                            fontSize: "12px"
                        }}
                        onClick={() => handleLancarNotasClick(subject.id, subject.name, subject.studentId)} 
                    >
                        Visualizar Avaliações
                        <EastIcon />
                    </Button>
                </Grid>
            ))}
        </Grid>
    );
}
