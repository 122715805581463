import {
   Grid,
   Table,
   TableBody,
   TableCell,
   TableHead,
   TableRow,
   Typography,
   TableCell as StyledTableCell,
} from "@mui/material";
import * as React from "react";
import { i18n } from "../../../translate/i18n";
import { useState, useEffect } from "react";
import axios from "axios";
import keycloak from "../../../keycloak";
import CustomPagination from "../../../components/pagination";
import Loading from "../../../components/loading/loading";
import { useParams } from "react-router-dom";

export default function CourseStudents() {
   const [incomingDate, setIncomingDate] = useState([]);
   const [loading, setLoading] = useState(false);
   const [totalPages, setTotalPages] = useState();

   const { id } = useParams();

   const [page, setPage] = useState(localStorage.getItem("courseStudentsPage") || 1);

   const apiUrl = process.env.REACT_APP_API_URL;

   // Certifique-se de que o número da página seja sempre um inteiro válido
   const pageSeter = (page) => {
      const pageNumber = parseInt(page);
      setPage(isNaN(pageNumber) ? 1 : pageNumber);
      localStorage.setItem("courseStudentsListingPage", page);
   };

   const getStudentsByCourseId = async () => {
      setLoading(true);

      try {
         const studentsResponse = await axios.get(`${apiUrl}/courses/${id}/students`, {
            headers: {
               Authorization: "Bearer " + keycloak.token,
            },
         });

         const studentsData = studentsResponse?.data || studentsResponse?.data?.data || {};
         setIncomingDate(studentsData);
         console.log("studentsData", studentsData);

         setTotalPages(studentsData.meta?.totalPages);
         if (localStorage.getItem("courseStudentsPage")) pageSeter(studentsData.data.page);
      } catch (error) {
         console.error("Erro ao obter dados dos alunos:", error);
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      if (keycloak.token) {
         getStudentsByCourseId();
      }
   }, [keycloak.token, page]);

   return (
      <Grid
         style={{
            backgroundColor: "white",
         }}
      >
         <Grid item xs={12}>
            {" "}
            {loading && <Loading />}
            <TableCell
               sx={{
                  height: "35px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid #E9ECF5",
                  marginTop: "30px",
               }}
            >
               <Grid>
                  <Typography variant="h3">{i18n.t("student.title")}</Typography>
               </Grid>

            </TableCell>
            <Table sx={{ minWidth: 650, border: "1px solid #E9ECF5" }} size="small" aria-label="a dense table">
               <TableHead>
                  <TableRow height="56px" sx={{ backgroundColor: "#F8FAFD" }}>
                     <StyledTableCell align="center"> {i18n.t("others.mechanographicNº")} </StyledTableCell>
                     <StyledTableCell align="center"> {i18n.t("others.student")} </StyledTableCell>
                     <StyledTableCell align="center"> {i18n.t("others.yearEnrolled")} </StyledTableCell>
                     <StyledTableCell align="center"> {i18n.t("others.subjectsInArrears")} </StyledTableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {incomingDate?.data?.map((data) => (
                     <TableRow key={data.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        
                        <TableCell component="th" scope="row" align="center">
                           {data.mechanographicNumber}
                        </TableCell>

                        <TableCell component="th" scope="row" align="center">
                           {data?.name}
                        </TableCell>

                        <TableCell align="center">{data.enrollYear}</TableCell>

                        <TableCell align="center">{data.failedSubjects?.map((subject, i) => (
                           <div key={subject}>{subject.name}</div>
                        ))}
                        </TableCell>
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
            {!loading && (
               <Grid style={{ display: "flex", justifyContent: "center" }}>
                  <CustomPagination totalPages={totalPages} setPage={pageSeter} page={page} />
               </Grid>
            )}
         </Grid>
      </Grid>
   );
}
