import { Grid, Typography } from "@mui/material";
import axios from "axios";
import { Form, Formik } from "formik";
import * as React from "react";
import * as Yup from "yup";
import AlertDialog from "../../components/dialog-option/AlertDialog";
import Select from "../../components/form-components/select";
import Textfield from "../../components/form-components/text-field";
import { i18n } from "../../translate/i18n";
import HandleUrl from "../../utils/HandleUrl";
import { MainButton } from "../../components/main-button/MainButton";
import { useEffect,useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import FallBackLoader from "../../components/FallBackComponents/FallBackLoader";
import { createItem as create } from "../../utils/functions/formCRUDFunctions";

export default function CreateExamsPage() {
const  isDisabled = HandleUrl();
  const [course, setCourse] = useState([]);
  let schoolYear = "";
  const [graduation, setGraduation] = useState([]);
  const [numberOfCandidates,setNumberOfCandidates] = useState();
  const [numberOfVacancies,setNumberOfVacancies] = useState();
  
  const [status, setStatus] = useState({
    isLoading: true,
  });

  const [textBox, setTextBox] = useState([i18n.t("messages.createTitle"), ""]);
  const INITIAL_FORM_STATE = {
    department: "",
    schoolYear: "",
    graduation: "",
    numberOfVacancies: numberOfVacancies,
    numberOfCandidates: numberOfCandidates,
    coordinator: "",
    course: "",
  };

  const FORM_VALIDATION = Yup.object().shape({
    graduation: Yup.string().required(i18n.t("messages.required")),
    schoolYear: Yup.string().required(i18n.t("messages.required")),
    coordinator: Yup.string().required(i18n.t("messages.required")),
    numberOfVacancies: Yup.string().required(i18n.t("messages.required")),
    numberOfCandidates: Yup.string().optional(),
    course: Yup.string().required(i18n.t("messages.required")),
  });

  const { keycloak } = useKeycloak();
  const apiUrl = process.env.REACT_APP_API_URL;

  const getList = async (type, set) => {
    try {
      const response = await axios.get(`${apiUrl}/${type}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token, // token de autenticação
        },
      });
      console.log("Resposta da Requisição:", response);
      set(response.data?.data ? response.data.data : response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getList("graduations", setGraduation);
    getList("curricular-plan", setCourse);
  }, []);


  const formatFormData = (values) => ({
    schoolYear: values.schoolYear,
    graduation: values.graduation,
    department: values.department,
    coordinator: values.coordinator,
    numberOfVacancies: parseInt(values.numberOfVacancies),
    course: values.course,
  });

  const createItem = create(
    formatFormData,
    `${apiUrl}/accessExams`,
    setTextBox,
    setStatus,
    keycloak
  );

  console.log("initial form state: ", INITIAL_FORM_STATE);
  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          padding: "80px",
          paddingTop: "55px",
        }}
      >
        <Formik
          initialValues={{
            ...INITIAL_FORM_STATE,
          }}
          validationSchema={FORM_VALIDATION}
          onSubmit={(values) => {
            createItem(values);
          }}
          enableReinitialize
        >
          <Form>
            <Typography variant="h3" style={{ color: "#003B76" }}>
              {i18n.t("button.exams")}
            </Typography>
            <br />

            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Select
                  name="course"
                  options={course.map((item) => ({
                    name: item.course.name,
                    id: item.course.id,
                  }))}
                  label={i18n.t("others.course")}
                  disabled={isDisabled}
                />
              </Grid>

              <Grid item xs={5}>
                <Textfield
                  name="schoolYear"
                  options={schoolYear}
                  label={i18n.t("others.schoolYear")}
                  disabled={isDisabled}
                />
              </Grid>
            </Grid>
            <br />

            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Select
                  name="graduation"
                  options={graduation}
                  label={i18n.t("others.graduation")}
                  disabled={isDisabled}
                ></Select>
              </Grid>

              <Grid item xs={5}>
                <Textfield
                  name="coordinator"
                  label={i18n.t("others.coordinator")}
                  disabled={isDisabled}
                ></Textfield>
              </Grid>

              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={4.7}>
                    <Textfield
                      name="numberOfVacancies"
                      label={i18n.t("others.NumberOfVacancies")}
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item xs={4.7}>
                    <Textfield
                      name="numberOfCandidates"
                      label={i18n.t("others.numberOfCandidates")}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <br />

            <Grid container spacing={3}>
              <Grid item xs={10}></Grid>

              <Grid item xs={2}>
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    paddingLeft: "600px",
                    paddingTop: "130px",
                  }}
                >
                  <span>
                    <MainButton url="/exams" name={i18n.t("button.cancel")} />
                  </span>

                  <span>
                    <AlertDialog
                      show={true}
                      button={i18n.t("button.register")}
                      head={textBox[0]}
                      body={
                        textBox[1] || (
                          <FallBackLoader isLoading={status.isLoading} />
                        )
                      }
                      goBack={i18n.t("ExamsList.title")}
                      urlGoBack="/exams"
                      stay={i18n.t("ExamsList.titleCreate")}
                      urlStay="/exams/create"
                      isValid={true}
                      type="submit"
                    />
                  </span>
                </div>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </div>
    </>
  );
}
