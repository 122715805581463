import React, { useEffect } from 'react';
import WebhookIcon from '@mui/icons-material/Webhook';
import { i18n } from '../../translate/i18n';
import './home.css';
import CustomCalendar from '../../components/canlender/calender';
import { Grid, Typography } from '@mui/material';
import CustomCard from '../../components/card/card';
import Card from '../../components/card/cards';
import Loading from '../../components/loading/loading';

export default function Home() {


  return (
    <Grid container display={""} style={{ height: '100vh' }}>
      <Grid item style={{ flex: 1, marginBottom: '20px',marginLeft:"-100px"}}>
        <Grid item>
          <Loading/>
        </Grid>
        <Grid item style={{ textAlign: 'center', paddingTop: '100px' }}>
        <Typography variant='p'>{i18n.t('messages.home')}</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={5}

        style={{
          background: 'white',
          textAlign: 'center',
          height: 'calc(100vh - 30px)', // Ajuste a altura para considerar a margem
          position: 'absolute',
          right: 0,
          marginTop: '-50px',
          marginRight: '20px',
        }}
      >
        <Typography variant="h3" sx={{ padding: "10px" }} >
          {i18n.t('others.NextE')}
        </Typography>
        <Grid>
          <Card
            title="Workshop de Primeiros"

            addressIcon={<img src="icon/localização.svg" alt="Localização" />}
            addressText="123 Rua Exemplo"
            calendarIcon={<img src="icon/calendario.svg" alt="Calendario" />}
            calendarText="10 de dezembro de 2023"
            clockIcon={<img src="icon/hora.svg" alt="Hora" />}
            clockText="14:30"
          />

          <Card
            title="Meu Card"
            addressIcon={<img src="icon/localização.svg" alt="Localização" />}
            addressText="123 Rua Exemplo"
            calendarIcon={<img src="icon/calendario.svg" alt="Calendario" />}
            calendarText="10 de dezembro de 2023"
            clockIcon={<img src="icon/hora.svg" alt="Hora" />}
            clockText="14:30"
          />
        </Grid>
        <hr style={{ margin: '10px 0', borderTop: '1px solid #ccc' }} />
        <CustomCalendar style={{ marginTop: '20px' }} />
      </Grid>
    </Grid>
  );
}
