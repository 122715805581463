import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Typography, Box,Button, CardActions } from "@mui/material";

const cardStyle = {
  width: 300, // Aumentei a largura do cartão
  height: 160, // Aumentei a altura do cartão
  border: "1px solid #ccc",
};

const containerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "20vh",
  width: 310, // Ajustei a largura do contêiner para acomodar a largura do cartão
};

function CustomCard({ title, description, sDetails, nStudents }) {
  return (
    <Box sx={containerStyle}>
      <Card variant="outlined" style={cardStyle}>
        <CardContent>
          <Typography
            variant="h6"
            component="div"
            sx={{
              font: "Word Sans",
              fontWeight: 700,
              size: "16px",
              lineHeight: "24px",
              color: "#003B76",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              mt: 1.5,
              font: "Word Sans",
              fontWeight: 400,
              size: "14px",
              lineHeight: "19px",
              color: "#212529",
            }}
            color="textSecondary"
          >
            {description}
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: "space-between" }}>
          <Button
            size="small"
            sx={{
              mt: 1.5,
              font: "Word Sans",
              fontWeight: 600,
              size: "14px",
              lineHeight: "38px",
              color: "#FFC107",
            }}
          >
            {sDetails}
          </Button>
          <Button
            size="small"
            sx={{
              mt: 1.5,
              font: "Word Sans",
              fontWeight: 700,
              size: "16px",
              lineHeight: "24px",
              color: "#003B76",
            }}
          >
            {nStudents}
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}

CustomCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  sDetails: PropTypes.string.isRequired,
  nStudents: PropTypes.number.isRequired,
};

export default CustomCard;
