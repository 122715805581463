import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell  from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useKeycloak } from "@react-keycloak/web";
import { useState, useEffect, useCallback } from "react";
import { useNavigate} from "react-router-dom";
import { AddBoxRounded } from "@mui/icons-material";
import { i18n } from "../../translate/i18n";
import CustomPagination from "../../components/pagination";
import ActionButtons from "../../components/actions-buttom/ActionButtons";
import FilterList from "../../components/filterList/FilterList";
import AutoSearch from "../../components/autoSearch/AutoSearch";
import { useFilter, useSearch } from "../../utils/hooks/useSearch";
import Loading from "../../components/loading/loading";
import FallBackCard from "../../components/FallBackComponents/FallBackCard";
import usePagination from "../../utils/hooks/usePagination";
import {
  deleteItem as eliminate,
  editItem as edit,
  visualizeItem,
  getItem as get,
  newItemPage,
} from "../../utils/functions/tableCRUDFunctions";
import {
  extractUniqueValues,

} from "../../utils/functions/tableFilterFunctions";
import {
  formatDateToLocale,
  truncateText,
} from "../../utils/functions/utilFunctions";
import AlertDialogForConfirm from "../../components/confirmation/AlertDialog";
import { useConfirmDeleteModal } from "../../utils/hooks/useModals";
import { debounce } from "lodash";


const ClassroomTypeListPage = () => {
  const [IncomingData, setIncomingData] = useState([]);

  const navigate = useNavigate();
  const apiUrl = `${process.env.REACT_APP_API_URL}/room-type`;
  const { keycloak } = useKeycloak();
  const pathUrl = "/classroom-type";

  const newItem = newItemPage(navigate, `${pathUrl}/add`);
  const [isLoading, setIsLoading] = useState(true);

  const {
    open,
    deleteItemId,
    message,
    loading,
    setLoading,
    success,
    handleClose,
    handleClickOpen,
    handleSuccess,
    handleBadRequest,
    errorFallback,
  } = useConfirmDeleteModal();

  const [queryString, setQueryString] = useState("");

  const analyzeItem = visualizeItem(pathUrl, navigate);

  const editItem = edit(pathUrl, navigate);

  //Table filters
  const [currentFilters, setCurrentFilters] = useState({});

  const [selectedCreatedAts, setSelectedCreatedAts] = useState([]);
  const createdAts = extractUniqueValues(
    IncomingData,
    "createdDate",
    formatDateToLocale
  );

  const filters = [
    {
      label: i18n.t("others.createdAt"),
      options: createdAts,
      selectedOptions: selectedCreatedAts,
      setSelectedOptions: setSelectedCreatedAts,
    },
  ];

  //Search functionality

  const { searchTerm, setSearchTerm } = useSearch();
  const { setCanFilter, canFilter } = useFilter();

  const filteringProps = {
    createdDate: selectedCreatedAts,
    search: searchTerm,
  };
  //Pagination  & Items per page filter
  const items = IncomingData || [];

  const {
    currentPage,
    totalPages,
    setCurrentPage,
    pageSize,
    handlePageSizeChange,
    availablePageSizes,
    handlePaginationData,
  } = usePagination(items, [5, 10, 20, 100]);

  const getItem = get(
    apiUrl,
    keycloak,
    setIncomingData,
    setIsLoading,
    currentPage,
    "classroomTypeListPage",
    pageSize,
    handlePaginationData,
    setCurrentFilters,
    canFilter || searchTerm,
    setQueryString
  );

  const deleteItem = eliminate(
    apiUrl,
    keycloak,
    getItem,
    setLoading,
    handleSuccess,
    handleBadRequest,
    errorFallback,
    totalPages,
    pageSize,
    "classroomTypeListPage",
    currentFilters
  );

  const debouncedGetItem = useCallback(
    debounce((filters) => getItem(filters), 400),
    []
  );

  useEffect(() => {
    if (keycloak.token) {
      if (searchTerm) {
        debouncedGetItem(filteringProps);
      } else {
        getItem(filteringProps);
      }
    }
  }, [keycloak.token, currentPage, pageSize, canFilter, searchTerm]);

  useEffect(() => {
    // Clear the specific localStorage entries for the current page
    for (let i = 1; i <= totalPages; i++) {
      localStorage.removeItem(
        `classroomTypeListPage${i}Size${pageSize}${queryString}`
      );
    }
  }, [pageSize]);

  useEffect(() => {
    return () => {
      // Clear all localStorage entries for the current page
      for (let i = 1; i <= totalPages; i++) {
        localStorage.removeItem(
          `classroomTypeListPage${i}Size${pageSize}${queryString}`
        );
      }
    };
  }, [pageSize, totalPages, queryString]);

  console.log("Current page: ", currentPage);

  return (
    <>
      <Grid
        container
        component={Paper}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.7)", // Set the initial background color
          position: "relative",
          alignItems: { xs: "left", md: "initial" },
          boxShadow: "none",
        }}
      >
        {isLoading && <Loading />}
        {!isLoading && (
          <Grid item xs={12}>
            <div className="container-da-tabela-responsiva">
              <TableContainer
                sx={{ overflow: "hidden", border: "1px solid #E9ECF5" }}
              >
                <Table sx={{ minWidth: 650 }} aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        colSpan={7}
                        style={{ height: "59px" }}
                      >
                        <Typography
                          variant="h3"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {i18n.t("classroomType.title")}

                          <Box sx={{ display: "flex", gap: "10px" }}>
                            <Box sx={{ width: "300px" }}>
                              <AutoSearch
                                placeholder={i18n.t("others.search")}
                                search={searchTerm}
                                setSearch={setSearchTerm}
                                handler={() => setSearchTerm("")}
                                sx={{ width: "300px" }}
                              />
                            </Box>

                            <Box sx={{ width: "100px", marginLeft: "20px" }}>
                              <Select
                                value={pageSize}
                                onChange={handlePageSizeChange}
                                displayEmpty
                                inputProps={{
                                  "aria-label": "Page size",
                                }}
                                sx={{
                                  width: "80px",
                                  height: "40px",
                                }}
                              >
                                {availablePageSizes.map((size) => (
                                  <MenuItem key={size} value={size}>
                                    {size}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "120px",
                                height: "40px",
                                border: "1px solid #C3CAD9",
                                marginRight: "20px",
                                borderRadius: "5px",
                              }}
                            >
                              <FilterList
                                cardStyle={{
                                  position: "absolute",
                                  zIndex: "1",
                                  top: "140px",
                                  right: "323px",
                                }}
                                filters={filters}
                                setCanFilter={setCanFilter}
                                canFilter={canFilter}
                              />
                              {i18n.t("others.filter")}
                            </Box>
                            <Box>
                              <Button
                                variant="outlined"
                                sx={{
                                  height: "35px",
                                  width: "80px",
                                  color: "#FFC107",
                                  backgroundColor: "#FFFFFF",
                                  borderColor: "#FFC107",

                                  "&:hover": {
                                    color: "#FFC107",
                                    backgroundColor: "#FFFFFF",
                                    borderColor: "#FFC107",
                                    // Add other hover styles as needed
                                  },
                                }}
                                onClick={newItem}
                              >
                                <AddBoxRounded />
                                {i18n.t("button.new")}
                              </Button>
                            </Box>
                          </Box>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        backgroundColor: "#F8FAFD",
                        height: "56px",
                        color: "#939a9f",
                        width: "100%",
                      }}
                    >
                      <TableCell
                        sx={{ color: "#939a9f", width: "40%" }}
                        align="left"
                      >
                        {i18n.t("others.name")}
                      </TableCell>
                      <TableCell
                        sx={{ color: "#939a9f", width: "50%" }}
                        align="left"
                      >
                        {i18n.t("others.description")}
                      </TableCell>
                      <TableCell
                        sx={{ color: "#939a9f", width: "20%" }}
                        align="left"
                      >
                        {i18n.t("others.createdAt")}
                      </TableCell>
                      <TableCell
                        sx={{ color: "#939a9f", width: "30%" }}
                        align="center"
                      >
                        {i18n.t("others.options")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items.map((data, i) => (
                      <TableRow key={i}>
                        <TableCell align="left">{data?.name} </TableCell>
                        <TableCell align="left">
                          {truncateText(data?.description, 50)}
                        </TableCell>
                        <TableCell align="left">
                          {formatDateToLocale(data?.createdDate)}
                        </TableCell>
                        <TableCell align="center">
                          {
                            <ActionButtons
                              id={data.id}
                              handleVisualize={analyzeItem}
                              handleEdit={editItem}
                              handleDeleteItem={() => handleClickOpen(data.id)}
                            />
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <FallBackCard data={IncomingData} isLoading={isLoading} />
              </TableContainer>
            </div>
          </Grid>
        )}
      </Grid>

      {!isLoading && IncomingData?.length ? (
        <Grid sx={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
          <CustomPagination
            setPage={setCurrentPage}
            totalPages={totalPages}
            page={currentPage}
          />
        </Grid>
      ) : null}

      {deleteItemId && (
        <AlertDialogForConfirm
          id={deleteItemId}
          open={open}
          handleClose={handleClose}
          handleDeleteItem={deleteItem}
          message={message}
          isSuccessful={success}
          isLoading={loading}
        />
      )}
    </>
  );
};

export default ClassroomTypeListPage;
