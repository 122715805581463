import { Grid, IconButton, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as React from "react";
import axios from "axios";
import Textfield from "../../../components/form-components/text-field";
import { i18n } from "../../../translate/i18n";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Loading from "../../../components/loading/loading";
import DisciplinaModal from "../../staff/components/forms-fields/modal";
import ButtonWithTitle from "../../../components/styleButton/styleButton";
import CloseIcon from "@mui/icons-material/Close";
import { useKeycloak } from "@react-keycloak/web";
import ButtonSelect from "../components/buttonSelect/ButtonSelect";

export default function RegisterCourse() {
   let isDisabled = true;
   const { keycloak } = useKeycloak();
   const [coordinatorData, setCoordinatorData] = useState([]);
   const [loading, setLoading] = useState(false);
   const apiUrl = process.env.REACT_APP_API_URL;
   const loggedUsername = keycloak.tokenParsed.preferred_username;
   const { id } = useParams();

   const [schoolYearId, setSchoolYearId] = useState();

   const solveDate = (coordinatorData) => {
      let d = new Date(coordinatorData.courseCoordination?.schoolYear?.startDate);
      let EndDate = new Date(coordinatorData.courseCoordination?.schoolYear?.endDate);
      return (
         coordinatorData.courseCoordination?.schoolYear?.startDate &&
         d.getFullYear() + (coordinatorData.courseCoordination?.schoolYear?.endDate && "-" + EndDate.getFullYear())
      );
   };

   useEffect(() => {
      setLoading(true);
      const getCoordinatorByUserId = async () => {
         try {
            const courseResponse = await axios.get(`${apiUrl}/courses/${id}`);
            const courseData = courseResponse?.data;
            console.log("courseData", courseData);
            setCoordinatorData(courseData);
            setSchoolYearId(courseData.courseCoordination?.schoolYear?.id);
            setSelectedDeparts(courseData?.department);
         } catch (error) {
            console.error("Erro ao obter dados do coordenador:", error);
         } finally {
            setLoading(false);
         }
      };

      getCoordinatorByUserId();
   }, [keycloak, id]);

   //Departments Modal functionalities
   let departmentsIncommingData = [];
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [selectedDeparts, setSelectedDeparts] = useState([]);

   const saveDepartmentNames = () => {
      // Salve os nomes das disciplinas em seu estado ou estrutura de dados preferida
      console.log(
         "Salvando nomes das disciplinas:",
         selectedDeparts.map((depart) => depart.name)
      );
   };

   const handleRemoveDepartment = (department) => {
      console.log("Disciplina para remover:", department);
      console.log("Disciplinas selecionadas antes da remoção:", selectedDeparts);

      const updatedDeparts = selectedDeparts.filter((currentDepart) => currentDepart?.id !== department?.id);
      setSelectedDeparts(updatedDeparts);
   };

   return (
      <>
         {loading && <Loading />}
         <Grid
            backgroundColor="white"
            sx={{
               padding: "55px 110px 50px",
               margin: "0 -100px 20px -50px",
            }}
         >
            <Formik>
               <Form>
                  <Typography variant="h1" gutterBottom paddingBottom={"30px"}>
                     {i18n.t("course.titleCreate")}
                  </Typography>

                  <Grid
                     container
                     spacing={2}
                     //xs={12}
                     style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "20px",
                        flexDirection: "row",
                        Width: "1183px",
                        //margin: "auto",
                     }}
                  >
                     <Grid item xs={9} md={5.3} xl={4}>
                        <Grid>
                           <Textfield
                              name="name"
                              value={coordinatorData.name}
                              disabled={isDisabled}
                              label={i18n.t("others.name")}
                           />
                        </Grid>

                        <Grid style={{ marginTop: "30px" }}>
                           <Textfield
                              name="graduation"
                              value={coordinatorData.graduation}
                              label={i18n.t("others.graduationType")}
                              disabled={isDisabled}
                           ></Textfield>
                        </Grid>

                        <Grid style={{ marginTop: "30px" }}>
                           <Textfield
                              name="coordenator"
                              value={loggedUsername}
                              label={i18n.t("others.coordinator")}
                              disabled={isDisabled}
                           ></Textfield>
                        </Grid>

                        <Grid style={{ marginTop: "30px" }}>
                           <label>
                              <p
                                 style={{
                                    fontSize: "13px",
                                    fontWeight: "600",
                                    color: "#42474B",
                                    margin: "0px",
                                    border: "none",
                                 }}
                              >
                                 {i18n.t("others.department")}
                              </p>
                           </label>

                           <Grid>
                              <ButtonWithTitle
                                 title={
                                    selectedDeparts?.length > 0
                                       ? `${i18n.t("department.addedDeparts")}`
                                       : `${i18n.t("department.addDepart")}`
                                 }
                                 onClick={() => setIsModalOpen(true)}
                                 disabled={isDisabled}
                              />
                              <Grid container spacing={1}>
                                 {selectedDeparts?.length
                                    ? selectedDeparts.map((department) => (
                                         <Grid item key={department.id}>
                                            <div
                                               style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  marginRight: 2,
                                                  marginBottom: 1,
                                                  background: "#E9ECF5",
                                                  padding: "1px",
                                                  marginTop: "8px",
                                               }}
                                            >
                                               <Typography key={department.id}>{department.name}</Typography>
                                               <IconButton
                                                  size="small"
                                                  onClick={() => handleRemoveDepartment(department)}
                                               >
                                                  {!isDisabled && <CloseIcon />}
                                               </IconButton>
                                            </div>
                                         </Grid>
                                      ))
                                    : null}
                              </Grid>
                           </Grid>

                           <DisciplinaModal
                              open={isModalOpen}
                              onClose={() => setIsModalOpen(false)}
                              onConfirm={(selectedDeparts) => {
                                 setSelectedDeparts(selectedDeparts);
                                 saveDepartmentNames();
                                 setIsModalOpen(false);
                              }}
                              title={i18n.t("others.department")}
                              data={departmentsIncommingData}
                              selected={selectedDeparts}
                           />
                        </Grid>
                     </Grid>

                     <Grid item xs={9} md={5.3} xl={4}>
                        <Grid item>
                           <Textfield
                              name="lativeYear"
                              value={solveDate(coordinatorData)}
                              label={i18n.t("others.lativeYear")}
                              disabled={isDisabled}
                           ></Textfield>
                        </Grid>

                        {/* <Grid
                              item
                              style={{ marginTop: "30px" }}
                              // xs={5.5}
                           >
                              <Textfield
                                 name="department"
                                 value={courseData.course?.department?.name}
                                 label={i18n.t("others.department")}
                                 disabled={isDisabled}
                              ></Textfield>


                           </Grid> */}

                        <Grid
                           style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "10px",
                              marginTop: "30px",
                           }}
                        >
                           <Grid item>
                              <Textfield
                                 name="tuition"
                                 disabled={isDisabled}
                                 label={i18n.t("others.tuition")}
                                 value={coordinatorData?.price}
                              />
                           </Grid>

                           <Grid item>
                              <Textfield
                                 name="numberOfStudents"
                                 disabled={isDisabled}
                                 label={i18n.t("others.numberOfStudents")}
                                 type="number"
                                 value={coordinatorData?.totalStudents}
                              />
                           </Grid>
                        </Grid>

                        <Grid
                           style={{
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "10px",
                              marginTop: "30px",
                           }}
                        >
                           <Grid item>
                              <Textfield
                                 name="Workload"
                                 disabled={isDisabled}
                                 value={coordinatorData?.workingHours}
                                 label={i18n.t("others.workload")}
                              />
                           </Grid>

                           <Grid item>
                              <Textfield
                                 name="year"
                                 disabled={isDisabled}
                                 label={i18n.t("others.year")}
                                 type="number"
                                 value={coordinatorData?.workingHours}
                              />
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>
               </Form>
            </Formik>
         </Grid>
         {!loading && (
            <Grid
               style={{
                  backgroundColor: "white",
                  padding: "15px",
                  marginLeft: "-50px",
                  marginRight: "-100px",
               }}
            >
               <ButtonSelect schoolYearId={schoolYearId} totalNumberOfYears={coordinatorData?.totalNumberOfYears} />
            </Grid>
         )}
      </>
   );
}
