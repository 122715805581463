import { Box, Button, Grid, MenuItem, Select, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ActionButtons from "../../components/actions-buttom/ActionButtons";
import CustomPagination from "../../components/pagination";
import { i18n } from "../../translate/i18n";
import { useKeycloak } from "@react-keycloak/web";
import { AddBoxRounded } from "@mui/icons-material";
import AutoSearch from "../../components/autoSearch/AutoSearch";
import Loading from "../../components/loading/loading";
import FallBackCard from "../../components/FallBackComponents/FallBackCard";
import usePagination from "../../utils/hooks/usePagination";
import {
  deleteItem as eliminate,
  editItem as edit,
  visualizeItem,
  getItem as get,
  newItemPage,
} from "../../utils/functions/tableCRUDFunctions";
import AlertDialogForConfirm from "../../components/confirmation/AlertDialog";
import { useConfirmDeleteModal } from "../../utils/hooks/useModals";
import { useFilter, useSearch } from "../../utils/hooks/useSearch";
import { debounce } from "lodash";

export default function SchedulesListingPage() {
  const [IncomingData, setIncomingData] = useState([]);
  const pathUrl = "/schedules";
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [apiUrl, setApiUrl] = useState(`${process.env.REACT_APP_API_URL}/scheduler`);

  const [realUrl, setRealUrl] = useState(null);

  const newItem = newItemPage(navigate, `${pathUrl}/create`);

  const schedulesTypes = [
    {name: "Teorica", url: "scheduler"},
    {name: "Pratica", url: "scheduler-p"}
  ]

  const {
    open,
    deleteItemId,
    message,
    loading,
    setLoading,
    success,
    handleClose,
    handleClickOpen,
    handleSuccess,
    handleBadRequest,
    errorFallback,
  } = useConfirmDeleteModal();

  const analyzeItem = visualizeItem(pathUrl, navigate, realUrl);

  const editItem = edit(pathUrl, navigate, realUrl);

  //Search functionality
  const { searchTerm, setSearchTerm } = useSearch();
  const  canFilter = useFilter();

  const filteringProps = {
    search: searchTerm,

  };

  //Pagination  & Items per page filter
  const items = IncomingData || [];
  const {
    currentPage,
    totalPages,
    setCurrentPage,
    pageSize,
    handlePageSizeChange,
    availablePageSizes,
    handlePaginationData,
  } = usePagination(items, [5, 10, 20, 100]);

  const getItem = get(
    apiUrl,
    keycloak,
    setIncomingData,
    setIsLoading,
    currentPage,
    "schedulesListPage",
    pageSize,
    handlePaginationData,
    canFilter || searchTerm
  );

  const deleteItem = eliminate(
    apiUrl,
    keycloak,
    getItem,
    setLoading,
    handleSuccess,
    handleBadRequest,
    errorFallback,
    totalPages,
    pageSize,
    "schedulesListPage",
  );

  const handleType = (event) => {
    setRealUrl(event)
    const newApiUrl = `${process.env.REACT_APP_API_URL}/${event}`;
    setApiUrl(newApiUrl);
  }

  const debouncedGetItem = debounce((filters) => getItem(filters), 1000);

  useEffect(() => {
    if (keycloak.token) {
      if (searchTerm) {
        debouncedGetItem(filteringProps);
      } else {
        getItem({
          ...filteringProps,
          apiUrl, // Certifique-se de que `getItem` possa aceitar e utilizar `apiUrl`
        });
      }
      setRealUrl(items[0]?.strand.name === "Prática" && "scheduler-p");
    }
  }, [keycloak.token, currentPage, pageSize, canFilter, searchTerm, apiUrl]);


  useEffect(() => {
    localStorage.clear();
  }, [pageSize]);


  return (
    <>
      <Grid container component={Paper}>
        {isLoading && <Loading />}
        {!isLoading && (
          <Grid item xs={12}>
            <Table
              sx={{ border: "1px solid #E9ECF5", fontWeight: "500" }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow height="56px">
                  <TableCell
                    colSpan={9}
                    style={{ height: "59px" }}
                  >
                    <Typography
                      variant="h3"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {i18n.t("course.scheduleList")}
                      <Box sx={{ display: "flex", gap: "10px" }}>
                        <Box sx={{ width: "300px" }}>
                          <AutoSearch
                            placeholder={i18n.t("others.search")}
                            search={searchTerm}
                            setSearch={setSearchTerm}
                            handler={() => setSearchTerm("")}
                            sx={{ width: "300px" }}
                          />
                        </Box>

                        <Box
                          sx={{
                            width: "100px",
                            marginLeft: "20px",
                          }}
                        >
                          <Select
                            value={pageSize}
                            onChange={handlePageSizeChange}
                            displayEmpty
                            inputProps={{ "aria-label": "Page size" }}
                            sx={{
                              width: "100px",
                              height: "40px",
                            }}
                          >
                            {availablePageSizes.map((size) => (
                              <MenuItem key={size} value={size}>
                                {size}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "120px",
                            height: "40px",
                            border: "1px solid #E9ECF5",
                            marginRight: "20px",
                          }}
                        >
                          <Select
                            defaultValue={schedulesTypes[0].url}
                            onChange={(e)=>{
                              handleType(e.target.value)
                            }}
                            inputProps={{ "aria-label": "Type" }}
                            sx={{
                              width: "180px",
                              height: "40px",
                            }}
                          >
                            {schedulesTypes.map((data, i) => (
                              <MenuItem key={i} value={data.url}>
                                {data.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                        <Box>
                          <Button
                            variant="outlined"
                            sx={{
                              height: "35px",
                              width: "80px",
                              color: "#FFC107",
                              backgroundColor: "#FFFFFF",
                              borderColor: "#FFC107",

                              "&:hover": {
                                color: "#FFC107",
                                backgroundColor: "#FFFFFF",
                                borderColor: "#FFC107",
                                // Add other hover styles as needed
                              },
                            }}
                            onClick={newItem}>
                            <AddBoxRounded />
                            {i18n.t("course.new")}
                          </Button>
                        </Box>
                      </Box>
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{
                    backgroundColor: "#F8FAFD",
                    height: "56px",
                  }}
                >
                  <TableCell align="center">
                    {" "}
                    {i18n.t("others.course")}
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    {i18n.t("others.disciplines")}{" "}
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    {i18n.t("others.year")}{" "}
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    {i18n.t("others.semester")}{" "}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("others.strand")}{" "}
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    {i18n.t("others.nºStudents")}{" "}
                  </TableCell>

                  <TableCell align="center">
                    {" "}
                    {i18n.t("others.actions")}{" "}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((data) => (
                  <TableRow
                    key={data.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {data.course?.name}
                    </TableCell>
                    <TableCell align="center">{ data.schedulerDetails ? data?.schedulerDetails[0].subject.name : data.subject?.name}</TableCell>
                    <TableCell align="center">{data.year}</TableCell>
                    <TableCell align="center">{data.semester}</TableCell>
                    <TableCell align="center">
                      {data.strand?.name}
                    </TableCell>
                    <TableCell align="center">
                      {data.course?.totalStudents}
                    </TableCell>
                    <TableCell align="center">
                      {
                        <ActionButtons
                          id={data.id}
                          handleVisualize={analyzeItem}
                          handleEdit={editItem}
                          handleDeleteItem={() => handleClickOpen(data.id)}
                        />
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <FallBackCard data={IncomingData} isLoading={isLoading} />
          </Grid>
        )}
      </Grid>

      {!isLoading && IncomingData?.data?.length ? (
        <Grid sx={{ display: "flex", justifyContent: "center" }}>
          <CustomPagination
            setPage={setCurrentPage}
            totalPages={totalPages}
            page={currentPage}
          />
        </Grid>
      ) : null}

      {deleteItemId && (
        <AlertDialogForConfirm
          id={deleteItemId}
          open={open}
          handleClose={handleClose}
          handleDeleteItem={deleteItem}
          message={message}
          isSuccessful={success}
          isLoading={loading}
        />
      )}
    </>
  );
}
