import {
   Grid,
   Paper,
   Typography,
   styled,
   ThemeProvider,
   createTheme,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import ActionButtons from "../../components/actions-buttom/ActionButtons";
import Select from "../../components/form-components/select";
import SearchIcon from "@mui/icons-material/Search";
import CustomPagination from "../../components/pagination";
import { i18n } from "../../translate/i18n";
import { useKeycloak } from "@react-keycloak/web";
import { useEffect } from "react";
import { useState } from "react";
import { Form, Formik } from "formik";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import Loading from "../../components/loading/loading";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
   [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F8FAFD",
      color: "#939a9f",
   },
   [`&.${tableCellClasses.body}`]: {
      // fontSize: 58,
   },
}));

const theme = createTheme({
   palette: {
      warning: {
         main: "#FFC107",
      },
   },
});

export default function TeacherListingPage() {
   const [teacherIncomingData, setTeacherIncomingData] = useState(null);
   const [currentPage, setCurrentPage] = useState(1);
   const [totalPages, setTotalPages] = useState();
   const navigate = useNavigate();
   const apiUrl = process.env.REACT_APP_API_URL;
   const { keycloak } = useKeycloak();
   const [page, setPage] = useState(localStorage.getItem("enrollPage") || 1);
   const [years, setYears] = useState([]);
   const [semester, setSemester] = useState([]);
   const [employeeData, setEmployeeData] = useState(null);
   const [selectedYear, setSelectedYear] = useState("");
   const [selectedSemester, setSelectedSemester] = useState("");

   const [loading, setLoading] = useState(false);

   const { id } = useParams();

   const handleGetTeacherDataToPreview = (subjectId, courseId) => {
      navigate(`/FinalGradeList/${courseId}/${subjectId}`);
   };
   
   const getList = async (type, set) => {
      try {
         const response = await axios.get(`${apiUrl}/${type}`, {
            headers: {
               Authorization: "Bearer " + keycloak.token, // token de autenticação
            },
         });
         console.log("Resposta da Requisição:", response);
         set(response.data?.data ? response.data.data : response.data);
      } catch (error) {
         console.error(error);
      }
   };

   const getEmployeeInfo = async () => {
      try {
         if (keycloak.authenticated) {
            const userId = keycloak.idTokenParsed.sub;
            const response = await axios.get(`${apiUrl}/employees/user-account/${userId}`, {
               headers: {
                  Authorization: 'Bearer ' + keycloak.token,
               },
            });
            console.log('Informações do funcionário:', response.data);

            setEmployeeData(response.data);
         }
      } catch (error) {
         console.error('Erro ao buscar dados do funcionário:', error);
      }
   };

   useEffect(() => {
      getList("years", setYears);
      getList("semester", setSemester);
      getEmployeeInfo();
   }, []);

   useEffect(() => {
      if (employeeData) {
         const teacherId = employeeData.id;
         if (teacherId) {
            const fetchTeacherSubjects = async () => {
               try {
                  setLoading(true);
                  const response = await axios.get(
                     `${apiUrl}/teacher-semester-subject-plan/find-subjects-current-year/teacher/${teacherId}`,
                     {
                        headers: {
                           Authorization: "Bearer " + keycloak.token,
                        },
                     }
                  );
                  setTeacherIncomingData(response.data); // ou setTeacherIncomingData(response.data.data) dependendo da estrutura da resposta
               } catch (error) {
                  console.error("Erro ao buscar disciplinas do professor:", error);
               } finally {
                  setLoading(false);
               }
            };

            fetchTeacherSubjects();
         }
      }
   }, [employeeData]);

   const handleSearch = () => {
      // Coloque aqui a lógica para lidar com a pesquisa
   };
   const solveDate = (teacherIncomingData) => {
      let d = new Date(teacherIncomingData?.schoolYear?.startDate);
      let EndDate = new Date(teacherIncomingData?.schoolYear?.endDate);
      return (
         teacherIncomingData?.schoolYear?.startDate &&
         d.getFullYear() +
         (teacherIncomingData?.schoolYear?.endDate && "-" + EndDate.getFullYear())
      );
   };

   return (
      <>
         <Grid container component={Paper}>
            {loading && <Loading />}
            <Grid item xs={12}>
               {loading ? null : (
                  <TableContainer sx={{ overflow: "auto" }}>
                     <Formik>
                        <Form>
                           <Table
                              sx={{ minWidth: 650, border: "1px solid #E9ECF5" }}
                              size="small"
                              aria-label="a dense table"
                           >
                              <TableHead>
                                 <TableRow height="70px">
                                    <TableCell align="left" colSpan={3}>
                                       <Typography variant="h3">
                                          {i18n.t("teacher.ListOfDisciplines")}
                                       </Typography>
                                    </TableCell>

                                    <TableCell align="right" colSpan={2}>
                                       <Grid
                                          align="right"
                                          style={{
                                             display: "flex",
                                             gap: "10px",
                                          }}
                                       >
                                          <Select
                                             name="Year"
                                             options={years}
                                             placeholder={i18n.t("others.year")}
                                             onChange={(event) => setSelectedYear(event.target.value)}
                                             label={i18n.t("others.year")}
                                          ></Select>
                                          <Select
                                             name="Semester"
                                             options={semester}
                                             placeholder={i18n.t("others.semester")}
                                             label={i18n.t("others.semester")}
                                             onChange={(event) => setSelectedSemester(event.target.value)}
                                          ></Select>

                                          <Grid sx={{ paddingTop: "1.6rem" }}>
                                             <ThemeProvider theme={theme}>
                                                <Button
                                                   variant="contained"
                                                   fullWidth={true}
                                                   color="warning"
                                                   sx={{
                                                      color: "white",
                                                      minWidth: "0px",
                                                      width: "10px",
                                                   }}
                                                   onClick={handleSearch}
                                                >
                                                   <SearchIcon />
                                                </Button>
                                             </ThemeProvider>
                                          </Grid>
                                       </Grid>
                                    </TableCell>
                                 </TableRow>
                                 <TableRow height="56px">
                                    <StyledTableCell align="left"> {i18n.t("others.disciplines")} </StyledTableCell>
                                    <StyledTableCell align="left"> {i18n.t("others.course")} </StyledTableCell>
                                    <StyledTableCell align="left"> {i18n.t("others.lativeYear")} </StyledTableCell>
                                    <StyledTableCell align="left">Vertente</StyledTableCell>
                                    <StyledTableCell align="center">  {i18n.t("others.options")}</StyledTableCell>
                                 </TableRow>
                              </TableHead>
                              <TableBody>
                                 {teacherIncomingData?.map((teacherData, i) => (
                                    teacherData.subjectClassTypes.map((subjectClassType, j) => (
                                       <TableRow key={`${i}-${j}`}>
                                          <TableCell component="th" scope="row">
                                             {subjectClassType.courseSemesterSubject.subject.name}
                                          </TableCell>
                                          <TableCell align="left">
                                             {subjectClassType.courseSemesterSubject.course.name}
                                          </TableCell>
                                          <TableCell align="left">
                                             {solveDate(teacherData)}
                                          </TableCell>
                                          <TableCell align="left">
                                             {subjectClassType.variant.name}
                                          </TableCell>
                                          <TableCell align="center">
                                             <ActionButtons
                                                handleVisualize={() => handleGetTeacherDataToPreview(subjectClassType.courseSemesterSubject.subject.id, subjectClassType.courseSemesterSubject.course.id)}
                                             />

                                          </TableCell>
                                       </TableRow>
                                    ))
                                 ))}
                              </TableBody>

                           </Table>
                        </Form>
                     </Formik>
                  </TableContainer>
               )}
            </Grid>
         </Grid>
         {!loading && (
            <Grid item xs={12}>
               <CustomPagination totalPages={totalPages} setCurrentPage={setCurrentPage} currentPage={currentPage} />
            </Grid>
         )}
      </>
   );
} 