import { ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import GlobalVariantsChanges from "./GlobalVariantsChanges";
import "./index.css";
import ClassListingPage from "./pages/class/ClassListingPage";
import ClassRegisterPage from "./pages/class/ClassRegisterPage";
import RegisterDepartment from "./pages/department/RegisterDepartment";
import EnrolListPage from "./pages/enroll-student/EnrolListPage";
import EnrollStudentCreatePage from "./pages/enroll-student/EnrollStudent";
import EvaluationPage from "./pages/evaluation/EvaluationPage";
import RegisterEvaluation from "./pages/evaluation/RegisterEvaluation";
import Home from "./pages/home/Home";
import Layout from "./pages/layout/Layout";
import Lessonlisting from "./pages/lessonpage/LessonPage";
import RegisterLesson from "./pages/lessonpage/RegisterLessonPage";
import EmployeeCreatePage from "./pages/staff/EmployeeCreatePage";
import EmployeeListingPage from "./pages/staff/EmployeeListingPage";
import StudentListing from "./pages/student/StudentListingPage";
import SubjectCreatePage from "./pages/subject/SubjectCreatePage";
import SubjectListingPage from "./pages/subject/SubjectListingPage";
import TeacherListingPage from "./pages/teacher/TeacherListingPage";
import ExamsListingPage from "./pages/accessExams/ExamsListingPage";
import CreateExamsPage from "./pages/accessExams/CreateExams";
import EnrollConfirmation from "./pages/enroll-student/Form-components/EnrollConfirmation";
import EditExamsPage from "./pages/accessExams/EditExames";
import EnrollAnalysis from "./pages/enroll-student/EnrollAnalysis";
import AuthProvider from "./AuthContext";
import EnrollUpdate from "./pages/enroll-student/Form-components/EnrollUpdate";
import CurricularPlanListPage from "./pages/curricular-plan/CurricularPlanListPage";
import AddCurricularPlan from "./pages/curricular-plan/AddCurricularPlan";
import SchoolYearListingPage from "./pages/SchoolYear/SchoolYearListingPage";
import SchoolYearListingCreatPage from "./pages/SchoolYear/SchoolYearCreatePage";
import GraduationListingPage from "./pages/Graduation/GraduationListPage";
import GraduationCreatePage from "./pages/Graduation/GraduationCreatePage";
import CourseStudents from "./pages/course/create/CourseStudents";
import CourseTeachers from "./pages/course/create/CourseTeachers";
import SchedulesListingPage from "./pages/schedules/SchedulesListingPage";
import RegisterCourse from "./pages/course/create/CourseRegisterPage";
import CourseListingPage from "./pages/course/create/CourseListingPage";
import DepartmentListing from "./pages/department/DepartmentListing";
import RegisterSchedules from "./pages/schedules/registerSchedules";
import CreateGroups from "./pages/course/create/createGroups";
import TeachersSchedules from "./pages/teacher/TeachersSchedules";
import IssueDocumentConfirmation from "./pages/issueDocument/issueDocumentComponent/IssueDocumentConfirmation";
import IssueDocumentASListingPage from "./pages/issueDocument/issueDocumentsSecretaria/IssueDocumentASListingPage";
import IssueDocumentStudentListingPage from "./pages/issueDocument/issueDocumentStudents/IssueDocumentStudentListingPage";
import IssueDocumentSEListingPage from "./pages/issueDocument/issueDocumentSE/IssueDocumentSEListingPage";
import FinalGradeList from "./pages/teacher/FinalGradeList";
import DisciplinesListPage from "./pages/disciplines/DisciplinesListPage";
import AddNewDiscipline from "./pages/disciplines/AddNewDiscipline";
import RenewalEnroll from "./pages/enroll-student/RenewalEnroll";
import IssueDocumentAnalyse from "./pages/issueDocument/issueDocumentsSecretaria/IssueDocumentSEAnalyse";
import IssueDocumentAsAnalyseSecretaty from "./pages/issueDocument/issueDocumentSE/IssueDocumentASAnalyse";
import ContractListPage from "./pages/contract/ContractListPage";
import ContractForm from "./pages/contract/ContractForm";
import CategoryForm from "./pages/category/CategoryForm";
import CategoryListPage from "./pages/category/CategoryListPage";
import ClassroomTypeListPage from "./pages/classroom-tyype/ClassroomTypeListPage";
import ClassroomTypeForm from "./pages/classroom-tyype/ClassroomTypeForm";
import RoleListPage from "./pages/cargo/RoleListPage";
import RoleForm from "./pages/cargo/RoleForm";
import ReportPage from "./pages/report/report";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common["Language"] = process.env.REACT_APP_LANGUAGE;

ReactDOM.createRoot(document.getElementById("root")).render(
  <AuthProvider>
    <ThemeProvider theme={GlobalVariantsChanges}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<Home />} />
            <Route path="/departments" element={<DepartmentListing />} />
            <Route
              path="/departments/create"
              element={<RegisterDepartment />}
            />
            <Route path="/departments/:id" element={<RegisterDepartment />} />
            <Route
              path="/departments/:id/edit"
              element={<RegisterDepartment />}
            />
            <Route path="/contract" element={<ContractListPage />} />
            <Route path="/contract/add" element={<ContractForm />} />
            <Route path="/contract/:id" element={<ContractForm />} />
            <Route path="/category" element={<CategoryListPage />} />
            <Route path="/category/add" element={<CategoryForm />} />
            <Route path="/category/:id" element={<CategoryForm />} />
            {/* Role page routes */}
            <Route path="/cargo" element={<RoleListPage />} />
            <Route path="/cargo/add" element={<RoleForm />} />
            <Route path="/cargo/:id" element={<RoleForm />} />
            {/* Classroom type page routes */}
            <Route path="/classroom-type" element={<ClassroomTypeListPage />} />
            <Route path="/classroom-type/add" element={<ClassroomTypeForm />} />
            <Route path="/classroom-type/:id" element={<ClassroomTypeForm />} />
            <Route path="/classes" element={<ClassListingPage />} />
            <Route path="/classes/create" element={<ClassRegisterPage />} />
            <Route path="/classes/:id" element={<ClassRegisterPage />} />
            <Route path="/classes/:id/edit" element={<ClassRegisterPage />} />
            <Route path="/courses" element={<CourseListingPage />} />
            <Route path="/courses/create" element={<RegisterCourse />} />
            <Route path="/courses/:id/edit" element={<RegisterCourse />} />
            <Route path="/courses/:id" element={<RegisterCourse />} />
            <Route path="/TeachersSchedules" element={<TeachersSchedules />} />
            <Route path="/teachers/:id" element={<CourseTeachers />} />
            <Route path="/students/:id" element={<CourseStudents />} />
            <Route path="/groups/create" element={<CreateGroups />} />
            <Route path="/groups/create/:id" element={<CreateGroups />} />
            <Route path="/groups/create/:id/edit" element={<CreateGroups />} />
            <Route path="/schedules" element={<SchedulesListingPage />} />
            <Route path="/schedules/:id" element={<RegisterSchedules />} />
            <Route path="/schedules/create" element={<RegisterSchedules />} />
            <Route
              path="/schedules/create/:id/edit"
              element={<RegisterSchedules />}
            />
            <Route path="/MySubjects" element={<TeacherListingPage />} />
            <Route
              path="/finalGradeList/:courseId/:subjectId"
              element={<FinalGradeList />}
            />
            <Route path="/students" element={<StudentListing />} />
            <Route
              path="/students/:id/edit"
              element={<EnrollStudentCreatePage />}
            />
            <Route path="/students/:id" element={<EnrollStudentCreatePage />} />
            <Route
              path="/issuedocument"
              element={<IssueDocumentStudentListingPage />}
            ></Route>
            <Route
              path="/issuedocumentSE"
              element={<IssueDocumentSEListingPage />}
            ></Route>
            <Route
              path="/issuedocumentAS"
              element={<IssueDocumentASListingPage />}
            />{" "}
            <Route
              path="/issuedocument-Analyse/:id"
              element={<IssueDocumentAsAnalyseSecretaty />}
            />
            <Route
              path="/issuedocumentAnalyse/:id"
              element={<IssueDocumentAnalyse />}
            />
            <Route
              path="/issuedocument-confirmation"
              element={<IssueDocumentConfirmation />}
            />
            <Route path="/enrolls" element={<EnrolListPage />} />
            <Route path="/enroll/:id/edit" element={<EnrollAnalysis />} />
            <Route
              path="/subjects/students"
              element={<DisciplinesListPage />}
            />
            <Route
              path="/report"
              element={<ReportPage/>}
            />
            <Route path="/RenewalEnroll" element={<RenewalEnroll />} />
            <Route
              path="/subjects/students/view/:subjectId/:subjectName/:studentId"
              element={<AddNewDiscipline />}
            />
            <Route path="/cargo" element={<RoleListPage />} />
            <Route path="/subjects" element={<SubjectListingPage />} />
            <Route path="/subjects/create" element={<SubjectCreatePage />} />
            <Route path="/subjects/:id" element={<SubjectCreatePage />} />
            <Route path="/subjects/:id/edit" element={<SubjectCreatePage />} />
            <Route path="/lessons" element={<Lessonlisting />} />
            <Route path="/lessons/create" element={<RegisterLesson />} />
            <Route path="/lessons/:id" element={<RegisterLesson />} />
            <Route path="/lessons/:id/edit" element={<RegisterLesson />} />
            <Route path="/SchoolYear" element={<SchoolYearListingPage />} />
            <Route
              path="/SchoolYear/create"
              element={<SchoolYearListingCreatPage />}
            />
            <Route
              path="/SchoolYear/:id"
              element={<SchoolYearListingCreatPage />}
            />
            <Route path="/graduation" element={<GraduationListingPage />} />
            <Route
              path="/graduation/create"
              element={<GraduationCreatePage />}
            />
            <Route path="/graduation" element={<GraduationListingPage />} />
            <Route
              path="/graduation/create"
              element={<GraduationCreatePage />}
            />
            <Route path="/graduations/:id" element={<GraduationCreatePage />} />
            <Route
              path="/graduations/:id/edit"
              element={<GraduationCreatePage />}
            />
            <Route path="/employees" element={<EmployeeListingPage />} />
            <Route path="/employees/create" element={<EmployeeCreatePage />} />
            <Route path="/employees/:id" element={<EmployeeCreatePage />} />
            <Route
              path="/employees/:id/edit"
              element={<EmployeeCreatePage />}
            />
            <Route path="/exams" element={<ExamsListingPage />} />
            <Route path="/exams/create" element={<CreateExamsPage />} />
            <Route path="exams/create/:id" element={<CreateExamsPage />} />
            <Route path="/exams/edit/:id" element={<EditExamsPage />} />
            <Route path="/exams/view/:id" element={<EditExamsPage />} />
            <Route path="/evaluations" element={<EvaluationPage />} />
            <Route
              path="/evaluations/create/:courseId/:subjectId/:subjectName"
              element={<RegisterEvaluation />}
            />
            <Route path="/enrolls/analyse/:id" element={<EnrollAnalysis />} />
            <Route
              path="/curricular-plan"
              element={<CurricularPlanListPage />}
            />
            <Route
              path="/curricular-plan/add"
              element={<AddCurricularPlan />}
            />
            <Route
              path="/curricular-plan/:id"
              element={<AddCurricularPlan />}
            />
          </Route>

          <Route
            path="/enrolls/create-form"
            element={<EnrollStudentCreatePage />}
          />
          <Route
            path="/enrolls/confirmation"
            element={<EnrollConfirmation />}
          />
          <Route path="/enrolls/update/:id" element={<EnrollUpdate />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </AuthProvider>
);
