const messages = {
  en: {
    translations: {
      titles: {
        home: "Home Page",
        mainTitle: "Academic Management System",
      },
      messages: {
        home: "Welcome to our Academic Management System",
        wrong: "Something is Wrong!",
        whatToDo: "What would you like to do?",
        confirmDelete: "Are you sure you want to delete this asset?",
        error: "Error deleting asset",
        success: "Asset deleted successfully",
        createTitle: "Creation Status",
        createMessage: "Asset Created Successfully",
        updateTitle: "Update Status",
        updateMessage: "Asset Was Updated Successfully",
        required: "Required",
        beNumber: "Must be a number",
        bePositiveNumber: "Must be a positive number",
        beEmail: "invalid email",
        notFound: "No asset found",
      },
      others: {
        name: "Name",
        quantity: "Quantity",
        cost: "Cost",
        adquiredDate: "Adquired Date",
        description: "Description",
        classList: "Class List",
        course: "Course",
        nºStudents: "Number of Students",
        nºGroups: "Number of Groups",
        groups: "Groups",
        actions: "Actions",
        filter: "Filter",
        noFilters: "No filters",
        Sigla: "Acronym",
        enrollId: "Enroll ID",
        schedules: "Schedules",
        idCard: "ID Card",
        student: "Student",
        courses: "Courses",
        class: "Class",
        createdAt: "Created Date",
        status: "Status",
        canceledReasons: "Canceled Reasons",
        visualize: "Visualize",
        edit: "Edit",
        delete: "Delete",
        personalInfo: "Personal Info",
        firstName: "First Name",
        lastName: "Last Name",
        birthdate: "Brithdate",
        contact: "Contact",
        phone: "Phone",
        email: "Email",
        address: "Address",
        payment: "Payment",
        NewG: "New Graduation",
        amount: "Amount",
        capacity: "Capacity",
        numberOfStudents: "Nº of Students",
        teacher: "Teacher",
        Classroom: "Classroom",
        subject: "Subject",
        category: "Category",
        addDisciplines: "Add Disciplines",
        semester: "Semester",
        year: "Year",
        yearNumber: "Years Number",
        options: "Actions",
        bribe: "Bribe",
        workload: "Workload",
        coordinator: "Coordinator",
        dateOfAcquision: "Date Of Acquision",
        department: "Department",
        price: "Price",
        designation: "Designation",
        startTime: "Start Time",
        endTime: "End Time",
        date: "Date",
        grade: "Grade",
        type: "Type",
        lesson: "Lesson",
        evaluation: "Evaluation",
        startDate: "Start Date",
        endDate: "End Date",
        headOfProject: "Head of Project",
        numOfCollaborators: "Num. of Collaborators",
        search: "Search",
        employee: "Employee",
        observations: "Observations",
        part: "Part",
        enroll: "Enroll",
        payee: "Recipient",
        executor: "Payer",
        paidDate: "Paid Date",
        hiredDate: "Hired Date",
        role: "Role",
        contractTyoe: "Contract Type",
        contract: "Contract",
        salary: "Salary",
        bank: "Bank",
        userName: "User Name",
        password: "Password",
        cancel: "Cancel",
        fullName: "Full Name",
        paymentMethod: "Payment Method",
        payerDetails: `Payer's Details`,
        payeeDetails: `Beneficiary's Details`,
        schoolYear: "School Year",
        graduationType: "Graduation Type",
        graduation: " Graduation",
        NumberOfVacancies: " Number of Vacancies",
        numberOfCandidates: "Number of Candidates",
        ProcessNumber: "Process number",
        StudentName: "Student name",
        Sex: "Sex",
        Results: "Results",
        generateGroups: "Generate Groups",
        createCourse: "Create Course",
        createGroups: "Create Groups",
        lativeYear: "Lative Year",
        disciplines: "Disciplines",
        cardId: "Card ID",
        mechanographicNº: "Mechanographic Nº",
        yearEnrolled: "Year Enrolled",
        subjectsInArrears: "Subjects In Arrears",
        newrh: "New employee added",
        curricularPlan: "Curricular Plan",
        subjects: "Subjects",
        NewS: "New Subject",
        tuition: "Tuition",
        NfFinalGrade: "NF = Final Grade",
        EXRecAppealExam: "EX REC = Appeal Exam",
        MAcContinuousAssessmentAverage: "MAC = Continuous Assessment Average",
        finalResult: "Final Result",
        continuousEvaluation: "Continuous Evaluation",
        filterBy: "Filter By",
        finish: "Done",
      },
      button: {
        register: "Register",
        return: "Return To",
        stay: "Stay In",
        enroll: "Enroll",
        create: "Create",
        add: "Add",
        createClass: "Create a new Class",
        createEmployee: "New Employee",
        createTeacher: "New Teacher",
        createCourse: "Create a Course",
        createSubject: "Create Subject",
        createLesson: "Create Lesson",
        resgisterEvaluation: "Register Evaluation",
        previous: "Previous",
        next: "Next",
        save: "Save",
        exams: "New",
        cancel: "Cancel",
        subjects: "Subjects",
        schedules: "Schedules",
        students: "Students",
        teachers: "Teachers",
        addDisciplines: "Add Disciplines",
        addShedules: "Add Schedules",
        studentSchedules: "Student Schedules",
        teacherSchedules: "Teacher Schedules",
        filter: "Filter",
        new: "New",
        update: "Update",
        addC: "Add course",
        addT: "Add Teacher",
      },
      sidebar: {
        Enroll: "Enroll",
        Student: "Students",
        schedules: "Schedules",
        Class: "Class",
        Teacher: "Teachers",
        Course: "Course",
        CouseCategory: "Couse Category",
        Subject: "Subject",
        Lesson: "Lessons",
        Evaluation: "Evaluation",
        Projects: "Projects",
        Asset: "Assets",
        Classroom: "Classroom",
        StudentFee: "Student Fee",
        Department: "Department",
        Employees: "Employees",
        Payroll: "Payroll",
        Bank: "Banks",
        Leave: "School year",
        Users: "Users",
        Exams: "Candidates",
        Academic: "Academic",
        HumanResource: "Human Resource",
        graduation: "Graduation",
        settings: "Settings",
        RH: "RH",
        MySubjects: "My Subjects",
        CurricularPlan: "Curricular Plan",
        Subjects: "Subjects",
      },
      curricularPlan: {
        title: "Plans List",
        titleCreate: "New Plan",
      },
      contract: {
        title: "Contracts List",
        titleCreate: "New Contract",
      },
      category: {
        title: "Categories List",
        titleCreate: "New Category",
      },
      role: {
        title: "Roles List",
        titleCreate: "New Role",
      },
      classroomType: {
        title: "Classroom Type List",
        titleCreate: "New Classroom Type",
      },
      subjects: {
        title: "Subject List",
        titleCreate: "New Subject",
        Note: "Notes",
      },
      asset: {
        title: "Asset List",
        titleCreate: "Register Asset",
      },
      enroll: {
        title: "Enroll List",
        titleCreate: "Register Enroll",
        createMessage: "Enrolled Student",
      },
      class: {
        title: "Class List",
        titleCreate: "Create a new Class",
        addClassToCourse: "Associate Class to a Course",
        subjectPlaning: "Subject Planing",
        viewClass: "View Class",
        newClass: "New Class",
      },
      student: {
        title: "Student List",
        registrationId: "Registration ID",
      },
      teacher: {
        title: "Teachers List",
        cardId: "Card ID",
        name: "Name",
        telephone: "Telephone",
        email: "Email",
        theoreticalDisciplines: "Theoretical Disciplines",
        practicalDisciplines: "Practical Disciplines",
        availability: "Availability",
        ListOfDisciplines: "List of Disciplines",
        schedulesGrid: "Schedule Grid",
      },
      course: {
        title: "Course List",
        titleCreate: "Course",
        dragToAdd: "Drag to Add",
        workingHours: "working Hours",
        bribe: "Bribe",
        firstSemester: "1º Semester",
        secondSemester: "2º Semester",
        firstYear: "1º Year",
        secondYear: "2º Year",
        thirdYear: "3º Year",
        fourthYear: "4º Year",
        fifthYear: "5º Year",
        sixthYear: "6º Year",
        biology: "Biology",
        physiotherapy: "Physiotherapy",
        biochemistry: "Biochemistry",
        anatomy: "Anatomy",
        semiology: "Semiology",
        scheduleList: "Schedule List",
        classList: "Class List",
        new: "New",
        monday: "Monday",
        tuesday: "Tuesday",
        wednesday: "Wednesday",
        thursday: "Thursday",
        friday: "Friday",
        saturday: "Saturday",
        class: "Class",
        strand: "Strand",
        period: "Period",
        name: "Course Name",
        addClassrooms: "Add Classrooms",
        nºStudents: "Nº of Students",
      },
      courseCategory: {
        title: "Course Category List",
        titleCreate: "Create a Course Category",
      },
      subject: {
        title: "Subject List",
        titleCreate: "Create Subject",
        createMessage: "subject Created",
        updateMessage: "subject Updated",
      },
      lesson: {
        title: "Lessons List",
        titleCreate: "Create Lesson",
        lessonDetails: "Lesson Details",
        lessonDate: "Lesson Date",
      },
      evaluation: {
        title: "Evaluations List",
        titleCreate: "Create Evaluation",
        Register: "Register Evaluation",
      },
      project: {
        title: "Projects List",
        titleCreate: "Create Project",
        studentCard: "Enter the Student ID Card",
        employeeCard: "Enter the employee ID Card",
      },
      classroom: {
        title: "Classrooms List",
        titleCreate: "Register a Classroom",
      },
      studentFee: {
        title: "Student Fees",
        titleCreate: "Register Student Fee",
      },
      department: {
        title: "Departments List",
        titleCreate: "Create Department",
        addDepart: "Add Departaments",
        addedDeparts: "Added Departments",
      },
      payroll: {
        title: "Payrolls List",
        titleCreate: "Register Payment",
        employeePayment: "Employee Payment",
      },
      employee: {
        title: "Employees List",
        titleCreate: "Register an Employee",
        hiringDetails: "Hiring Details",
        paymentDetails: "Payment Details",
        dpUpdate: "Updated employee data",
        formError:
          "Please select a department, employee category, and employee role",
      },
      bank: {
        title: "Banks List",
        titleCreate: "Register Banks",
      },
      leave: {
        title: "Leaves List",
        titleCreate: "Register an Leave",
        leavePeriod: "Leave Period",
        createMessage: "Leave Created Successfully",
      },
      user: {
        title: "Users List",
      },
      ExamsList: {
        title: "List of Candidates",
        titleCreate: "List of Candidate",
        editExms: "Edit Candidate",
      },
      graduations: {
        dpUpdate: "graduation updated",
      },
      request: {
        netError:
          "A network error occurred. Please check your connection and try again.",
        serverError: "The server encountered an error. Please try again later.",
        unauthorized: "You do not have permission to access this resource.",
        syntaxError:
          "A syntax error occurred. Please check the data and try again. If the problem persists, contact the system administrator.",
        badRequest:
          "An error occurred in the request. Please check the data and try again. If the error persists, contact the system administrator.",
      },
      schedules: {
        createSuccessMessage: "Schedules Created Successfully",
        urlStay: "schedules",
        urlGoBack: "schedules",
        noSchedule: "To create schedules you must add the data correctly",
        missingTeacher: "Please click on the subject name to add the teacher",
      }
    },
  },
};

export { messages };
