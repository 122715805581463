import React, { useEffect,useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,

} from '@mui/material';
import { i18n } from '../../translate/i18n';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';


export default function ReportPage() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { keycloak } = useKeycloak();
  const [studentSubjects, setStudentSubjects] = useState(null);
  const [semester,setSemester] = useState([]);
  const [year,setYear] = useState([]);

  const goToPreviousYear = () => {
    console.log("Retroceder um ano");
    // Adicione aqui a lógica necessária para navegar para trás nos anos
  };

  const goToNextYear = () => {
    console.log("Avançar um ano");
    // Adicione aqui a lógica necessária para navegar para frente nos anos
  };

  const getList = async (type, set) => {
    try {
        const response = await axios.get(`${apiUrl}/${type}`, {
            headers: {
                Authorization: "Bearer " + keycloak.token,
            },
        });
        
        set(response.data?.data ? response.data.data : response.data);
    } catch (error) {
        console.error(error);
    }
};

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const userId = keycloak.idTokenParsed.sub;
        const studentResponse = await axios.get(`${apiUrl}/students/user-account/${userId}`, {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        });
        const studentId = studentResponse.data.id;

        const subjectsResponse = await axios.get(`${apiUrl}/students/${studentId}/subjects`, {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        });

        setStudentSubjects(subjectsResponse.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchStudentData();
    getList("semester",setSemester);
    getList("years",setYear);
  }, [apiUrl, keycloak.token]);


  const reportData = {
    disciplines: [
      'Disciplina 1',
      'Disciplina 2',
      'Disciplina 3',
      'Disciplina 4',
    
  
    ],
    tests: [
      { type: 'BI', scores: [75, 80, 85, 90]},
      { type: 'BII', scores: [85, 90, 88, 92]},
      { type: 'TI', scores: [90, 88, 85, 87] },
      { type: 'TII', scores: [80, 85, 82, 88]},
      { type: 'PI', scores: [85, 90, 88, 92] },
      { type: 'PII', scores: [90, 88, 85, 87]},
      { type: 'Avaliação Contínua', scores: [80, 85, 82, 88] },
      { type: 'Nota final', scores: [85, 90, 88, 92] },
    ],
  };

  return (
    <Grid container component={Paper}>
      <Grid item xs={12}>
        <TableContainer sx={{ overflow: 'hidden' }}>
          <Table sx={{ minWidth: 650, border: '1px solid blue', borderRight: '1px solid blue' }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell  colSpan={6} style={{ height: "59px", borderBottom: '1px solid blue', background: ' #CCD8E4', color: 'black' }}>
                  <Typography
                    variant="h3"
                    sx={{
                      display: "flex",
                   
                      justifyContent: "space-between",
                    }}
                  >
                    {i18n.t("Relatório")}
                    <Box display="flex" justifyContent={"end"} >
                    <Grid container   >
                      <Grid item display={"flex"} sx={{marginRigh:"-150px"}}>
                        <Button onClick={goToPreviousYear} className="arrow-button" style={{ border: 'none', background: 'none', color: 'black' }}>&lt;</Button>
                        <Typography
                          variant="p"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            fontSize:"12px"
                          }}
                        >
                          {i18n.t("1º ano")}
                        </Typography>
                        <Button onClick={goToNextYear} className="arrow-button" style={{ border: 'none', background: 'none', color: 'black' }}>&gt;</Button>
                      </Grid>
                      <Grid item display={"flex"}>
                        <Button onClick={goToPreviousYear} className="arrow-button" style={{ border: 'none', background: 'none', color: 'black' }}>&lt;</Button>
                        <Typography
                          variant="p"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            fontSize:"12px"
                          }}
                        >
                          {i18n.t("1º semestre")}
                        </Typography>
                        <Button onClick={goToNextYear} className="arrow-button" style={{ border: 'none', background: 'none', color: 'black',marginRigh:"-100px" }}>&gt;</Button>
                      </Grid>
                    </Grid>
                    </Box>
                  </Typography>
                  
                </TableCell>
                <TableCell align="right" style={{ height: "59px", borderBottom: '1px solid blue', color: 'black', background: ' #CCD8E4' }}>
       
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" style={{ borderRight: '1px solid blue', borderBottom: '1px solid blue', background: ' #CCD8E4' }}>
                  {i18n.t("Avaliações/Disciplinas")}
                </TableCell>
                {reportData.disciplines.map((discipline) => (
                  <React.Fragment key={discipline}>
                    <TableCell align="center" style={{ width: 200, borderLeft: '1px solid blue', borderRight: '1px solid blue', borderBottom: '1px solid blue', background: ' #CCD8E4', color: 'black', height: '100%' }}>
                      {discipline}
                    </TableCell>
                  </React.Fragment>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {reportData.tests.map((test) => (
                <TableRow key={test} sx={{ height: 60 }}>
                  <TableCell component="th" scope="row" style={{ borderBottom: '1px solid blue', color: 'black', height: '100%' }}>
                    {i18n.t(test.type)}
                  </TableCell>
                  {test.scores.map((score) => (
                    <React.Fragment key={score}>
                      <TableCell align="center" style={{ width: 200, borderLeft: '1px solid blue', borderRight: '1px solid blue', borderBottom: '1px solid blue', height: '100%' }}>
                        {score}
                      </TableCell>
                    </React.Fragment>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
