
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import mainLogo from "../../assets/mainLogo.png";
import {  useState } from "react";
import "./SideBar.css";
import Hidden from "@mui/material/Hidden";
import { Avatar, Grid, IconButton, MenuItem, Select, Typography } from "@mui/material";
import { i18n } from "../../translate/i18n";
import Button from "@mui/material/Button";
import { useKeycloak } from "@react-keycloak/web";
import MenuIcon from "@mui/icons-material/Menu";


export default function SideBar(authenticated = { authenticated }) {
  
   console.log("autentic", authenticated);
   const [isSidebarVisible, setIsSidebarVisible] = useState(false);
   const { keycloak } = useKeycloak();
   console.log("userRoles:", keycloak?.idTokenParsed?.realm_access?.roles || []);
   const navigate = useNavigate();
   const location = useLocation();
   const [isLogoutVisible, setIsLogoutVisible] = useState(false);
   const [isMenuOpen, setIsMenuOpen] = useState(false);

   const handleAvatarClick = () => {
      setIsLogoutVisible(!isLogoutVisible);

      if (window.innerWidth < 1200) {
         setIsMenuOpen(!isMenuOpen);
      }
   };


   const handleLogout = () => {
      keycloak.logout({ redirectUri: window.location.origin });
   };
   const handleClick = (url) => navigate(url);
   const I18N_STORAGE_KEY = "i18nextLng";
   const [language] = React.useState(localStorage.getItem(I18N_STORAGE_KEY));

   const handeSelectChange = (e) => {
      const v = e.target.value;
      localStorage.setItem(I18N_STORAGE_KEY, v);
      window.location.reload(); // Recarrega a página atual
    };
    
  const menu = [
    {
      items: [
        {
          title: i18n.t("titles.home"),
          image: "/icon/home.svg",
          selectedImage: "/icon/homeBold.svg",
          url: "/home",
          role: [
            "Admin",
            "Aluno",
            "Professor",
            "Secretaria",
            "Coordenador",
            "Financeiro",
            "Coordenador Anual"
          ],
        },

            {
               title: i18n.t("sidebar.Enroll"),
               image: "/icon/Matriculas.svg",
               selectedImage: "/icon/enrollBold.svg",
               url: "/enrolls",
               role: ["Secretaria", "Financeiro", "Admin"],
            },
            {
               title: i18n.t("sidebar.Exams"),
               image: "/icon/Candidatos.svg",
               selectedImage: "/icon/book.svg",
               url: "exams",
               role: ["Admin"],
            },

            {
               title: i18n.t("sidebar.RH"),
               image: "/icon/RH.svg",
               selectedImage: "/icon/people.svg",
               url: "/employees",
               role: ["Admin"],
            },
            {
               title: i18n.t("sidebar.Leave"),
               image: "/icon/AnoLetivo.svg",
               selectedImage: "/icon/book.svg",
               url: "/SchoolYear",
               role: ["Admin"],
            },
            {
               title: i18n.t("Perfil Academico"),
               image: "/icon/profile (1).svg",
               url: "/students",
               role: ["Aluno"],
            },
            {
               title: i18n.t("Emissao de Documento"),
               image: "/icon/EmitDocAluno.svg",
               url: "/issuedocument",
               role: ["Aluno"],
            },

            {
               title: i18n.t("Emissao de Documento"),
               image: "/icon/EmitDoc.svg",
               url: "/issuedocumentSE",
               role: ["Financeiro"],
            },
            {
               title: i18n.t("Emissao de Documento"),
               image: "/icon/EmitDoc.svg",
               url: "/issuedocumentAS",
               role: ["Secretaria"],
            },


        {
          title: i18n.t("sidebar.MySubjects"),
          image: "/icon/mySubjects.svg",
          selectedImage: "/icon/MinhasDiciplinas.svg",
          url: "/MySubjects",
          role: ["Professor"],
        },
        {
          title: i18n.t("sidebar.schedules"),
          image: "/icon/horario.svg",
          selectedImage: "/icon/HorárioG.svg",
          url: "/TeachersSchedules",
          role: ["Professor"],
        },
        {
          title: i18n.t("sidebar.Course"),
          image: "/icon/Cursos.svg",
          selectedImage: "/icon/courseG.svg",
          url: "/courses",
          role: ["Coordenador","Coordenador Anual"],
        },
        {
          title: "Minhas Disciplinas",
          image: "/icon/Disciplinas.svg",
          url: "/subjects/students",
          role: ["Aluno"],
        },
        {
          title: i18n.t("Relatorio"),
          image: "/icon/Relatorio.svg",
          url: "/report",
          role: ["Aluno"],
        },

            {
               title: i18n.t("sidebar.Enroll"),
               image: "/icon/Notas.svg",
               url: "/RenewalEnroll",
               role: ["Aluno"],
            },
            {
               title: i18n.t("sidebar.Note"),
               image: "/icon/Notas.svg",
               url: "/evaluations/note",
               role: ["Secreteria"],
            },
         ],
      },
      {
         section: "sidebar.settings",
         image: "/icon/Configuracoes.svg",

      items: [
      
        {
          title: i18n.t("sidebar.schedules"),
          image: "/icon/horario.svg",
          selectedImage: "/icon/HorárioG.svg",
          url: "/schedules",
          role: ["Coordenador","Coordenador Anual"],
        },
        {
          title: i18n.t("sidebar.Class"),
          image: "/icon/turmas.svg",
          selectedImage: "/icon/TurmaG.svg",
          url: "/classes",
          role: ["Coordenador","Coordenador Anual"],
        },
        {
          title: i18n.t("sidebar.Evaluation"),
          image: "/icon/Notas.svg",
          url: "/evaluations",
          role: ["Coordenador","Coordenador Anual"],
        },
        {
          title: i18n.t("others.contract"),
          image: "/icon/PlanoCurricular.svg",
          selectedImage: "/icon/calenderBold.svg",
          url: "/contract",
          role: ["Admin"],
        },
        {
          title: i18n.t("others.category"),
          image: "/icon/PlanoCurricular.svg",
          selectedImage: "/icon/calenderBold.svg",
          url: "/category",
          role: ["Admin"],
        },
        {
          title: i18n.t("others.role"),
          image: "/icon/PlanoCurricular.svg",
          selectedImage: "/icon/calenderBold.svg",
          url: "/cargo",
          role: ["Admin"],
        },

            {
               title: i18n.t("sidebar.graduation"),
               image: "/icon/Graduacao.svg",
               selectedImage: "/icon/teacherBold.svg",
               url: "graduation",
               role: ["Admin"],
            },
            {
               title: i18n.t("sidebar.Department"),
               image: "/icon/Departamento.svg",
               selectedImage: "/icon/briefcase (1).svg",
               url: "/departments",
               role: ["Admin"],
            },
            {
               title: i18n.t("others.TypeofRoom"),
               image: "/icon/PlanoCurricular.svg",
               selectedImage: "/icon/calenderBold.svg",
               url: "/classroom-type",
               role: ["Admin"],
            },
            {
               title: i18n.t("sidebar.Lesson"),
               image: "/icon/presentiont.svg",
               selectedImage: "/icon/presention-chart.svg",
               url: "/lessons",
               role: ["Admin"],
            },
            {
               title: i18n.t("others.Subjects"),
               image: "/icon/Disciplinas.svg",
               selectedImage: "/icon/note-2.svg",
               url: "/subjects",
               role: ["Admin"],
            },
            {
               title: i18n.t("others.curricularPlan"),
               image: "/icon/PlanoCurricular.svg",
               selectedImage: "/icon/calenderBold.svg",
               url: "/curricular-plan",
               role: ["Admin"],
            },

            // {
            //     title: i18n.t('sidebar.Bank'),
            //     icon: <AccountBalanceIcon color='#8893A9' />,
            //     url: '/banks'
            // },

            // { title: i18n.t('sidebar.Users'),icon: <PortraitIcon color='#8893A9' />,  url: '/users', },
         ],
      },
   ];

   console.log("userRoles:", keycloak?.tokenParsed?.resource_access?.["sga-api"]?.roles || []);

   const userRoles = keycloak?.tokenParsed?.resource_access?.["sga-api"]?.roles || [];
   const filteredMenu = menu
      .map((category) => {
         const filteredItems = category.items.filter(
            (item) => !item.role || userRoles.some((userRole) => item.role.includes(userRole))
         );
         return { ...category, items: filteredItems };
      })
      .filter((category) => category.items.length > 0);

   // Filter settings section based on user roles
  
   const toggleSidebar = () => {
      setIsSidebarVisible(!isSidebarVisible);
   };

   return (
      <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}>
         <AppBar
            sx={{
               zIndex: (theme) => theme.zIndex.drawer + 1,
               backgroundColor: "white",
               boxShadow: "none",
               borderBottom: "3px solid #E9ECF5",
            }}
         >
            <Toolbar>
               <Hidden mdUp>
                  <IconButton
                     edge="start"
                     color="black"
                     aria-label="menu"
                     className="menu-button" // Aplique a classe menu-button
                     onClick={toggleSidebar}
                  >
                     <MenuIcon />
                  </IconButton>
               </Hidden>
               <Grid
                  container
                  className={"header"}
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                     flexDirection: { xs: "flex", md: "row" },
                     flexWrap: "nowrap", // Evita a quebra de linha
                  }}
               >
                  <Grid item sx={{ display: { xs: "flex", alignItems: "center" } }}>
                     <img
                        src={mainLogo}
                        alt="logo"
                        style={{
                           width: "63px",
                           alignItems: "left",
                           padding: "10px 20px",
                           display: "flex",
                           justifyContent: "lef",
                        }}
                     />
                  </Grid>

                  <Grid item xs={12} md={6} sx={{ display: { xs: "flex", md: "flex" } }}>
                     <Hidden smDown>
                        <Typography variant="h1" style={{ fontSize: "1.2rem", marginLeft: "8rem" }}>
                           Sistema de Gestão Académica
                        </Typography>
                     </Hidden>
                  </Grid>

                  <Grid item>
                     <Select
                        size="small"
                        sx={{
                           background: " #EFEFEF ",
                           borderRadius: "2px",
                           border: "1 solid #E9ECF5",
                           marginRight: "-60px",
                        }}
                        onChange={handeSelectChange}
                        defaultValue={language}
                     >
                        <MenuItem value="pt">Português</MenuItem>
                        <MenuItem value="en">Ingles</MenuItem>
                     </Select>
                  </Grid>
                  <Grid
                     item
                     style={{
                        marginLeft: "30px",
                        marginTop: "5px",
                        marginRight: "-40px",
                     }}
                  >
                     <svg
                        width="30"
                        height="30"
                        viewBox="0 0 26 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="self-center mr-3"
                     >
                        <path
                           fillRule="evenodd"
                           clipRule="evenodd"
                           d="M20.3485 17.8077V11.3974C20.3485 7.46154 18.246 4.16667 14.5793 3.29487V2.42308C14.5793 1.35897 13.7075 0.5 12.6434 0.5C11.5793 0.5 10.7332 1.35897 10.7332 2.42308V3.29487C7.05368 4.16667 4.96393 7.44872 4.96393 11.3974V17.8077L3.29727 19.4615C2.48957 20.2692 3.05368 21.6538 4.1947 21.6538H21.0793C22.2203 21.6538 22.7973 20.2692 21.9896 19.4615L20.3485 17.8077ZM12.6427 25.5C14.053 25.5 15.2068 24.3462 15.2068 22.9359H10.0786C10.0786 24.3462 11.2197 25.5 12.6427 25.5ZM5.95009 3.35901C6.48855 2.87183 6.50137 2.0385 5.98855 1.52568C5.50137 1.0385 4.7065 1.02568 4.2065 1.50004C2.01419 3.50004 0.501373 6.21799 0.0141935 9.26927C-0.101191 10.0513 0.501373 10.7564 1.29624 10.7564C1.91163 10.7564 2.45009 10.3077 2.55265 9.69235C2.93727 7.20517 4.16804 4.98722 5.95009 3.35901ZM21.1158 1.50004C20.6029 1.02568 19.8081 1.0385 19.3209 1.52568C18.8081 2.0385 18.8337 2.85901 19.3593 3.34619C21.1286 4.9744 22.3722 7.19235 22.7568 9.67952C22.8465 10.2949 23.385 10.7436 24.0132 10.7436C24.7952 10.7436 25.4106 10.0385 25.2824 9.25645C24.7952 6.21799 23.2952 3.51286 21.1158 1.50004Z"
                           fill="#C3CAD9"
                        />
                     </svg>
                  </Grid>

                  <Divider orientation="vertical" variant="middle" flexItem />

                  <div
                     className="profile"
                     style={{
                        marginRight: "10px",
                        marginTop: "-5px",
                        marginLeft: "-50px",
                     }}
                  >
                     <Avatar
                        sx={{ width: 50, height: 50 }}
                        alt="Profile"
                        src={keycloak?.tokenParsed?.picture}
                        onClick={handleAvatarClick}
                     />
                     <div className="info" style={{ marginLeft: "5px" }}>
                        <Typography variant="p" className="name">
                           {keycloak?.tokenParsed?.given_name} {keycloak?.tokenParsed?.family_name}
                        </Typography>
                        <Typography variant="p" className="role">
                           {userRoles.length > 0 ? userRoles.join(", ") : "No Roles"}
                        </Typography>
                     </div>
                  </div>
                  {isLogoutVisible && (
                     <div className="logout-button-container">
                        {keycloak.authenticated ? (
                           <Button
                              onClick={handleLogout}
                              sx={{
                                 background: " #EFEFEF ",
                                 borderRadius: "2px",
                                 border: "1 solid #E9ECF5",
                                 color: "black",
                                 padding: "8px 10px",
                                 width: "30px",
                                 marginTop: "-20px",
                                 marginLeft: "-100px",
                              }}
                           >
                              Logout
                           </Button>
                        ) : null}
                     </div>
                  )}
               </Grid>
            </Toolbar>
         </AppBar>

         {authenticated &&
            (console.log("oi", authenticated),
            (
               <Drawer
                  sx={{
                     flexShrink: 0,
                     "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                     },
                  }}
                  variant="persistent"
                  anchor="left"
                  open={window?.innerWidth < 1200 ? isSidebarVisible : true}
               >
                  <Toolbar />
                  <Box sx={{ overflow: "auto", paddingTop: "25px", width: "210px" }}>
                     {filteredMenu.map((category, categoryIndex) => (
                        <div key={categoryIndex}>
                           {category.image && (
                              <List
                                 sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: "15px",
                                 }}
                              >
                                 <img
                                    src={category.image}
                                    alt={category.section}
                                    style={{ width: "15px", height: "15px" }}
                                 />
                                 <p className="category">{i18n.t(category.section)}</p>
                              </List>
                           )}

                           {category.items.map((element, itemIndex) => (
                              <List
                                 sx={{
                                    overflowY: "hidden",
                                    margin: 0,
                                    padding: 0,
                                    marginLeft: category.image ? "20px" : "0px",
                                 }}
                                 key={itemIndex}
                              >
                                 <ListItem
                                    disablePadding
                                    sx={{
                                       padding: 0,
                                       height: 40, // Adjust the height as needed
                                       alignItems: "center",
                                       justifyContent: "center",
                                    }}
                                 >
                                    <ListItemButton
                                       onClick={() => handleClick(element.url)}
                                       sx={{
                                          "&:hover": {
                                             backgroundColor: "transparent", // remove hover effect
                                          },
                                          "&.Mui-focusVisible": {
                                             backgroundColor: "transparent", // remove focus effect
                                          },
                                       }}
                                       disableRipple
                                    >
                                       <ListItemIcon
                                          sx={{
                                             marginRight: -4,
                                          }}
                                       >
                                          <img
                                             src={
                                                location.pathname.includes(element.url)
                                                   ? element.selectedImage // Imagem quando selecionado
                                                   : element.image // Imagem normal
                                             }
                                             alt={element.title}
                                             style={{
                                                width: "15px",
                                                height: "15px",
                                             }}
                                          />
                                       </ListItemIcon>
                                       <p
                                          style={{
                                             fontSize: "13px",
                                             fontWeight: "500",
                                             fontFamily: "Work Sans",
                                             lineHeight: "16px",
                                             letterSpacing: "0em",
                                             textAlign: "left",
                                             color: location.pathname.includes(element.url)
                                                ? "#FFC107" // Change this to the desired selected color
                                                : "#8893A9",
                                             margin: "0",
                                             padding: "0",
                                          }}
                                       >
                                          {element.title}
                                       </p>
                                    </ListItemButton>
                                 </ListItem>
                              </List>
                           ))}
                        </div>
                     ))}
                  </Box>
               </Drawer>
            ))}
      </Box>
   );
}
