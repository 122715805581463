
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import { Box, CardContent, Grid, Typography,Button  } from "@mui/material";
import MultipleSelect from "../select/select";
import { i18n } from "../../translate/i18n";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { useEffect,useState } from "react";
import PropTypes from 'prop-types';

export default function FilterList({
  cardStyle,
  filters = undefined,
  setCanFilter,
  canFilter,
}) {
  /**
   * filters must have this format:
   *
   * [ {label:"", options:[], selectedOptions:[], setSelectedOptions: ()=>{}} ]
   *
   * @label filtering field label
   * @options filtering field options
   * @selectedOptions selected options state
   * @setSelectedOptions selected options state setter for the current filter's selected options
   *
   * @setCanFilter enables the table filtering
   */

  const [isCardOpen, setIsCardOpen] = useState(false);

  const handleIconButtonClick = () => {
    setIsCardOpen(!isCardOpen);
  };



  //No longer needed, not removed to avoid breaking existing implementations
  const apiUrl = process.env.REACT_APP_API_URL;
  const { keycloak } = useKeycloak();

  const [years, setYears] = useState([]);
  const [semester, setSemester] = useState([]);
  const [subject, setSubject] = useState([]);

  const getList = async (type, set) => {
    try {
      const response = await axios.get(`${apiUrl}/${type}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token, // token de autenticação
        },
      });
      console.log("Resposta da Requisição:", response);
      set(response.data?.data ? response.data.data : response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!Array.isArray(filters)) {
      getList("years", setYears);
      console.log("year:", years);
      getList("semester", setSemester);
      console.log("semester:", semester);
      getList("subject", setSubject);
      console.log("disciplines:", subject);
    }
  }, []);

  const renderDefaultOptions = () => {
    if (!Array.isArray(filters)) {
      const optionsYears = years.map((item) => item.value);

      const optionsSemester = semester.map((item) => item.name);

      const optionsSubject = subject.map((item) => item.name);

      return (
        <>
          <MultipleSelect
            label={i18n.t("others.year")}
            options={optionsYears}
          />
          <MultipleSelect
            label={i18n.t("others.semester")}
            options={optionsSemester}
          />
          <MultipleSelect
            label={i18n.t("others.disciplines")}
            options={optionsSubject}
          />
        </>
      );
    }
    return null;
  };
  //Irrelevant code ends here

  return (
    <>
      {canFilter ? (
        <IconButton
          onClick={() => {
            setCanFilter(false);
            filters.map((filter) => filter.setSelectedOptions([]));
          }}
          disableRipple
        >
          <CloseIcon fontSize="large" />
        </IconButton>
      ) : (
        <IconButton onClick={handleIconButtonClick} disableRipple>
          <FilterListIcon fontSize="large" style={{ background: "white" }} />
        </IconButton>
      )}
      <div
        style={{
          position: "absolute",
          height: "100vh",
          top: 0,
          right: -280,
        }}
      >
        {isCardOpen && (
          <Card sx={{ minWidth: 295, ...cardStyle }}>
            <CardContent
              style={{ display: "column", width: "full", padding: "0px" }}
            >
              <Grid
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  border: "1px solid #E9ECF5",
                  borderRadius: "5px",
                }}
              >
                <Typography style={{ margin: "12px" }}>
                  {" "}
                  {filters?.length

                    ? i18n.t("others.filterBy")
                    : i18n.t("others.noFilters")}{" "}
                </Typography>


                <IconButton onClick={handleIconButtonClick}>
                  <CloseIcon fontSize="large" />
                </IconButton>
              </Grid>
              <Grid>
                {renderDefaultOptions()}
                {Array.isArray(filters) && filters.length
                  ? filters.map((filter) => (
                    <MultipleSelect
                      key={filter?.label}
                      label={filter?.label}
                      options={filter?.options}
                      selectedOptions={filter?.selectedOptions}
                      setSelectedOptions={filter?.setSelectedOptions}
                    />
                  ))
                  : null}
              </Grid>
              {filters?.length ? ( 
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <Box>
                    <Button
                      variant="text"
                      sx={{
                        color: "#FFC107",
                        padding: 1,
                        border: "1px solid #FFC107",
                      }}
                      onClick={() => {
                        handleIconButtonClick();
                        setCanFilter(false);
                        filters.map((filter) => filter.setSelectedOptions([]));
                      }}
                    >
                      {i18n.t("button.cancel")}
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      variant="text"
                      sx={{
                        padding: 1,
                        color: "white",
                        backgroundColor: "#FFC107",
                      }}
                      onClick={() => {
                        handleIconButtonClick();
                        setCanFilter(true);
                      }}
                    >
                      {i18n.t("button.filter")}
                    </Button>
                  </Box>
                </Grid>
              ) : null}
            </CardContent>
          </Card>
        )}
      </div>
    </>
  );
}
FilterList.propTypes = {
  cardStyle: PropTypes.object,
  filters: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    selectedOptions: PropTypes.array.isRequired,
    setSelectedOptions: PropTypes.func.isRequired
  })),
  setCanFilter: PropTypes.func.isRequired,
  canFilter: PropTypes.bool.isRequired,
};
