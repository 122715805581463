import { useCallback, useEffect, useState } from "react";
import { Button, Typography,Grid} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Selected from "../../components/form-components/select";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import HandleUrl from "../../utils/HandleUrl";
import { Form, Formik } from "formik";
import { i18n } from "../../translate/i18n";
import CardSchedules from "../schedules/components/CardSchedules";
import { transformScheduleData } from "../schedules/functions";

const ScheduleTable = () => {
   const { keycloak } = useKeycloak();
   const apiUrl =process.env.REACT_APP_API_URL;

   const [days, setDays] = useState([]);
   const [times, setTimes] = useState([]);

   let isDisabled=HandleUrl();
   const [classes, setClasses] = useState([]);
   const [classrooms, setClassrooms] = useState([]);
   const [groups, setGroups] = useState([]);
   const [period, setPeriod] = useState([]);
   const [strand, setStrand] = useState([]);
   const [listDiscipline, setListDiscipline] = useState([]);
   const [selectedStrand, setSelectedStrand] = useState([]);
   const [selectedPeriod, setSelectedPeriod] = useState([{ name: "Manhã" }]);

   const getSchedules = async () => {
      try {
         const userId = keycloak.idTokenParsed.sub;

         const response = await axios.get(`${apiUrl}/employees/user-account/${userId}`, {
            headers: {
               Authorization: "Bearer " + keycloak.token,
            },
         });
         const employeeData = response.data;
       

         const responseScheduler = await axios.get(`${apiUrl}/scheduler/teacher/${employeeData?.id}`, {
            headers: {
               Authorization: "Bearer " + keycloak.token,
            },
         });

         return responseScheduler.data;
      } catch (error) {
         console.error("Error fetching schedules:", error);
         throw error;
      }
   };

   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await getSchedules();
            setSchedule(response);
            getDisciplineData(response[0]?.subject?.id);
            getStrandData(response?.strand?.id);

            if (period.length !== 0) {
               getPeriodData(response?.period?.id);
            }
         } catch (error) {
            console.error("Error fetching data:", error);
         }
      };
      fetchData();
   }, []);

   const [schedule, setSchedule] = useState({});
   
   const getList = useCallback(async (type, set) => {
      try {
         const response = await axios.get(`${apiUrl}/${type}`, {
            headers: {
               Authorization: "Bearer " + keycloak.token,
            },
         });

         set(response.data?.data ? response.data?.data : response?.data);
         return response.data?.data ? response.data?.data : response?.data;
      } catch (error) {
         console.error(error);
      }
   });

   useEffect(() => {
      getList("classes/class-subject/by-coordinator", setClasses);
      getList("classrooms", setClassrooms);
      getList("group", setGroups);
      getList("period", setPeriod);
      getList("strand", setStrand);
      getList("teaching-days", setDays);
      getList("teaching-time", setTimes);
   }, [apiUrl]);

   async function getDisciplineData(e) {
      let disciplineS = classes.filter((elemento) => elemento.subject?.id === parseInt(e));

      setListDiscipline(disciplineS);
      setGroups(disciplineS[0]?.groups);
   }

   const scheduleMap = schedule && transformScheduleData(schedule, days, times);

   async function getStrandData(e) {
      if (e?.target) {
         let stranded = strand.filter((elemento) => elemento.id === e.target.value);
         setSelectedStrand(stranded);
      }
   }

   async function getPeriodData(e) {
      if (e?.target) {
         let periods = period.filter((elemento) => elemento.id === e.target.value);
         setSelectedPeriod(periods);
      }
   }

   return (
      <div>
         <Grid container spacing={2}>
            <Grid xs={12}>
               <Formik
                  onSubmit={(values) => {
                    
                  }}
                  enableReinitialize
               >
                  <Form>
                     <Grid
                        style={{
                           marginTop: "20px",
                           marginLeft: "-10px",
                           border: "1px solid #ccc",
                           borderRadius: "4px",
                           backgroundColor: "#fff",
                        }}
                        container
                     >
                        <Grid
                           xs={12}
                           align="right"
                           sx={{
                              display: "flex",
                              gap: "10px",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              padding: "10px",
                              borderBottom: "1px solid #ccc",
                           }}
                        >
                           <Grid xs={2}>
                              <Selected
                                 name="class"
                                 options={classes}
                                 label={"Turma"}
                                 disabled={isDisabled}
                                 onChange={(e) => getDisciplineData(e)}
                              ></Selected>
                           </Grid>
                           <Grid xs={2}>
                              <Selected
                                 name="strand"
                                 options={strand}
                                 label={"Vertente"}
                                 onChange={(e) => getStrandData(e)}
                                 disabled={isDisabled}
                              ></Selected>
                           </Grid>
                           <Grid xs={2}>
                              <Selected
                                 name="period"
                                 options={period}
                                 label={"Periodo"}
                                 disabled={isDisabled}
                                 onChange={(e) => getPeriodData(e)}
                              ></Selected>
                           </Grid>
                           <Grid item>
                              <div
                                 style={{
                                    paddingTop: "1rem",
                                    
                                 }}
                              >
                                 <span>
                                    <Button
                                       variant="contained"
                                       type="submit"
                                       fullWidth={true}
                                       color="warning"
                                       sx={{
                                          backgroundColor: "#FFC107",
                                          color: "white",
                                          paddingY: "5px",
                                          width: "10px",
                                       }}
                                    >
                                       <SearchIcon />
                                    </Button>
                                 </span>
                              </div>
                           </Grid>
                        </Grid>
                        <Grid
                           style={{
                              marginTop: "10px",
                           }}
                           container
                        >
                           <Typography
                              align="right"
                              variant="h1"
                              sx={{
                                 display: "flex",
                                 justifyContent: "space-between",
                                 alignItems: "center",
                                 width: "100%",
                                 padding: "10px",
                              }}
                           >
                              {i18n.t("teacher.schedulesGrid")}
                           </Typography>
                        </Grid>

                        <Grid item xs={12}>
                           <div style={{ marginLeft: "10px", width: "100%" }}>
                              <CardSchedules
                                 schedule={scheduleMap}
                                 groups={groups}
                                 classrooms={classrooms}
                                 disciplines={listDiscipline}
                                 setSchedule={setSchedule}
                                 selectedPeriod={selectedPeriod}
                                 selectedStrand={selectedStrand}
                                 viewOnly={true}
                              />
                           </div>
                        </Grid>
                     </Grid>
                  </Form>
               </Formik>
            </Grid>
         </Grid>
      </div>
   );
};

export default ScheduleTable;
