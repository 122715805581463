import { Alert, Snackbar } from "@mui/material";
import { Outlet } from "react-router-dom";
import "./Layout.css";
import SideBar from "../../components/sideBar/SideBar";
import { useKeycloak } from "@react-keycloak/web";
import { useMemo, useState } from "react";
import Loading from "../../components/loading/loading";
import axios from "axios";

export default function Layout() {
  const [open, setOpen] = useState(false);
  const [toast, setToast] = useState({ message: "", severity: "success" });

  const handleClic = (m, s) => {
    setToast({ message: m, severity: s });
    console.log("Toast:", toast);
    setTimeout(() => setOpen(true), 200);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  axios.interceptors.response.use(
    function (response) {
      if (!Array.isArray(response.data) && ![200, 201].includes(response.status)) {
        const successMessage = response.data.message || "Tarefa feita com sucesso";
        handleClic(successMessage, "success");
      }
      return response;
    },
    function (error) {
      if (error.status === 404) {
        const errorMessage =
          error.response.data.message.message || error.response.data || "Erro";
        handleClic(errorMessage, "error");
        return Promise.reject(error);
      }
    }
  );

  const [authenticated, setAuthenticated] = useState(false);
  const { keycloak } = useKeycloak();

  useMemo(() => {
    try {
      if (keycloak.authenticated) setAuthenticated(true);
      else keycloak?.login({ redirectUri: window.location.origin });
    } catch (error) {
   
    }
  }, [keycloak.authenticated, keycloak.login]);

  if (authenticated) {
    return (
      <div
        className={"midle-section"}
        style={window?.innerWidth < 1200 ? {} : { display: "flex" }}
      >
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={handleClose}
          sx={{ top: "20px" }}
        >
          <Alert
            onClose={handleClose}
            severity={toast?.severity}
            sx={{ width: "100%" }}
          >
            {JSON.stringify(toast?.message)}
          </Alert>
        </Snackbar>
        <SideBar />
        <div
          className={"content"}
          style={
            window?.innerWidth < 1200
              ? {}
              : { padding: "70px 130px", marginLeft: "150px" }
          }
        >
          <Outlet />
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
}
