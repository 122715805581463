import React, { useState, useEffect } from 'react';
import '../enroll-analysis.css';
import { Button,Step, StepButton, Stepper, styled } from '@mui/material';
import SquareIcon from '@mui/icons-material/Square';
import { Form, Formik } from 'formik';
import { useKeycloak } from '@react-keycloak/web';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import PersonalInfo from '../Form-components/PersonalInfo';
import SecondarySchoolInfo from '../Form-components/SecondarySchoolInfo';
import AdditionalInfo from '../Form-components/AdditionalInfo';
import * as Yup from 'yup';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loading from '../../../components/loading/loading';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const steps = ['Dados Biográficos', 'Dados do Ensino Médio', 'Dados Adicionais'];


const EnrollUpdate = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { keycloak } = useKeycloak();
  const [isFinanceiro, setIsFinanceiro] = useState(keycloak?.tokenParsed?.resource_access?.Financeiro);
  const [incomingData,setIncomingData]= useState({})
  const [formData, setFormData] = useState({})
  const [acceptUseTerms, setAcceptUseTerms] = useState(false);
  const [files,setFiles] = useState({})
  const {id} = useParams()
  const [open, openAlert] = React.useState(false); 
  const [toast,setToast]= React.useState({message:"",severity:"success"})
  
  const [uploadedImage, setUploadedImage] = useState(null);
  const handleClick = (m,s) => {
    setToast({message:m,severity:s})
    setTimeout(()=>
    openAlert(true),200);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    openAlert(false);
  };
  const NextButton = styled(Button)({
    backgroundColor: "#FFC107",
    color: "white",
    padding: "5px 35px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#FFCC39",
    },
  });
  
  const PrevButton = styled(Button)({
    backgroundColor: "white",
    color: "#FFC107",
    padding: "5px 35px",
    borderColor: "#FFC107",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "white",
      borderColor: "#FFCC39",
    },
  });
  
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/enroll/${id}`, {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        });
        let data = response.data;
        data.student.id = undefined
        data.student.course = data.student.course.id
        data.student.specialNeeds = data.student.specialNeeds == "0"? false:true
        data.student.phone = parseInt(data.student.phone)
        data.student.workingInStateAgency = data.student.workingInStateAgency == "0"? false:true
        data.student.highSchoolFinalGrade = parseInt(data.student.highSchoolFinalGrade)
        // Agora, você pode acessar os dados, como nome e ano da matrícula, a partir de 'data'
        setIncomingData(data);
        // Faça o que for necessário com esses dados
        // Atualize o estado ou exiba as informações na página
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, [id,keycloak.token]);

  useEffect(()=>{
    if(keycloak?.tokenParsed)
    if(keycloak?.tokenParsed?.resource_access?.['sga-api']?.roles.includes("Financeiro"))     
    setIsFinanceiro(true)

  },[keycloak.token])
  const handleStep = (step) => () => {
    if (!isFinanceiro && step !== 3) {
      return; // Impede que usuários não financeiros naveguem para outras etapas além da etapa de pagamento
    }
    setActiveStep(step);
  };
  const nextPage = (err = {}) => {
    
    if (activeStep === steps.length - 1) {
      console.log('Parabéns! Você chegou à última etapa.');
       
    } else {
        setActiveStep(activeStep + 1);

     
    }
  };

  const prevPage = () => {
    setActiveStep(activeStep - 1);
  };
  const uploadFiles = async (files,id,type,data) => {
    try {
      const formData = new FormData();
      formData.append('entityID', id);
      formData.append('type',type)
      formData.append('ProfilePic', files["ProfilePic"]); // O nome 'file' deve corresponder ao esperado pela API
      formData.append('DocIdentification',files["DocIdentification"])
      formData.append('HighSchoolCertificate',files["HighSchoolCertificate"])
      formData.append('MedicalCertificate',files["MedicalCertificate"])
      formData.append('DescentFromFormerCombatant',files["DescentFromFormerCombatant"])
      formData.append('ProOfOfDeficiencyCarrier',files["ProOfOfDeficiencyCarrier"])
      formData.append('AdditionalAttachment',files["AdditionalAttachment"])


      
    
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/enrollAttachment`, formData, {
          headers: {
            Authorization: "Bearer " + keycloak.token, // token de autenticação desgraçado
          },

          'Content-Type': 'multipart/form-data', // Importante para enviar arquivos
        
      });

      // A resposta da API pode conter informações úteis, dependendo do seu caso
      console.log('Resposta da Requisição de Upload:', response);
      
      handleClick("Ficheiros Upploaded Com Successo","success")

      // Você pode atualizar o estado com o ID da imagem ou outras informações relevantes da resposta
      //setImageId(response.data.imageId);
      
      

    } catch (error) {
      console.error('Erro ao fazer upload do arquivo:', error);
      
      handleClick("Erro ao fazer upload do arquivo:","error")
    } finally {
      const urlParams = new URLSearchParams(data.student);

// Convert the URLSearchParams object to a string
      const paramString = urlParams.toString();
      window.location.href = "/enrolls/confirmation?" + paramString
      
    }
  };
  const update = async (values) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/enroll/${id}`,
        {
            student: values
        },

        {
          headers: {
            Authorization: "Bearer " + keycloak.token, // token de autenticação desgraçado
          },
        }
      ).then((data)=> {
        console.log("MY RESPOSNSE",data)
        handleClick("Erroll Com Successo","success")
        uploadFiles(files,data.data?.id,values?.documentType,data.data)
      })
      if(response){
      console.log("Resposta da Requisição:", response);
     
      }
    } catch (error) {
      console.error(error);
      let message = error?.response?.data?.message?.message
      handleClick( message ,"error")
    }
  };
  
  const stepStyles = {
    "& .Mui-active": {
      color: "#FFC107",
    },
  };

  const formschema = Yup.object().shape({
    year: Yup.string(),
    phone: Yup.string().min(9,"O telefone deve ter minimo 9 numeros").max(9,"O telefone deve ter maximo 9 numeros"),
    email: Yup.string().email('Invalid email'),
    // Add validation for other fields here
    documentType: Yup.string(),
    documentNumber: Yup.string(),
    name: Yup.string(),
    birthdate: Yup.date(),
    motherName: Yup.string(),
    fatherName: Yup.string(),
    gender: Yup.string(),
    maritalStatus: Yup.string(),
    nationality: Yup.string(),
    province: Yup.string(),
    county: Yup.string(),
    householdSize: Yup.number().lessThan(50,"Não pode ser maior que 50"),
    schoolOrigen: Yup.string(),
    natureoftheschoolofprovenance: Yup.string(),
    nameOfTheHighSchoolCourse: Yup.string(),
    highSchoolFinalGrade: Yup.number("E permetido apenas numeros").min(4).max(4,'permitido duas casas decimais').moreThan(0,"Nota tem que ser entre 0 a 20").lessThan(20.01,"Nota tem que ser entre 0 a 20"),
    studyFinancing: Yup.string(),
    studentRegime: Yup.string(),
    yearsOfEntryTheCourse: Yup.number().lessThan(2024,"Tem que ser menor que 2024").moreThan(1990,"Tem que ser maior que 1990"),
    studyInclusively: Yup.boolean(),
    entryType: Yup.string(),
    schoolIssuingProfessionalDegree: Yup.string(),
    stateAgency: Yup.string(),
    profession: Yup.boolean(),
    functionPerformed: Yup.string(),
    workingInStateAgency: Yup.boolean(),
    professionalDegreeConferredBySchool: Yup.boolean(),
    deaf: Yup.boolean(),
    mediumTechnician: Yup.boolean(),
    seniorTechnician: Yup.boolean(),
    police: Yup.boolean(),
    military: Yup.boolean(),
    blind: Yup.boolean(),
    otherDeficiency: Yup.boolean(),
    motorDeficiency: Yup.boolean(),
    teacher: Yup.boolean(),
    administrationEmployee: Yup.boolean(),
  });
  if(!incomingData.student ) return <>Loading</>
  return (
    <div style={{padding:100}}>
       <Snackbar open={open}  anchorOrigin={{vertical: "top", horizontal: "center" }} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={toast?.severity} sx={{ width: '100%' }}>
          {toast?.message}
        </Alert>
      </Snackbar>
      <h2>Atualizar Matrícula</h2>

      <div className='mother-flex'>
        <div className='left-side'>
          <Stepper orientation='vertical' nonLinear activeStep={activeStep} sx={stepStyles}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton
                  sx={{ color: '#C2D6E5' }}
                  icon={<SquareIcon />}
                  onClick={handleStep(index)}
                  disabled={!isFinanceiro && index !== 3} // Desabilita etapas não relacionadas ao pagamento para usuários não financeiros
                >
                  <p className='step-title'>{label}</p>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>

        <div className='divider'></div>

        <div className='right-side'>
          <Formik
          
            validationSchema={formschema}
          onSubmit={(values, { setSubmitting })=> {
            update(values).finally(() => setSubmitting(false));
          }}
          initialValues={{ ...incomingData.student }}
          >{({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form>
              {activeStep === 0 && (
                <>
                  <PersonalInfo setUploadedImage={setUploadedImage} uploadedImage={uploadedImage} id={incomingData.id} values={values} edit  setFiles={setFiles} files={files} />
                </>
              )}
              {activeStep === 1 && (
                <>
                  <SecondarySchoolInfo id={incomingData.id} edit  setFiles={setFiles} files={files} />
                </>
              )}
              {activeStep === 2 && (
                <>
                <div>
                  <AdditionalInfo
                  edit
                  setFiles={setFiles} files={files}
                  setFormData={setFormData}
                  id={incomingData.id}
                  acceptUserTermsProps={{acceptUseTerms, setAcceptUseTerms}}
                    values={values} />
                    </div>

                
                </>
              )}
              <div className="button-group" style={{ paddingTop: "100px" }}>
              <PrevButton
                variant="outlined"
                onClick={prevPage}
                disabled={activeStep === 0}
              >
                Anterior
              </PrevButton>
              {activeStep!=2 && <NextButton
                type="button"
                variant="contained"
                onClick={()=>nextPage()}
              >
                Próximo
              </NextButton>}
              {activeStep==2 && <NextButton
                type="submit"
                disabled={!acceptUseTerms || isSubmitting}
                variant="contained"
                onClick={()=>handleSubmit()}
              >
                Submeter
              </NextButton>}
            </div>
            {isSubmitting && <Loading/>}
            </Form>
          )}
           
          </Formik>
          {activeStep !== steps.length - 1 && (
            <div className="button-container-right">
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EnrollUpdate;
