import React, { useEffect, useState } from "react";
import { Button, Grid, Typography, Select, MenuItem } from "@mui/material";
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";

export default function EvaluationPage() {

    const navigate = useNavigate();
    const [graduations, setGraduations] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;
    const { keycloak } = useKeycloak();
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState('');
    const [selectedGraduation, setSelectedGraduation] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [courseSubjects, setCourseSubjects] = useState([]);
    const [pageTitle, setPageTitle] = useState("Lançar Notas");

    const handleLancarNotasClick = (courseId, subjectId, subjectName) => {
        setPageTitle("Lançar Notas");
        navigate(`/evaluations/create/${courseId}/${subjectId}/${encodeURIComponent(subjectName)}`);
        
    };

    const handleViewNotesClick = () => {
        setPageTitle("Visualizar Notas");
        // Add logic here to handle the action of viewing notes
    };

    const handleLancarNotasButtonClick = () => {
        setPageTitle("Lançar Notas");
    };

    const handleCourseSelect = async (courseId) => {
        try {
            let apiEndpoint = `${apiUrl}/coordinator-course/${courseId}/subjects`;
            if (selectedYear) {
                apiEndpoint += `?year=${selectedYear}`;
            }

            const response = await axios.get(apiEndpoint, {
                headers: {
                    Authorization: "Bearer " + keycloak.token,
                },
            });
            setCourseSubjects(response.data.data);
        } catch (error) {
            console.error("Erro ao obter os assuntos do curso:", error);
        }
    };

    useEffect(() => {
        if (selectedCourse) {
            handleCourseSelect(selectedCourse);
        }
    }, [selectedCourse, selectedYear]); 

    useEffect(() => {
        async function fetchData() {
            try {
                const [graduationsResponse, coursesResponse] = await Promise.all([
                    axios.get(`${apiUrl}/graduations`, {
                        headers: {
                            Authorization: "Bearer " + keycloak.token,
                        },
                    }),
                    axios.get(`${apiUrl}/courses/from-school-year`, {
                        headers: {
                            Authorization: "Bearer " + keycloak.token,
                        },
                    })
                ]);
                setGraduations(graduationsResponse.data?.data ? graduationsResponse.data.data : graduationsResponse.data);
                setCourses(coursesResponse.data?.data ? coursesResponse.data.data : coursesResponse.data);
            } catch (error) {
                console.error("Erro ao buscar dados:", error);
            }
        }

        fetchData();
    }, [apiUrl, keycloak.token]);

    return (
        <Grid sx={{ backgroundColor: "white", padding: "80px", paddingTop: "30px" }}>
            <Grid container display={"flex"} justifyContent="space-between" alignItems="center" sx={{ marginBottom: '40px' }}>
                <Grid item>
                    <Button
                        variant="outlined"
                        sx={{
                            height: "25px",
                            color: "#003B76",
                            backgroundColor: "#EFEFEF",
                            borderColor: "#EFEFEF",
                            borderRadius: '0',
                            fontSize: "12px"
                        }}
                        onClick={handleLancarNotasButtonClick}
                    >
                        Lançar Notas
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{
                            height: "25px",
                            color: "#FFC107",
                            backgroundColor: "#EFEFEF",
                            borderColor: "#EFEFEF",
                            borderRadius: '0',
                            fontSize: "12px",
                            marginLeft: '10px',
                        }}
                        onClick={handleViewNotesClick}
                    >
                        Visualizar notas
                    </Button>
                </Grid>
            </Grid>
            <Grid container display={"flex"} justifyContent="space-between" alignItems="center" sx={{ marginBottom: '10px' }}>
                <Grid item>
                    <Typography variant="h4" sx={{ color: '#212529', fontWeight: 'bold', fontSize: "20px" }}>
                        {pageTitle}
                    </Typography>
                </Grid>
                <Grid item alignItems="center">
                    <Select
                        label="graduação"
                        value={selectedGraduation}
                        onChange={(e) => setSelectedGraduation(e.target.value)}
                        placeholder="Selecione uma Graduação"
                        sx={{ width: '160px', height: '35px', marginRight: '10px' }}
                    >
                        <MenuItem value="" disabled>
                            Selecione uma Graduação
                        </MenuItem>
                        {graduations?.map(graduation => (
                            <MenuItem key={graduation.id} value={graduation.name}>
                                {graduation.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <Select
                        placeholder="Curso"
                        label="curso"
                        sx={{ width: '160px', height: '35px', marginRight: '10px' }}
                        value={selectedCourse}
                        onChange={(e) => {
                            setSelectedCourse(e.target.value);
                            handleCourseSelect(e.target.value);
                        }}
                    >
                        <MenuItem value="" disabled>
                            Selecione um curso
                        </MenuItem>
                        {courses
                            .filter(course => course.graduation === selectedGraduation)
                            .map((course) => (
                                <MenuItem key={course.id} value={course.id}>
                                    {course.name}
                                </MenuItem>
                            ))}
                    </Select>
                    <Select
                        placeholder="Ano"
                        label="Ano"
                        sx={{ width: '160px', height: '35px' }}
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                    >
                        <MenuItem value="" disabled>
                            Selecione um ano
                        </MenuItem>
                        {selectedCourse && courses.map(course => {
                            if (course.id === selectedCourse) {
                                return Array.from({ length: course.totalNumberOfYears }, (_, index) => index + 1).map((year) => (
                                    <MenuItem key={year} value={year}>
                                        {year}
                                    </MenuItem>
                                ));
                            }
                            return null;
                        })}
                    </Select>
                    {(selectedGraduation === '' || selectedCourse === '') && (
                        <Typography variant="body1" sx={{ color:"#FFC107", fontWeight: 'bold', fontSize: "14px", textAlign: 'center', marginTop: '10px' }}>
                            Por favor, selecione uma graduação e um curso.
                        </Typography>
                    )}
                </Grid>
            </Grid>
            {courseSubjects.map((subject) => (
                <Grid
                    container
                    alignItems="center"
                    key={subject.id}
                    sx={{
                        border: '1px solid #fff',
                        borderTop: '1px solid #fff',
                        boxShadow: '0px 8px 16px 0px #ABBED133',
                        marginBottom: '5px',
                        padding: '20px',
                        maxWidth: '100%',
                        marginLeft: "-20px",
                        position: 'relative',
                    }}
                >
                    <Typography variant="body1" sx={{ color: '#003B76', fontWeight: 'bold', fontSize: "14px" }}>{subject.name}</Typography>
                    <Button
                        variant="outlined"
                        sx={{
                            height: "30px",
                            width: pageTitle === "Visualizar Notas" ? '160px' : '120px',
                            color: "#FFC107",
                            backgroundColor: "#EFEFEF",
                            borderColor: "#EFEFEF",
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            borderRadius: '0',
                            fontSize: "12px"
                        }}
                        onClick={() => {
                            if (pageTitle === "Lançar Notas") {
                                handleLancarNotasClick(selectedCourse, subject.id, subject.name);
                            } else if (pageTitle === "Visualizar Notas") {
                                navigate(`/finalGradeList/${selectedCourse}/${subject.id}`);
                            }
                        }}
                    >
                        {pageTitle === "Lançar Notas" ? "Lançar notas" : "Visualizar notas"}
                        <EastIcon />
                    </Button>
                </Grid>
            ))}
        </Grid>
    );
}
