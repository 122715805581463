import { Grid, Typography } from "@mui/material";
import axios from "axios";
import { Form, Formik } from "formik";
import * as React from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import AlertDialog from "../../components/dialog-option/AlertDialog";
import Select from "../../components/form-components/select";
import Textfield from "../../components/form-components/text-field";
import { i18n } from "../../translate/i18n";
import HandleUrl from "../../utils/HandleUrl";
import { MainButton } from "../../components/main-button/MainButton";
import { useEffect } from "react";
import { useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import FallBackLoader from "../../components/FallBackComponents/FallBackLoader";
import { getItem as get } from "../../utils/functions/tableCRUDFunctions";
import {
  createItem as create,
  updateItem as update,
} from "../../utils/functions/formCRUDFunctions";

export default function LeCreatePage() {
  const { keycloak } = useKeycloak();
  let page = 1;
  const apiUrl = process.env.REACT_APP_API_URL;
  const isDisabled = HandleUrl();
  const [roomTypes, setRoomTypes] = useState([]);
  const { id } = useParams();
  const isUpdate = !!id;
  const [status, setStatus] = useState({
    isLoading: true,
  });

  const [textBox, setTextBox] = useState([
    i18n.t(id ? "messages.updateTitle" : "messages.createTitle"),
    "",
  ]);
  const [INITIAL_FORM_STATE, setINITIAL_FORM_STATE] = useState({
    roomtype: {
      id: "",
    },
  });

  const getList = async (type, set) => {
    try {
      const response = await axios.get(`${apiUrl}/${type}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token, // token de autenticação
        },
      });
      console.log("Resposta da Requisição:", response);
      set(response.data?.data ? response.data.data : response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getList("room-type", setRoomTypes);
  }, []);

  const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string().required(i18n.t("messages.required")),
    capacity: Yup.number().required(i18n.t("messages.required")),
    roomtype: Yup.object().shape({
      id: Yup.string().required(i18n.t("messages.required")),
    }),
  });

  const getItem = get(
    `/classrooms/${id}`,
    keycloak,
    setINITIAL_FORM_STATE,
    undefined,
    page
  );
  useEffect(() => {
    if (id) {
      getItem();
    }
  }, [id]);

  const formatFormData = (values) => ({
    name: values.name,
    capacity: values.capacity,
    roomtype: values.roomtype.id,
  });

  const createItem = create(
    formatFormData,
    "classrooms",
    setTextBox,
    setStatus
  );
  const updateItem = update(
    formatFormData,
    "/classrooms",
    setTextBox,
    setStatus,
    keycloak
  );

  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          padding: "80px",
          paddingTop: "55px",
        }}
      >
        <Formik
          initialValues={{
            ...INITIAL_FORM_STATE,
          }}
          validationSchema={FORM_VALIDATION}
          onSubmit={(values) => {
            if (id) {
              updateItem(values);
            } else {
              createItem(values);
            }
          }}
          enableReinitialize
        >
          {({ values, isValid }) => (
            <Form>
              <Typography variant="h1">
                {isUpdate ? values.name : i18n.t("others.newClassroom")}
              </Typography>
              <br />
              <Grid container spacing={4}>
                <Grid item xs={5}>
                  <Textfield
                    name="name"
                    label={i18n.t("others.name")}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Select
                    name="roomtype.id"
                    options={roomTypes}
                    label={i18n.t("others.TypeofRoom")}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Textfield
                    name="capacity"
                    type="number"
                    label={i18n.t("others.cap")}
                    disabled={isDisabled}
                  />
                </Grid>
              </Grid>
              <br />
              <br />
              <Grid container spacing={8}>
                <Grid item xs={2}>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      paddingLeft: "590px",
                      paddingTop: "130px",
                    }}
                  >
                    <span style={{ marginLeft: "10px" }}>
                      <MainButton
                        url="/lessons"
                        name={i18n.t("button.cancel")}
                      />
                    </span>
                    <span>
                      <AlertDialog
                        show={true}
                        button={
                          isUpdate
                            ? i18n.t("button.update")
                            : i18n.t("button.register")
                        }
                        head={textBox[0]}
                        body={
                          textBox[1] || (
                            <FallBackLoader isLoading={status.isLoading} />
                          )
                        }
                        goBack={i18n.t("classroom.title")}
                        urlGoBack="/lessons"
                        stay={i18n.t("others.Classroom")}
                        urlStay={
                          isUpdate ? `/lessons/${id}/edit` : "/lessons/create"
                        }
                        type="submit"
                        isValid={isValid}
                        disabled={isDisabled}
                      />
                    </span>
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
