import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Form, Formik } from "formik";
import * as React from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import Select from "../../components/form-components/select";
import Textfield from "../../components/form-components/text-field";
import { i18n } from "../../translate/i18n";
import HandleUrl from "../../utils/HandleUrl";
import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import { yellow } from "@mui/material/colors";
import {
  getErrorMessage,
  handleFailure,
} from "../../utils/functions/utilFunctions";
import DeleteIcon from "@mui/icons-material/Delete";

export default function EditExamsPage() {

  let isDisabled = true;
  const isViewPage=HandleUrl();
  const [course, setCourse] = useState([]);
  const [genders, setGenders] = useState([]);
  let oneGender="";
  const [gender, setGender] = useState();
  const [schoolYear, setSchoolYear] = useState([]);
  const [graduations, setGraduations] = useState([]);
  const [accessExam, setAcessExam] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [resultList, setResultList] = useState([]);
  const [studentsEvaluated, setStudentsEvaluated] = useState([]);
  const { keycloak } = useKeycloak();
  const [editRow, setEditRow] = useState(null);
  const [processNumber, setProcessNumber] = useState();
  const [studentName, setStudentName] = useState();
  const [results, setResults] = useState();
  let  setOpen =false;
  const [status, setStatus] = useState({
    canClose: false,
    isLoading: true,
  });

  let  setTextBox =[i18n.t("messages.createTitle"), ""];

  let  setToast ={ message: "", severity: "success" };
  const handleClick = (m, s) => {
    setToast({ message: m, severity: s });
    setTimeout(() => setOpen(true), 200);
  };
  const handleEditChange = (index, field, value) => {
    const updatedStudentsEvaluated = [...studentsEvaluated];
    if (field === "processNumber") {
      // Certifique-se de que "processNumber" seja um número
      updatedStudentsEvaluated[index][field] = parseInt(value);
    } else {
      updatedStudentsEvaluated[index][field] = value;
    }
    setStudentsEvaluated(updatedStudentsEvaluated);
    console.log(updatedStudentsEvaluated[index]);
  };
  const fetchUpdatedData = () => {
    axios
      .get(`accessExams/${id.id}`)
      .then((response) => {
        setStudentsEvaluated(response.data?.studentsEvaluated);
        console.log(
          "Estudantes avaliados atualizados:",
          response.data?.studentsEvaluated
        );
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleSaveEdit = (index) => {
    const studentToUpdate = studentsEvaluated[index];

    // Verifique se processNumber é um número
    const processNumber = parseInt(studentToUpdate.processNumber);

    if (isNaN(processNumber)) {
      console.error("processNumber não é um número válido.");
      // Lidar com o erro ou apresentar uma mensagem de erro ao usuário, se necessário.
      return;
    }

    // Remover a propriedade "id" do objeto
    const { id, ...studentData } = studentToUpdate;

    // Extrair apenas o ID do gênero
    const genderId = studentToUpdate.gender.id;

    // Montar o objeto da solicitação
    const updatedStudent = {
      ...studentData,
      gender: genderId || studentToUpdate.gender, // Enviar apenas o ID do gênero
      processNumber, // Enviar o processNumber como um número
    };
    // ... código de atualização ...
    console.log("Valor de gender após a atualização: ", updatedStudent.gender);

    axios
      .put(`student-evaluated/${studentToUpdate.id}`, updatedStudent)
      .then((response) => {
        console.log("Estudante atualizado com sucesso:", response);

        fetchUpdatedData();
        console.log("Student updated successfully:", response);

        setEditRow(null);
      })
      .catch((error) => {
        console.error("Erro ao atualizar o estudante:", error);
      });
  };

  const handleDeleteRow = (index) => {
    try {
      const studentToDelete = studentsEvaluated[index];

      console.log("Estudante a ser excluído:", studentToDelete);

      axios
        .delete(`student-evaluated/${studentToDelete.id}`, {
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
          },
        })
        .then((response) => {
          console.log("Estudante excluído com sucesso:", response);

          fetchUpdatedData();
          console.log("Student deleted successfully:", response);
        })
        .catch((error) => {
          console.error("Erro ao excluir o estudante:", error);
        });
    } catch (error) {
      console.error("Erro ao excluir o estudante:", error);
    }
  };

  let id = useParams();

  const INITIAL_FORM_STATE = {
    schoolYear: accessExam?.schoolYear,
    graduation: accessExam?.graduation?.id,
    NumberOfVacancies: accessExam?.NumberOfVacancies,
    numberOfCandidates: studentsEvaluated?.length,
    coordinator: accessExam?.coordinator,
    course: accessExam?.course?.id,
  };

  console.log("INITIAL_FORM_STATE", INITIAL_FORM_STATE);

  const SECOND_FORM_STATE = {
    processNumber: processNumber,
    studentName: studentName,
    gender: gender,
    grade: results,
  };

  const FORM_VALIDATION = Yup.object().shape({
    graduation: Yup.string().required(i18n.t("messages.required")),
    schoolYear: Yup.string().required(i18n.t("messages.required")),
    coordinator: Yup.string().required(i18n.t("messages.required")),
    NumberOfVacancies: Yup.string().required(i18n.t("messages.required")),
    numberOfCandidates: Yup.string().required(i18n.t("messages.required")),
    course: Yup.string().required(i18n.t("messages.required")),
  });

  const SECOND_FORM_VALIDATION = Yup.object().shape({
    processNumber: Yup.string().required(i18n.t("messages.required")),
    studentName: Yup.string().required(i18n.t("messages.required")),
    gender: Yup.string().required(i18n.t("messages.required")),
    grade: Yup.number()
      .typeError("Resultado tem que ser um número")
      .min(0)
      .max(20, "Resultado tem que ser entre 0 e 20")
      .typeError("")
      .required(i18n.t("messages.required")),
  });

  useEffect(() => {
    const requests = [
      axios.get("/curricular-Plan"),
      axios.get("/gender"),
      axios.get(`/accessExams/${id?.id}`),
      axios.get("/departments"),
      axios.get("/graduations"),
      axios.get("/departments"),
    ];
    axios.all(requests).then((responses) => {
      setAcessExam(responses[2].data);
      setCourse(
        responses[0].data?.data.map((c) => ({
          name: c.course?.name,
          id: c.course?.id,
        }))
      );
      setGenders(responses[1].data);
      setSchoolYear(responses[2].data?.schoolYear);
      setStudentsEvaluated(responses[2].data?.studentsEvaluated);
      setDepartments(responses[5]?.data?.data);
      setGraduations(responses[4].data?.data);
      console.log(responses[2].data);
      
    });
  }, []);

  function createExamedStudents(values, setValues, resetForm) {
    try {
      setStatus({ ...status, isLoading: true });
  
      const formData = {
        accessExamId: id.id,
        processNumber: parseInt(values.processNumber),
        studentName: values.studentName,
        gender: values.gender,
        grade: parseFloat(values.grade),
      };
  
      axios
        .post("student-evaluated", formData, {
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
          },
        })
        .then(function (response) {
          if (response && response?.status <= 201) {
            setGender("");
            setProcessNumber("");
            setStudentName("");
            setResults("");
  
            // Busca os dados atualizados do servidor
            fetchUpdatedData();
  
            // Exibe mensagem de sucesso
            handleClick("Candidato adicionado com sucesso!", "success");
  
            // Reseta os campos do formulário
            resetForm();
  
            setStatus({ ...status, isLoading: false, canClose: true });
          } else {
            setTextBox([i18n.t("messages.wrong"), handleFailure(response)]);
            setStatus({ ...status, isLoading: false, canClose: true });
          }
        })
        .catch(function (error) {
          console.error(error);
          let message = error?.response?.data?.message?.message;
          if (
            message === "Student with this process number is already evaluated"
          ) {
            message =
              "Candidato com este número de teste de acesso já se encontra matriculado";
          }
          setTextBox([
            i18n.t("messages.wrong"),
            message || getErrorMessage(error),
          ]);
          setStatus({ ...status, isLoading: false, canClose: true });
          handleClick(message, "error");
        });
    } catch (error) {
      console.error(error);
      setTextBox([i18n.t("messages.wrong"), getErrorMessage(error)]);
      setStatus({ ...status, isLoading: false, canClose: true });
    }
  }
  

  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          padding: "80px",
          paddingTop: "55px",
        }}
      >
        {accessExam ? (
          <Formik
            initialValues={{
              ...INITIAL_FORM_STATE,
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={(values) => {
              setResultList([...resultList, values.numberOfCandidates]);

              values.numberOfCandidates = "";
            }}
            enableReinitialize
          >
            <Form>
              <Typography variant="h3" style={{ color: "#003B76" }}>
                {i18n.t("ExamsList.editExms")}
              </Typography>
              <br />

              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Select
                    name="course"
                    value={accessExam?.course?.id}
                    options={course}
                    label={i18n.t("others.course")}
                    disabled={isDisabled}
                  ></Select>
                </Grid>

                <Grid item xs={5}>
                  <Textfield
                    name="schoolYear"
                    options={schoolYear}
                    label={i18n.t("others.schoolYear")}
                    disabled={isDisabled}
                  />
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Select
                    name="graduation"
                    value={accessExam?.graduation?.id}
                    options={graduations}
                    label={i18n.t("others.graduation")}
                    disabled={isDisabled}
                  ></Select>
                </Grid>
                <Grid item xs={5}>
                  <Textfield
                    name="coordinator"
                    label={i18n.t("others.coordinator")}
                    disabled={isDisabled}
                  ></Textfield>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={4.7}>
                      <Textfield
                        name="numberOfVacancies"
                        value={accessExam?.numberOfVacancies}
                        label={i18n.t("others.NumberOfVacancies")}
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item xs={4.7}>
                      <Textfield
                        name="numberOfCandidates"
                        value={studentsEvaluated?.length}
                        label={i18n.t("others.numberOfCandidates")}
                        disabled={isDisabled}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        ) : null}
      </div>
      <br />
      <div
        style={{
          backgroundColor: "white",
          paddingTop: "8px",
          padding: "40px",
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(233, 236, 245, 1)",
            height: "100%",
            paddingTop: "8px",
            left: "282px",
            border: "1px",
          }}
        >
          <Formik
            initialValues={{
              ...SECOND_FORM_STATE,
            }}
            validationSchema={SECOND_FORM_VALIDATION}
            onSubmit={(values, { setValues, resetForm }) => {
              createExamedStudents(values, setValues, resetForm);
            }}
            enableReinitialize
          >
            <Form>
              <Grid container direction="row">
                <Grid item xs={5} style={{ marginLeft: "45px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <Textfield
                        name="processNumber"
                        label="Número de Prova de Acesso"
                        disabled={isViewPage}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        sx={{
                          "& .MuiInputBase-root": {
                            border: "none", // remove border
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Textfield
                        name="studentName"
                        label="Nome do Aluno"
                        disabled={isViewPage}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        sx={{
                          "& .MuiInputBase-root": {
                            border: "none", // remove border
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={5} style={{ marginLeft: "25px" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={5}>
                      <Select
                        name="gender"
                        options={genders}
                        defaultValue={oneGender}
                        label={i18n.t("others.Sex")}
                        disabled={isViewPage}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        sx={{
                          "& .MuiInputBase-root": {
                            border: "none", // remove border
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={5} style={{ alignItems: "flex-end" }}>
                      <Textfield
                        name="grade"
                        label={i18n.t("others.Results")}
                        disabled={isViewPage}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        sx={{
                          "& .MuiInputBase-root": {
                            border: "none", // remove border
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  xs={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    style={{
                      width: "100%",
                      height: "32px",
                      padding: "5px",
                      backgroundColor: "#FFC107",
                      color: "white",
                      border: "none",
                      borderRadius: "3px",
                      marginTop: "15px",
                      cursor: isViewPage ? "default" : "pointer",
                    }}
                    type="submit"
                    disabled={isViewPage}
                  >
                    Adicionar
                  </button>
                </Grid>
              </Grid>
              <br />
            </Form>
          </Formik>
          <div>
            <Grid container component={Paper} sx={{ boxShadow: "none" }}>
              <Grid item xs={12} sx={{ boxShadow: "none" }}>
                <TableContainer
                  sx={{
                    marginBottom: "50px",
                    overflow: "hidden",
                    border: "1px solid #E9ECF5",
                  }}
                >
                  {studentsEvaluated
                    .sort((a, b) => a.processNumber - b.processNumber)
                    .map((data, index) => (
                      <Table
                        key={data.id}
                        sx={{ minWidth: 650, border: "1px solid #E9ECF5" }}
                        aria-label="a dense table"
                      >
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            align="left"
                            style={{ width: "260px", paddingLeft: "55px" }}
                          >
                            {editRow === index ? (
                              <input
                                type="number"
                                value={data.processNumber}
                                onChange={(e) =>
                                  handleEditChange(
                                    index,
                                    "processNumber",
                                    e.target.value
                                  )
                                }
                                sty
                              />
                            ) : (
                              data.processNumber
                            )}
                          </TableCell>
                          <TableCell align="left" style={{ width: "220px" }}>
                            {editRow === index ? (
                              <input
                                type="text"
                                value={data.studentName}
                                onChange={(e) =>
                                  handleEditChange(
                                    index,
                                    "studentName",
                                    e.target.value
                                  )
                                }
                              />
                            ) : (
                              data.studentName
                            )}
                          </TableCell>
                          <TableCell align="left" style={{ width: "186px" }}>
                            {editRow === index ? (
                              <select
                                style={{ width: "200px", height: "20px" }}
                                name="gender"
                                options={genders}
                                defaultValue={data.gender?.id}
                                label={i18n.t("others.Sex")}
                                value={data.gender?.id}
                                onChange={(e) =>
                                  handleEditChange(
                                    index,
                                    "gender",
                                    e.target.value
                                  )
                                }
                              >
                                {genders.map((gender) => (
                                  <option key={gender.id} value={gender.id}>
                                    {gender?.name}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              // Se a linha de edição não for a linha atual, o nome do gênero é renderizado.
                              data.gender?.name
                            )}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {editRow === index && !isViewPage ? (
                              <input
                                type="number"
                                value={data.grade}
                                onChange={(e) =>
                                  handleEditChange(
                                    index,
                                    "grade",
                                    parseFloat(e.target.value)
                                  )
                                }
                              />
                            ) : (
                              data.grade
                            )}
                            {!isViewPage && editRow === index ? (
                              <IconButton onClick={() => handleSaveEdit(index)}>
                                <SaveIcon
                                  sx={{ color: yellow[700] }}
                                  fontSize="small"
                                />
                              </IconButton>
                            ) : (
                              <div style={{ alignSelf: "end" }}>
                                <IconButton onClick={() => setEditRow(index)}>
                                  <EditIcon
                                    sx={{ color: yellow[700] }}
                                    fontSize="small"
                                  />
                                </IconButton>
                                <IconButton
                                  size="small"
                                  color="secondary"
                                  onClick={() => handleDeleteRow(index)}
                                >
                                  <DeleteIcon
                                    sx={{ color: yellow[700] }}
                                    fontSize="small"
                                  />
                                </IconButton>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      </Table>
                    ))}
                </TableContainer>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
