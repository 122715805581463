import * as React from "react";
import { Grid, Typography } from "@mui/material";
import HandleUrl from "../../utils/HandleUrl";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { i18n } from "../../translate/i18n";
import { Form, Formik } from "formik";
import Textfield from "../../components/form-components/text-field";
import * as Yup from "yup";
import AlertDialog from "../../components/dialog-option/AlertDialog";
import { MainButton } from "../../components/main-button/MainButton";
import FallBackLoader from "../../components/FallBackComponents/FallBackLoader";
import { getItem as get } from "../../utils/functions/tableCRUDFunctions";
import {
  createItem as create,
  updateItem as update,
} from "../../utils/functions/formCRUDFunctions";
import { useKeycloak } from "@react-keycloak/web";

export default function SubjectCreatePage() {
  let isDisabled = HandleUrl();
  const { id } = useParams();
  const [INITIAL_FORM_STATE, setINITIAL_FORM_STATE] = useState({
    name: "",
    description: "",
    acronym: "",
    id: "",
  });

  const isUpdate = !!id;
  const [status, setStatus] = useState({
    isLoading: true,
  });
  const { keycloak } = useKeycloak();
  let page = 1;
  const apiUrl = `${process.env.REACT_APP_API_URL}/subject`;

  const [textBox, setTextBox] = useState([
    i18n.t(id ? "messages.updateTitle" : "messages.createTitle"),
    "",
  ]);

  const FORM_VALIDATION = Yup.object().shape({
    acronym: Yup.string().required(i18n.t("messages.required")),
    description: Yup.string().required(i18n.t("messages.required")),
    name: Yup.string().required(i18n.t("messages.required")),
    id: Yup.string().optional(),
  });

  const getItem = get(
    `${apiUrl}/${id}`,
    keycloak,
    setINITIAL_FORM_STATE,
    undefined,
    page
  );
  useEffect(() => {
    if (id) {
      getItem();
    }
  }, [id]);

  const formatFormData = (values) => ({
    name: values.name,
    acronym: values.acronym,
    description: values.description,
  });

  const createItem = create(formatFormData, apiUrl, setTextBox, setStatus);
  const updateItem = update(formatFormData, apiUrl, setTextBox, setStatus);

  return (

    <div
      style={{
        backgroundColor: "white",
        padding: "80px",
        paddingTop: "55px",
      }}
    >
      <Formik
        initialValues={{
          ...INITIAL_FORM_STATE,
        }}
        validationSchema={FORM_VALIDATION}
        onSubmit={(values) => {
          if (id) {
            updateItem(values);
          } else {
            createItem(values);
          }
        }}
        enableReinitialize
      >
        {({ values }) => (
          <Form>

            <Typography variant="h1">
              {isUpdate ? values.name : i18n.t("others.NewS")}{" "}
            </Typography>
            <Grid container spacing={3} style={{ marginTop: "10px" }}>
              <Grid item xs={5}>
                <Textfield
                  name="name"
                  label={i18n.t("others.name")}
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item xs={5}>
                <Textfield
                  name="acronym"
                  label={i18n.t("others.Sigla")}
                  disabled={isDisabled}
                />
              </Grid>
              <Grid item xs={10}>
                <Textfield
                  name="description"
                  label={i18n.t("others.description")}
                  disabled={isDisabled}
                  multiline
                />
              </Grid>

              <Grid item xs={2}>
                <div
                  style={{
                    display: "flex",
                    paddingLeft: "583px",
                    paddingTop: "130px",
                    gap: "10px",
                  }}
                >
                  <span style={{ marginLeft: "10px" }}>
                    <MainButton
                      url="/subjects"
                      name={i18n.t("button.cancel")}
                    />
                  </span>
                  <span>
                    <AlertDialog
                      button={
                        isUpdate
                          ? i18n.t("button.update")
                          : i18n.t("button.register")
                      }
                      goBack={i18n.t("subjects.title")}
                      head={textBox[0]}
                      body={
                        textBox[1] || (
                          <FallBackLoader isLoading={status.isLoading} />
                        )
                      }
                      urlGoBack="/subjects"
                      stay={i18n.t("subjects.titleCreate")}
                      urlStay="/subjects/create"
                      isValid={true}
                      type="submit"
                      disabled={isDisabled}
                    />
                  </span>
                </div>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>

  );
}
