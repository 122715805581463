import './styles/enroll-confirmation.css';
import question from '../../../assets/question.png'
import { Button, Container, Grid, Snackbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import EastIcon from '@mui/icons-material/East';
import { Link, useParams } from 'react-router-dom';
import enrollImage from '../../../assets/enrollImage.png'
import information from "../../../assets/information.png"
import call from "../../../assets/call.png"
import mail from "../../../assets/mail.png"
import logo from "../../../assets/mainLogo.png"
import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import axios from "axios";
import UploadButton from '../../../components/form-components/upload-button';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const StyledButton = styled(Button)(({ theme }) => ({
  color: '#FFC107',
  width: '400px',
  border: 'none',
  alignSelf: 'right',
  '&:hover': {
    backgroundColor: 'white',
    border: 'none'
  },
}));

const NextButton = styled(Button)({
  p: 3,
  backgroundColor: '#FFC107',
  color: 'white',
  width: "100%",
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#FFCC39',
  },
});




const EnrollConfirmation = () => {
  const { keycloak } = useKeycloak();
  const { id } = useParams();
  const [files, setFiles] = useState({})
  const [student, setStudent] = useState({})
  const [status, setStatus] = useState("")
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isSenderLoading, setIsSenderLoading] = useState(false)
  const [open, setOpen] = useState(false);
  const [toast, setToast] = useState({ message: "", severity: "success" })
  const handleClick = (m, s) => {
    setToast({ message: m, severity: s })
    setTimeout(() =>
      setOpen(true), 200);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };




  const searchParams = new URLSearchParams(window.location.search);

  const uploadFiles = async (files, id, name, type) => {
    setIsSenderLoading(true)
    try {
      const formData = new FormData();
      formData.append('entityID', id);
      console.log(files)
      formData.append(name, files[name]); // O nome 'file' deve corresponder ao esperado pela API


      const response = await axios.post(`${process.env.REACT_APP_API_URL}/enrollAttachment/${type}`, formData, {
        headers: {
          Authorization: "Bearer " + keycloak.token, // token de autenticação desgraçado
        },

        'Content-Type': 'multipart/form-data', // Importante para enviar arquivos

      });

      // A resposta da API pode conter informações úteis, dependendo do seu caso
      console.log('Resposta da Requisição de Upload:', response);

      handleClick("Ficheiros Upploaded Com Successo", "success")

    } catch (error) {
      console.error('Erro ao fazer upload do arquivo:', error);

      handleClick("Erro ao fazer upload do arquivo:", "error")
    } finally {
      setIsSenderLoading(false)
    }
  };



  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    async function fetchData() {
      try {
        const response = await axios.get(`${apiUrl}/enroll/${searchParams.get("id")}`, {
          headers: {
            Authorization: "Bearer " + keycloak.token,
          },
        });
        const data = response.data;

        // Agora, você pode acessar os dados, como nome e ano da matrícula, a partir de 'data'
        setStudent(data.student);

        setStatus(data.status)
        // Faça o que for necessário com esses dados
        // Atualize o estado ou exiba as informações na página
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, [id, keycloak?.token]);

  return (


    <Grid container>
      <Snackbar open={open} anchorOrigin={{ vertical: "top", horizontal: "center" }} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={toast?.severity} sx={{ width: '100%' }}>
          {toast?.message}
        </Alert>
      </Snackbar>
      <Grid item xs={9}>

        <Container maxWidth='lg'>

          <img src={logo} className='logo-confirmation' />

          <div className='enroll-confirmation'>


            <h2>Inscrição em medicina</h2>

            <div className='confirmation-body'>
              <div className='flex'>
                <div>
                  <h3>Nome:</h3>
                </div>
                <div>
                  <p>{searchParams.get("name") || student?.name}</p>
                </div>
              </div>
              <div className='flex'>
                <div>
                  <h3>Identificação:</h3>
                </div>
                <div>
                  <p>{searchParams.get("documentNumber") || student?.documentNumber}</p>
                </div>
              </div>
              <div className='flex'>
                <div>
                  <h3>Ano de Matrícula:</h3>
                </div>
                <div>
                  <p>{searchParams.get("year") || student?.year}</p>
                </div>

              </div>
              <div className='flex'>
                <div>
                  <h3>Método de pagamento:</h3>
                </div>
                <div>
                  <p>Referência de pagamento</p>
                </div>
              </div>
            </div>

            <div className='confirmation-footer'>
              <div>
                <img src={question} />
              </div>
              <div>
                <Typography>A sua matrícula se encontra em processamento!</Typography>
                <Typography sx={{ textAlign: 'justify', color: "#949494" }} variant='p'>Recebemos a sua submissão para matrícula com sucesso.Receberá a referência de pagamento, depois de pagar deve enviar o comprovativo para o link fornecido no seu email.</ Typography>
              </div>
            </div>
          </div>

          <Grid container spacing={6}>
            <Grid item xs={12} justifyContent="end" sx={{ display: "flex" }}>
              <div style={{ flexGrow: 1 }}>

              </div>
              <div className=''>

                <div className='s-18 pb-22' style={status !== "RUPE_GENERATED" ? { display: "none" } : {}}>
                  <div className='w-240'>
                    <p className='ref'>Comprovante de pagamento:</p>
                  </div>
                  <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                    <div className='w-240' >
                      <UploadButton files={files} set={setFiles} name="ConfirmationPayment" />
                    </div>
                    <div className='' style={{ display: "flex" }}>
                      <NextButton variant="contained" onClick={() => {
                        uploadFiles(files, searchParams.get("id"), "ConfirmationPayment", "payment-confirmation");
                      }}>Enviar</NextButton>
                    </div>
                  </div>
                </div>
                <div>
                  <Link to="/" style={{ textDecoration: 'none' }}>
                    <StyledButton variant="outlined" endIcon={<EastIcon />}>
                      <span>
                        Voltar a Página Inicial
                      </span>
                    </StyledButton>
                  </Link>
                </div>

              </div>

            </Grid>
          </Grid>
        </Container>
      </Grid>

      <Grid item xs={3}>
        <div className='confirmation-left-side'>
          <img src={enrollImage} />

          <div className="confirmation-content">
            <div className="information">
              <img src={information} />
              <h2>Para Informações</h2>
            </div>
            <div class="information-contend">
              <p>Encontre aqui as informações de apoio para o processo de matrícula na FMUAN.</p>

              <div className="contact-group">
                <img src={call} />
                <p>954 552 708 - 952 275 073 - 937 601 616 </p>
              </div>
              <div className="contact-group">
                <img src={mail} />
                <p>daacmedicina2223@gmail.com
                  secretariafmuan@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default EnrollConfirmation