import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import HandleUrl from '../../../utils/HandleUrl';
import { i18n } from '../../../translate/i18n';
import Textfield from '../../../components/form-components/text-field';




export default function Contact() {

    const [isDisabled, setIsDisabled] = React.useState(HandleUrl());




    return (

        <Grid container >

            <Grid item xs={12} marginBottom={2} >
                <Typography sx={{ color: "#003B76" }} variant="h3">{i18n.t("others.contact")}</Typography>
            </Grid>

            <Grid item xs={12} container spacing={3}>

                <Grid item xs={5} >
                    <Textfield name="phone" label={i18n.t("others.phone")} disabled={isDisabled} />
                </Grid>

                <Grid item xs={5} >
                    <Textfield name="institutionalEmail" label={i18n.t("others.emailInst")}  disabled={isDisabled} />
                </Grid>

                <Grid item xs={5} >
                    <Textfield name="personalEmail" label={i18n.t("others.emailPers")}disabled={isDisabled} />
                </Grid>
                <Grid item xs={5} >
                    <Textfield name="address" label={i18n.t("others.address")}disabled={isDisabled} />
                </Grid>

            </Grid>

        </Grid>

    )


}