import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CourseStudents from "../../create/CourseStudents";
import CourseTeachers from "../../create/CourseTeachers";
import CourseDisciplines from "../../create/CourseDisciplines";
import FilterList from "../../../../components/filterList/FilterList";
import AutoSearch from "../../../../components/autoSearch/AutoSearch";
import { i18n } from "../../../../translate/i18n";
import ShedulesSelect from "./ShedulesSelect";
import keycloak from "../../../../keycloak";
import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFilter } from "../../../../utils/hooks/useSearch";

import { debounce } from "lodash";

import { flattenArray } from "../../../../utils/functions/tableFilterFunctions";

function TabPanel(props) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box sx={{ p: 2 }}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}

TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.number.isRequired,
   value: PropTypes.number.isRequired,
};

export default function ButtonSelect(props) {
   const [value, setValue] = useState(0);
   const [studentsDate, setStudentsDate] = useState([]);
   const [searchTerm, setSearchTerm] = useState("");
   const { id } = useParams();
   const apiUrl = process.env.REACT_APP_API_URL;

   const [selectedYear, setSelectedYear] = useState([]);
   let selectedCourse= [];
   const [selectedSemester, setSelectedSemester] = useState([]);
   const [selectedSubject, setSelectedSubject] = useState([]);
   let selectedNumberOfGroups=[];
   let selectedNumberOfStudents= [];

   const extractUniqueValues = (array, key) => {
      return [...new Set(array?.map((item) => item[key]))];
   };

   const flattenedSemesters = flattenArray(studentsDate.courseSemesterSubjects, "semester");
   const semesters = [...new Set(extractUniqueValues(flattenedSemesters, "semester").map((item) => item))];

   const flattenedSubject = flattenArray(studentsDate.courseSemesterSubjects, "subject");
   const subjects = [...new Set(extractUniqueValues(flattenedSubject, "subject").map((item) => item.name))];
   

   const filteringProps = {
      year: selectedYear,
      name: selectedCourse,
      semester: selectedSemester,
      subject: selectedSubject,
      numberOfGroups: selectedNumberOfGroups,
      numberOfStudents: selectedNumberOfStudents,
      search: searchTerm,
   };

   const { setCanFilter, canFilter } = useFilter();
 

   const filters = [
      {
         label: i18n.t("others.year"),
        
         selectedOptions: selectedYear,
         setSelectedOptions: setSelectedYear,
      },     
      {
         label: i18n.t("others.semester"),
         options: semesters,
         selectedOptions: selectedSemester,
         setSelectedOptions: setSelectedSemester,
      },
      {
         label: i18n.t("others.subject"),
         options: subjects,
         selectedOptions: selectedSubject,
         setSelectedOptions: setSelectedSubject,
      },
   
   ];

   const handleChange = (event, newValue) => {
      setValue(newValue);
   };

   const handleSearch = (searchTerm) => {
      // Implemente a lógica de pesquisa aqui
      setSearchTerm(searchTerm);


   };

   const getStudentsByCourseId = async (filters) => {
      try {
         const studentsResponse = await axios.get(`${apiUrl}/courses/${id}`, {
            headers: {
               Authorization: "Bearer " + keycloak.token,
            },
            params: {
               search: searchTerm,
               ...filters, // Passando os filtros como parâmetros
            },
         });

         const studentsData = studentsResponse?.data || studentsResponse?.data?.data || {};
         setStudentsDate(studentsData);
      } catch (error) {
         console.error("Erro ao obter dados dos alunos:", error);
      } 
   };

   const debouncedGetItem = debounce((filters) => getStudentsByCourseId(filters), 500);

   useEffect(() => {
      if (keycloak.token) {
         if (searchTerm) {
            debouncedGetItem(filteringProps);
         } else {
            getStudentsByCourseId(filteringProps);
         }
      }
   }, [keycloak.token, searchTerm]);
   return (
      <Box>
         <Box
            sx={{
               display: "flex",
               justifyContent: "space-between",
               backgroundColor: "#F1F1E1",
               height: "75px",
               marginX: "15px",
            }}
         >
            <Tabs
               value={value}
               onChange={handleChange}
               aria-label="basic tabs example"
               sx={{
                  height: "10px",
                  display: "flex",
                  alignItems: "center",
                  margin: "auto",
                  marginLeft: "20px",
               }}
            >
               <Tab
                  label={i18n.t("others.disciplines")}
               
                  style={{
                     backgroundColor: value === 0 ? "#FFC107" : "",
                     color: value === 0 ? "#f3f3f3" : "#2B434E",
                  }}
               />
               <Tab
                  label={i18n.t("others.schedules")}
                
                  style={{
                     backgroundColor: value === 1 ? "#FFC107" : "",
                     color: value === 1 ? "#f3f3f3" : "#2B434E",
                  }}
               />
               <Tab
                  label={i18n.t("others.student")}
               
                  style={{
                     backgroundColor: value === 2 ? "#FFC107" : "",
                     color: value === 2 ? "#f3f3f3" : "#2B434E",
                  }}
               />
               <Tab
                  label={i18n.t("others.teacher")}
            
                  style={{
                     backgroundColor: value === 3 ? "#FFC107" : "",
                     color: value === 3 ? "#f3f3f3" : "#2B434E",
                  }}
               />
            </Tabs>
            <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
               {value !== 1 && value !== 0 && (
                  <AutoSearch
                     placeholder={i18n.t("others.search")}
                     sx={{ backgroundColor: "white" }}
                     search={searchTerm} // Passando o termo de pesquisa
                     setSearch={handleSearch} // Passando a função para atualizar o termo de pesquisa
                  />
               )}

               {value !== 0 && (
                  <FilterList
                     filters={filters}
                     setCanFilter={setCanFilter}
                     canFilter={canFilter}
                     cardStyle={{
                        position: "absolute",
                        zIndex: "1",
                        top: "39.8rem",
                        right: "21.8rem",
                     }}
                  />
               )}
            </Box>
         </Box>

         <TabPanel value={value} index={0}>
            <CourseDisciplines totalNumberOfYears={props.totalNumberOfYears} schoolYearId={props.schoolYearId} />
         </TabPanel>
         <TabPanel value={value} index={1}>
            <ShedulesSelect />
         </TabPanel>
         <TabPanel value={value} index={2}>
            <CourseStudents />
         </TabPanel>
         <TabPanel value={value} index={3}>
            <CourseTeachers searchTerm={searchTerm} />
         </TabPanel>
      </Box>
   );
}
ButtonSelect.propTypes = {
   totalNumberOfYears: PropTypes.number.isRequired,
   schoolYearId: PropTypes.string.isRequired,
};
