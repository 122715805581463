import { Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as React from "react";
import {useParams } from "react-router-dom";
import * as Yup from "yup";
import AlertDialog from "../../components/dialog-option/AlertDialog";
import Textfield from "../../components/form-components/text-field";
import { i18n } from "../../translate/i18n";
import HandleUrl from "../../utils/HandleUrl";
import { MainButton } from "../../components/main-button/MainButton";
import { useEffect,useState  } from "react";
import { useKeycloak } from "@react-keycloak/web";
import FallBackLoader from "../../components/FallBackComponents/FallBackLoader";
import { getItem as get } from "../../utils/functions/tableCRUDFunctions";
import {
  createItem as create,
  updateItem as update,
} from "../../utils/functions/formCRUDFunctions";

export default function GraduationCreatePage() {
  let isDisabled = HandleUrl();

  const { keycloak } = useKeycloak();
  let page =1;
  const apiUrl = `${process.env.REACT_APP_API_URL}/graduations`;
  const [INITIAL_FORM_STATE, setINITIAL_FORM_STATE] = useState({});
  const { id } = useParams();
  const isUpdate = !!id;
  const [status, setStatus] = useState({
    isLoading: true,
  });

  const [textBox, setTextBox] = useState([
    i18n.t(id ? "messages.updateTitle" : "messages.createTitle"),
    "",
  ]);

  const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string().required(i18n.t("messages.required")),
    description: Yup.string().required(i18n.t("messages.required")),
  });

  const getItem = get(
    `${apiUrl}/${id}`,
    keycloak,
    setINITIAL_FORM_STATE,
    undefined,
    page
  );
  useEffect(() => {
    if (id) {
      getItem();
    }
  }, [id]);

  const formatFormData = (values) => ({
    name: values.name,
    description: values.description,
  });

  const createItem = create(formatFormData, apiUrl, setTextBox, setStatus);
  const updateItem = update(formatFormData, apiUrl, setTextBox, setStatus);

  return (
    <>
      <Grid
        sx={{ backgroundColor: "white", padding: "80px", paddingTop: "55px" }}
      >
        <Formik
          initialValues={{
            ...INITIAL_FORM_STATE,
          }}
          validationSchema={FORM_VALIDATION}
          onSubmit={(values) => {
            if (id) {
              updateItem(values);
            } else {
              createItem(values);
            }
          }}
          enableReinitialize
        >
          {({ values, isValid }) => (
            <Form>
              <Typography variant="h1">
                {isUpdate ? values.name : i18n.t("others.NewG")}{" "}
              </Typography>
              <br />

              <Grid container spacing={4}>
                <Grid item xs={5}>
                  <Textfield
                    name="name"
                    label={i18n.t("others.name")}
                    disabled={isDisabled}
                  />
                </Grid>

                <Grid item xs={10}>
                  <Textfield
                    name="description"
                    label={i18n.t("others.description")}
                    disabled={isDisabled}
                    multiline
                  />
                </Grid>
              </Grid>
              <br />
              <br />
              <Grid container spacing={3}>
                <Grid item xs={2}>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      paddingLeft: "590px",
                      paddingTop: "130px",
                    }}
                  >
                    <span style={{ marginLeft: "10px" }}>
                      <MainButton
                        url="/graduation"
                        name={i18n.t("button.cancel")}
                      />
                    </span>
                    <span>
                      <AlertDialog
                        show={true}
                        button={
                          isUpdate
                            ? i18n.t("button.update")
                            : i18n.t("button.register")
                        }
                        head={textBox[0]}
                        body={
                          textBox[1] || (
                            <FallBackLoader isLoading={status.isLoading} />
                          )
                        }
                        goBack={i18n.t("others.listOfGraduation")}
                        urlGoBack="/graduation"
                        stay={
                          isUpdate
                            ? i18n.t("others.graduation")
                            : i18n.t("graduations.newGrad")
                        }
                        isValid={isValid}
                        urlStay={
                          isUpdate
                            ? `graduation/${id}/edit`
                            : "/graduation/create"
                        }
                        type="submit"
                        disabled={isDisabled}
                      />
                    </span>
                  </div>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
}
