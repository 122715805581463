import { Container, Grid, Typography } from "@mui/material";
import question from '../../../assets/question.png'



const IssueDocumentConfirmation = () => {
  const searchParams = new URLSearchParams(window.location.search);
     
    return (
        <Grid container style={{backgroundColor:"white"}}>
            <Grid item xs={8} style={{padding:"120px"}}>
                <Container maxWidth='lg'>
                  <div className='enroll-confirmation'>   
                    <h2>Solicitação de decumento</h2>
                    <div className='confirmation-body'>
                      <div className='flex'>
                        <div>
                          <h3>Nome:</h3>
                        </div>
                        <div>
                          <p>{searchParams.get("name") }</p>
                        </div>
                      </div>
                      <div className='flex'>
                        <div>
                          <h3>Identificação:</h3>
                        </div>
                        <div>
                          <p>{searchParams.get("mechanographicNumber") }</p>
                        </div>
                      </div>
                      <div className='flex'>
                        <div>
                          <h3>Document Type:</h3>
                        </div>
                        <div>
                          <p>{searchParams.get("docType") }</p>
                        </div>
                      </div>
                      <div className='flex'>
                        <div>
                          <h3>Payment Method:</h3>
                        </div>
                        <div>
                          <p>Referência de pagamento</p>
                        </div>
                      </div>
                    </div>
                    <div className='confirmation-footer'>
                      <div>
                        <img src={question} />
                      </div>
                      <div>
                        <Typography>O seu Documento se encontra em processamento!</Typography>
                        <Typography sx={{ textAlign: 'justify', color: "#949494" }} variant='p'>
                          Receberá um e-mail com a referencia de pagamento para finalizar a sua solicitação.
                        </ Typography>
                      </div>
                    </div>
                  </div>
                </Container>
            </Grid>
        </Grid>
    )
}

export default IssueDocumentConfirmation;