import React, { useState, useEffect } from "react";
import "./enroll-analysis.css";
import {
  Box,
  Button,
  Modal,
  Step,
  StepButton,
  Stepper,
  Typography,
} from "@mui/material";
import SquareIcon from "@mui/icons-material/Square";

import PaymentAnalyse from "./form-analysis/PaymentAnalyse";
import HistoryAnalyse from "./form-analysis/HistoryAnalyse";
import { Form, Formik } from "formik";
import { useKeycloak } from "@react-keycloak/web";
import { useParams } from "react-router-dom";
import axios from "axios";
import PersonalInfo from "./Form-components/PersonalInfo";
import SecondarySchoolInfo from "./Form-components/SecondarySchoolInfo";
import AdditionalInfo from "./Form-components/AdditionalInfo";
import DateTimePicker from "../../components/form-components/date-time-picker";

import Textfield from "../../components/form-components/text-field";


import MuiAlert from "@mui/material/Alert";
import FallBackLoader from "../../components/FallBackComponents/FallBackLoader";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const steps = [
  "Dados Biográficos",
  "Dados do Ensino Médio",
  "Dados Adicionais",
  "Pagamento",
  "Histórico",
];
const buttonStyle = {
  backgroundColor: "#FFC107",
  color: "white",
  padding: "5px 35px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#FFCC39",
  },
  margin: "1rem 1rem 1rem 0",
};

const EnrollAnalysis = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { keycloak } = useKeycloak();
  const [canChangeSteps, setCanChangeSteps] = useState(
    keycloak?.tokenParsed?.resource_access?.Financeiro
  );
  const [incomingData, setIncomingData] = useState({});
  const [modalOpen, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    subTitle: "",
    content: <></>,
    confirm: () => {},
  });
  const { id } = useParams();
  const [open, openAlert] = useState(false);
  let status ={
    isLoading: true,
  };
  const [toast, setToast] = useState({ message: "", severity: "success" });
  const handleClick = (m, s) => {
    setToast({ message: m, severity: s });
    setTimeout(() => openAlert(true), 200);
  };
  const sendValues = async (type, values) => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/enroll/${id}/${type}`, values, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      })
      .then((response) => {
        handleClick(type + " enviado com sucesso", "success");
        setTimeout((window.location.href = "/enrolls"), 500);
      })
      .catch((err) => {
        handleClick("Erro ao fazer pedido:", "error");
      });
  };
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/enroll/${id}`,
          {
            headers: {
              Authorization: "Bearer " + keycloak.token,
            },
          }
        );
        const data = response.data;

        // Agora, você pode acessar os dados, como nome e ano da matrícula, a partir de 'data'
        setIncomingData(data);
        // Faça o que for necessário com esses dados
        // Atualize o estado ou exiba as informações na página
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, [id, keycloak.token]);

  useEffect(() => {
    if (!canChangeSteps) {
      setActiveStep(3);
    }
  }, [canChangeSteps]);

  useEffect(() => {
    if (keycloak?.tokenParsed)
      if (
        keycloak?.tokenParsed?.resource_access?.["sga-api"]?.roles?.includes(
          "Secretaria"
        ) ||
        keycloak?.tokenParsed?.resource_access?.["sga-api"]?.roles?.includes(
          "Admin"
        )
      )
        setCanChangeSteps(true);
  }, [keycloak.token]);

  useEffect(() => {
    if (incomingData.status === "LIQUIDATED") {
      setActiveStep(0); // Começa no primeiro passo quando o estado é "Liquidado".
    } else {
      // Caso contrário, você pode configurar aqui o passo inicial padrão para outros estados.
      setActiveStep(3);
    }
  }, [incomingData.status]);
  const handleStep = (step) => () => {
    if (!canChangeSteps && step !== 3) {
      return; // Impede que usuários não financeiros naveguem para outras etapas além da etapa de pagamento
    }
    setActiveStep(step);
  };

  const MyModal = ({ confirm, title, subTitle, content, open, close }) => {
    return (
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="modal-modal-title"
            align="center"
            color="#003B76"
            variant="h6"
            component="h2"
          >
            {title}
          </Typography>
          <Typography
            id="modal-modal-description"
            align="center"
            sx={{ mt: 2, mb: 4 }}
          >
            {subTitle}
          </Typography>
          <Formik
            initialValues={{}}
            onSubmit={(values, { setSubmitting }) => {
              if (confirm) confirm(values).finally(() => setSubmitting(false));
              close();
              console.log(values);
            }}
          >
            {({
              isSubmitting,
              /* and other goodies */
            }) => (
              <Form>
                <Box>{content}</Box>

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    sx={{
                      ...buttonStyle,
                      backgroundColor: "#FFF",
                      border: "1px solid #FFC107",
                      color: "#FFC107",
                      "&:hover": {
                        backgroundColor: "#FFCC39",
                        color: "white",
                      },
                    }}
                    variant="contained"
                    onClick={close}
                  >
                    Cancelar
                  </Button>
                  <Button sx={buttonStyle} variant="contained" type="submit">
                    {isSubmitting ? "Comfirmando" : "Confirmar"}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    );
  };
  const stepStyles = {
    "& .Mui-active": {
      color: "#FFC107",
    },
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  if (!incomingData.student)
    return (
      <>
        {" "}
        <FallBackLoader isLoading={status.isLoading} />
      </>
    );
  return (
    <div className="center-container">
      <h2
        style={{
          position: "fixed",
          marginTop: "-60px",
        }}
      >
        Analisar Matrícula
      </h2>

      <div className="mother-flex">
        <div className="left-side">
          <Stepper
            orientation="vertical"
            nonLinear
            activeStep={activeStep}
            sx={stepStyles}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton
                  sx={{ color: "#C2D6E5" }}
                  icon={<SquareIcon />}
                  onClick={handleStep(index)}
                  disabled={!canChangeSteps && index !== 3} // Desabilita etapas não relacionadas ao pagamento para usuários não financeiros
                >
                  <p className="step-title">{label}</p>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>

        <div className="divider"></div>

        <div className="right-side">
          <Formik initialValues={{ ...incomingData.student }}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <div>
                {activeStep === 0 && (
                  <>
                    <PersonalInfo
                      id={incomingData.id}
                      values={values}
                      disabled
                    />
                  </>
                )}
                {activeStep === 1 && (
                  <>
                    <SecondarySchoolInfo id={incomingData.id} disabled />
                  </>
                )}
                {activeStep === 2 && (
                  <>
                    <div>
                      <AdditionalInfo
                        handleClick={handleClick}
                        disabled
                        id={incomingData.id}
                        acceptUserTermsProps={{
                          acceptUseTerms: {},
                          setAcceptUseTerms: () => {},
                        }}
                        values={values}
                      />
                    </div>

                    <div className="flex">
                      <div>
                        <div
                          style={
                            [
                              "UNDER_ANALYSIS",
                              "LIQUIDATED",
                              "APPROVED",
                            ].includes(incomingData.status)
                              ? {
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 10,
                                }
                              : { display: "none" }
                          }
                        >
                          {" "}
                          <svg
                            width="27"
                            height="27"
                            viewBox="0 0 27 27"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M24.2115 0H2.78853C1.24847 0 0 1.27693 0 2.85211V24.1479C0 25.7231 1.24847 27 2.78853 27H24.2115C25.7515 27 27 25.7231 27 24.1479V2.85211C27 1.27693 25.7515 0 24.2115 0Z"
                              fill="#FB3F4A"
                            />
                            <path
                              d="M12.5937 13.994L8.00471 18.5235L9.32851 19.8302L13.9175 15.3006L18.6786 20L20 18.6952L15.2394 13.9963L19.9906 9.30619L18.6668 8L13.9156 12.6896L9.32191 8.15542L8 9.45975L12.5937 13.994Z"
                              fill="white"
                              stroke="white"
                              stroke-width="0.5"
                            />
                          </svg>
                          Documento Não Conforme
                        </div>
                        <Button
                          sx={
                            [
                              "UNDER_ANALYSIS",
                              "LIQUIDATED",
                              "APPROVED",
                            ].includes(incomingData.status)
                              ? {
                                  ...buttonStyle,
                                  backgroundColor: "#FFF",
                                  border: "1px solid #FFC107",
                                  color: "#FFC107",
                                  "&:hover": {
                                    backgroundColor: "#FFCC39",
                                    color: "white",
                                  },
                                }
                              : { display: "none" }
                          }
                          variant="contained"
                          onClick={() => {
                            setModalContent({
                              title: "Inscrição rejeitada",
                              subTitle:
                                "Descreva o motivo pela qual a inscrição foi rejeitada.",
                              confirm: async (values) => {
                                sendValues("reject", values);
                              },
                              content: (
                                <>
                                  <Textfield
                                    name="reason"
                                    multiline
                                    rows={5}
                                    fullWidth
                                  />
                                </>
                              ),
                            });
                            setOpen(true);
                          }}
                        >
                          Rejeitar
                        </Button>
                        <Button
                          sx={
                            [
                              "UNDER_ANALYSIS",
                              "LIQUIDATED",
                              "WAITING_FOR_STUDENT_INFO",
                            ].includes(incomingData.status)
                              ? buttonStyle
                              : { display: "none" }
                          }
                          variant="contained"
                          onClick={() => {
                            setModalContent({
                              title: "Pedido de atualização",
                              subTitle:
                                "Descreva os dados que precisam ser atualizados.",
                              confirm: async (values) => {
                                sendValues("request-update", values);
                              },
                              content: (
                                <>
                                  <Textfield
                                    name="reason"
                                    multiline
                                    rows={5}
                                    fullWidth
                                  />
                                </>
                              ),
                            });
                            setOpen(true);
                          }}
                        >
                          Pedir Atualização
                        </Button>
                      </div>
                      <div>
                        <div
                          style={
                            ["UNDER_ANALYSIS", "LIQUIDATED"].includes(
                              incomingData.status
                            )
                              ? {
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 10,
                                }
                              : { display: "none" }
                          }
                        >
                          {" "}
                          <svg
                            width="27"
                            height="27"
                            viewBox="0 0 27 27"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M24.2115 0H2.78853C1.24847 0 0 1.27693 0 2.85211V24.1479C0 25.7231 1.24847 27 2.78853 27H24.2115C25.7515 27 27 25.7231 27 24.1479V2.85211C27 1.27693 25.7515 0 24.2115 0Z"
                              fill="#55D400"
                            />
                            <path
                              d="M18.9756 7.92676L11.6619 15.4033L8.37553 12.0616L6.83984 13.6402C8.44987 15.2762 10.0568 16.9155 11.6696 18.5486C14.6181 15.5329 17.5666 12.5171 20.5151 9.50138L18.9756 7.92676Z"
                              fill="white"
                            />
                          </svg>
                          Documento Aprovado
                        </div>

                        <Button
                          sx={
                            ["UNDER_ANALYSIS", "LIQUIDATED"].includes(
                              incomingData.status
                            )
                              ? buttonStyle
                              : { display: "none" }
                          }
                          variant="contained"
                          onClick={() => {
                            setModalContent({
                              title: "Incrição Aprovada",
                              subTitle: "",
                              confirm: async (values) => {
                                sendValues("approve", values);
                              },
                              content: (
                                <>
                                  <Typography
                                    id="modal-modal-title"
                                    color="#003B76"
                                  >
                                    Definir data entrega
                                  </Typography>
                                  <Box sx={{ display: "flex", gap: 4 }}>
                                    <DateTimePicker
                                      name="showUpFrom"
                                      label={
                                        <span>
                                          De
                                          <span className="red-asterisk">
                                            *
                                          </span>
                                        </span>
                                      }
                                    />
                                    <DateTimePicker
                                      name="showUpTo"
                                      label={
                                        <span>
                                          Ate
                                          <span className="red-asterisk">
                                            *
                                          </span>
                                        </span>
                                      }
                                    />
                                  </Box>
                                </>
                              ),
                            });
                            setOpen(true);
                          }}
                        >
                          Aprovar
                        </Button>
                        <div
                          style={
                            incomingData.status === "APPROVED"
                              ? {
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 10,
                                }
                              : { display: "none" }
                          }
                        >
                          {" "}
                          <svg
                            width="27"
                            height="27"
                            viewBox="0 0 27 27"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M24.2115 0H2.78853C1.24847 0 0 1.27693 0 2.85211V24.1479C0 25.7231 1.24847 27 2.78853 27H24.2115C25.7515 27 27 25.7231 27 24.1479V2.85211C27 1.27693 25.7515 0 24.2115 0Z"
                              fill="#55D400"
                            />
                            <path
                              d="M18.9756 7.92676L11.6619 15.4033L8.37553 12.0616L6.83984 13.6402C8.44987 15.2762 10.0568 16.9155 11.6696 18.5486C14.6181 15.5329 17.5666 12.5171 20.5151 9.50138L18.9756 7.92676Z"
                              fill="white"
                            />
                          </svg>
                          Aprovação da Matrícula
                        </div>
                        <Button
                          sx={
                            incomingData.status === "APPROVED"
                              ? buttonStyle
                              : { display: "none" }
                          }
                          variant="contained"
                          onClick={() => {
                            setModalContent({
                              title: "Aprovação da Matrícula",
                              subTitle:
                                "Comfirme a sua intenção de aprovar a matrícula.",
                              confirm: async (values) => {
                                sendValues("finish", {});
                              },
                              content: null,
                            });
                            setOpen(true);
                          }}
                        >
                          Matricular
                        </Button>
                      </div>
                    </div>
                  </>
                )}
                {activeStep === 3 && (
                  <>
                    <PaymentAnalyse />
                  </>
                )}
                {activeStep === 4 && (
                  <>
                    <HistoryAnalyse values={values} />
                  </>
                )}
              </div>
            )}
          </Formik>
          <MyModal
            title={modalContent.title}
            subTitle={modalContent.subTitle}
            content={modalContent.content}
            confirm={modalContent.confirm}
            close={() => {
              setOpen(false);
            }}
            open={modalOpen}
          />
          {activeStep !== steps.length - 1 && (
            <div className="button-container-right">
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EnrollAnalysis;
