import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

const Card = ({ title, addressIcon, addressText, calendarIcon, calendarText, clockIcon, clockText }) => {
  const cardStyle = {
    border: '1px solid #E8E8E8',
    borderRadius: '5px',
    padding: '10px',
    margin: '10px',
  };

  const titleStyle = {
    marginBottom: '5px',
    fontFamily: 'Work Sans',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '21.04px',
    letterSpacing: '3%',
    color: '#003B76',
    width: '100%', // Allow the title to take up the full width
    wordWrap: 'break-word', // Allow the title to wrap to the next line
  };

  return (
    <Grid style={cardStyle}>
      <div>
        <h2 style={titleStyle}>{title}</h2>

        <div style={infoItemStyle}>
          <span style={iconStyle}>{addressIcon}</span>
          <span>{addressText}</span>
        </div>

        <div style={infoItemStyle}>
          <span style={iconStyle}>{calendarIcon}</span>
          <span>{calendarText}</span>
        </div>

        <div style={infoItemStyle}>
          <span style={iconStyle}>{clockIcon}</span>
          <span>{clockText}</span>
        </div>
      </div>
    </Grid>
  );
};

const infoItemStyle = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '5px',
};

const iconStyle = {
  marginRight: '5px',
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  addressIcon: PropTypes.node.isRequired,
  addressText: PropTypes.string.isRequired,
  calendarIcon: PropTypes.node.isRequired,
  calendarText: PropTypes.string.isRequired,
  clockIcon: PropTypes.node.isRequired,
  clockText: PropTypes.string.isRequired,
};

export default Card;
