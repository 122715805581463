import { Grid, Typography } from "@mui/material";
import * as React from "react";
import { i18n } from "../../../translate/i18n";
import Textfield from '../../../components/form-components/text-field';

import HandleUrl from "../../../utils/HandleUrl";



export default function Payment() {

  const [isDisabled, setIsDisabled] = React.useState(HandleUrl());
 
  return (

    <Grid container >
      <Grid item xs={12} marginBottom={2} >
        <Typography sx={{ color: "#003B76" }} variant="h3">{i18n.t("employee.paymentDetails")}</Typography>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} marginBottom={2} >
          <Typography sx={{ color: "#003B76" }} variant="h4">{i18n.t("others.MainData")}</Typography>
        </Grid>
        <Grid item xs={5} >
          <Textfield name="mainBank"  label={i18n.t("others.bank")} disabled={isDisabled} />
        </Grid>

        <Grid item xs={5} >
          <Textfield name="mainAccountNumber" label={i18n.t("others.AccountNumber")} disabled={isDisabled} />
        </Grid>
        <Grid item xs={5} >
          <Textfield name="mainNib" label="NIB" disabled={isDisabled} />
        </Grid>     
         </Grid>

      <Grid container spacing={2} >
        <Grid item xs={12} marginBottom={2} >
          <Typography sx={{ color: "#003B76", paddingTop:"35px" }} variant="h4">{i18n.t("others.SecondaryData")}</Typography>
        </Grid>
        <Grid item xs={5} >
          <Textfield name="secondaryBank" label={i18n.t("others.bank")} disabled={isDisabled} />
        </Grid>

        <Grid item xs={5} >
          <Textfield name="secondaryAccountNumber" label={i18n.t("others.AccountNumber")}disabled={isDisabled} />
        </Grid>
        <Grid item xs={5} >
          <Textfield name="secondaryNib" label="NIB" disabled={isDisabled} />
        </Grid>
      </Grid>

      <Grid item xs={3}  sx={{  paddingTop:"15px" }} >
        <Textfield name="salary" type="number" label={i18n.t("others.salary")} disabled={isDisabled} showDollar={true} />
      </Grid>
    
    </Grid>

  );
}
