import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import HandleUrl from "../../../../utils/HandleUrl";
import { i18n } from "../../../../translate/i18n";
import CardSchedules from "../../../schedules/components/CardSchedules";
import { useState, useEffect, useCallback } from "react";
import { transformScheduleData } from "../../../schedules/functions";

function CustomTabPanel(props) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box sx={{ p: 3 }}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}

CustomTabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.number.isRequired,
   value: PropTypes.number.isRequired,
};

function a11yProps(index) {
   return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
   };
}

export default function ShedulesSelect() {
   const apiUrl = process.env.REACT_APP_API_URL;
   const id = 35;
   console.log("Valor do id:", id);

   const { keycloak } = useKeycloak();

   const isDisabled = HandleUrl();

   const [incommingData, setIncommingData] = useState({});
   const [value, setValue] =useState(0);
   const [days, setDays] = useState([]);
   const [times, setTimes] = useState([]);
   let groups= [];
   const [classrooms, setClassrooms] = useState([]);
   const [period, setPeriod] = useState([]);
   const [strand, setStrand] = useState([]);
   const [classes, setClasses] = useState([]);

   const [schedule, setSchedule] = useState({});

   let listDiscipline=[];
   const [selectedStrand, setSelectedStrand] = useState([]);
   const [selectedPeriod, setSelectedPeriod] = useState([{ name: "Manhã" }]);

   const queryParameters = new URLSearchParams(window.location.search);
   const type = queryParameters.get("type");

   const getSchedules = async () => {
      try {
         const response = await axios.get(`${apiUrl}/${type || "scheduler"}/${id}`, {
            headers: {
               Authorization: "Bearer " + keycloak.token,
            },
         });
         return response;
      } catch (error) {
         console.error(error);
      }
   };

   useEffect(() => {
      if (id) {
         const fetchData = async () => {
            getSchedules()
               .then((response) => {
                  setIncommingData(response?.data);
                  console.log("incomming", incommingData);

                  getStrandData(response?.data?.strand.id);
                  period.length !== 0 && getPeriodData(response?.data?.period?.id);
               })
               .catch((error) => {
                  console.log(error);
               });
         };
         fetchData();
      }
   }, [id, classes]);

   const getList = useCallback(async (type, set) => {
      try {
         const response = await axios.get(`${apiUrl}/${type}`, {
            headers: {
               Authorization: "Bearer " + keycloak.token,
            },
         });

         set(response?.data?.data ? response?.data?.data : response?.data);
         return response?.data?.data ? response?.data?.data : response?.data;
      } catch (error) {
         console.error(error);
      }
   });

   useEffect(() => {
      getList("classes/class-subject/by-coordinator", setClasses);
      getList("classrooms", setClassrooms);
      getList("period", setPeriod);
      getList("strand", setStrand);
      getList("teaching-days", setDays);
      getList("teaching-time", setTimes);
   }, [apiUrl]);

   const resetBoard = () => {
      const newInitialState = days?.reduce((acc, day) => {
         times?.forEach((time) => {
            acc[`${day.id}#${time.id}`] = { groupId: null, classroomId: null, disciplineId: null };
         });
         return acc;
      }, {});

      setSchedule(newInitialState);
   };

   // Atualiza o estado `schedule` sempre que `days` ou `times` forem atualizados
   useEffect(() => {
      resetBoard();
   }, [days, times]);

   

   const typeSchedules = type ? "schedulerPDetails" : "schedulerDetails";
   const scheduleMap = transformScheduleData(incommingData?.[`${typeSchedules}`], days, times);
   
   async function getStrandData(e) {
      let stranded = strand.filter((elemento) => elemento.id === e);
      setSelectedStrand(stranded);
   }

   async function getPeriodData(e) {
      let periods = period?.filter((elemento) => elemento.id === e);
      setSelectedPeriod(periods);
   }

   const handleChange = (event, newValue) => {
      setValue(newValue);
      console.log(newValue);
   }

   return (
      <Box sx={{ width: "100%" }}>
         <Box sx={{}}>
            <Tabs
               value={value}
               onChange={handleChange}
               aria-label="basic tabs example"
               sx={{
                  height: "20px",
            
               }}
            >
               <Tab
                  label={i18n.t("button.studentSchedules")}
                  {...a11yProps(0)}
                  sx={{
                     backgroundColor: value === 0 ? "#F6F6EA" : "none",
                     color: value === 0 ? "white" : "#2B434E",
                     borderRadius: 2,
                   
                  }}
               />
               <Tab
                  label={i18n.t("button.teacherSchedules")}
                  {...a11yProps(1)}
                  sx={{
                     backgroundColor: value === 1 ? "#F6F6EA" : "none",
                     color: value === 1 ? "#FFFFFF" : "#2B434E",
                     borderRadius: 2,
                   
                  }}
               />
             
            </Tabs>
         </Box>

         <CustomTabPanel value={value} index={0}>
            <CardSchedules
               schedule={scheduleMap}
               groups={groups}
               classrooms={classrooms}
               disciplines={listDiscipline}
               setSchedule={setSchedule}
               
               selectedPeriod={selectedPeriod}
               selectedStrand={selectedStrand}
               viewOnly={isDisabled}
            />
         </CustomTabPanel>
         <CustomTabPanel value={value} index={1}>
           
         </CustomTabPanel>
      </Box>
   );
}
