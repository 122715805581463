import { useCallback, useEffect, useState } from "react";
import { Alert, List, Snackbar, Typography } from "@mui/material";

import { DndContext } from "@dnd-kit/core";
import { Grid } from "@mui/material";

import DraggableItem from "./components/DraggableItem";
import Selected from "../../components/form-components/select";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import HandleUrl from '../../utils/HandleUrl';
import { Form, Formik } from "formik";
import { i18n } from '../../translate/i18n';
import { useParams } from "react-router-dom";
import AlertDialog from "../../components/dialog-option/AlertDialog";
import FallBackLoader from "../../components/FallBackComponents/FallBackLoader";
import CardSchedules from "./components/CardSchedules";
import { getErrorMessage, handleFailure } from "../../utils/functions/utilFunctions";
import { transformScheduleData, updateScheduleWithNewData } from "./functions";

const ScheduleTable = () => {
  const { keycloak } = useKeycloak();
  const apiUrl=process.env.REACT_APP_API_URL;
  const { id } = useParams();
  const [incommingData, setIncommingData] = useState({});
  const [days, setDays] = useState([]);
  const [times, setTimes] = useState([]);
  
  const isDisabled =HandleUrl();
  const [classes, setClasses] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [groups, setGroups] = useState([]);
  const [period, setPeriod] = useState([]);
  const [strand, setStrand] = useState([]);

  const [schedule, setSchedule] = useState({});

  const [open, setOpen] = useState(false);
  const [toast, setToast] = useState({ message: "", severity: "success" });
  const handleClick = (m, s) => {
     setToast({ message: m, severity: s });
     setTimeout(() => setOpen(true), 200);
  };
  const handleClose = (event, reason) => {
     if (reason === "clickaway") {
        return;
     }
     setOpen(false);
  };
  const [listDiscipline, setListDiscipline] = useState([]);
  const [selectedStrand, setSelectedStrand] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState([
    {name: "Manhã"}
  ]);

  const [textBox, setTextBox] = useState([i18n.t("messages.createTitle"), ""]);
  const [status, setStatus] = useState({
    isLoading: true,
  });

  const queryParameters = new URLSearchParams(window.location.search)
  const type = queryParameters.get("type")
  
  const getSchedules = async () => {
    try {
      const response = await axios.get(`${apiUrl}/${type ? type : "scheduler"}/${id}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        getSchedules().then((response) => {
          setIncommingData(response?.data);
          if(type) {
            getDisciplineData(response?.data?.subject.id);
          } else {
            getDisciplineData(response?.data?.schedulerDetails[0]?.subject.id);
          }
          getStrandData(response?.data?.strand.id);
          period.length !== 0 && getPeriodData(response?.data?.period?.id);
        }).catch((error) => {console.log(error)});
      }
      fetchData()
    }
  }, [id, classes]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getList = useCallback(async (type, set) => {
    try {
      const response = await axios.get(`${apiUrl}/${type}`, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
        },
      });

      set(response.data?.data ? response.data.data : response.data);
      return response.data?.data ? response.data.data : response.data;
    } catch (error) {
      console.error(error);
    }
  });

  useEffect(() => {
    getList("classes/class-subject/by-coordinator", setClasses);
    getList("classrooms", setClassrooms);
    getList("period", setPeriod);
    getList("strand", setStrand);
    getList("teaching-days", setDays);
    getList("teaching-time", setTimes);
  }, [apiUrl]);

  const resetBoard = () => {
    const newInitialState = days?.reduce((acc, day) => {
      times?.forEach((time) => {
        acc[`${day.id}#${time.id}`] = { groupId: null, classroomId: null, disciplineId: null };
      });
      return acc;
    }, {});
  
    setSchedule(newInitialState);
  }

  // Atualiza o estado `schedule` sempre que `days` ou `times` forem atualizados
  useEffect(() => {
    resetBoard()
  }, [days, times]);


  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (over) {
      setSchedule((prevSchedule) => {
        const newSchedule = { ...prevSchedule };
        const activeId = active.id;
        const overId = over.id;

        // Verificar se o item arrastado é um grupo ou uma sala de aula
        let isDiscipline
        let  isGroup
        if(selectedStrand[0]?.name === "Teórica" ) {
          isDiscipline = listDiscipline.some((discipline) => discipline.subject.id === activeId);
        } else {
          isGroup = groups.some((group) => group.id === activeId);
        }
        const isClassroom = classrooms.some((classroom) => classroom.name === activeId);

        if (isGroup) {
          newSchedule[overId].groupId = activeId;
        } else if (isClassroom) {
          newSchedule[overId].classroomId = activeId;
        } else if (isDiscipline) {
          newSchedule[overId].disciplineId = activeId;
        }

        return newSchedule;
      });
    }
  };

  const getItemId = (itemId, items) => {
    const item = items.find((item) => item?.name === itemId);
    
    return item ? item.id: null
  };

  const exportSchedule = (scheduleMap) => {
    const scheduleDetails = [];

    Object.entries(scheduleMap).forEach(([key, value]) => {
      
      const dinamic = selectedStrand[0]?.name === "Teórica" ? value?.disciplineId?.toString() : value?.groupId?.toString()
      let nameDinamic = selectedStrand[0]?.name === "Teórica" ? "subjectId" : "groupId"

      const [dayId, timeId] = key.split("#");
      scheduleDetails.push({
        dayId: dayId,
        timeId: timeId,
        classroomId: value?.classroomId ? getItemId(value?.classroomId, classrooms)?.toString() : null,
        [nameDinamic]: dinamic,
        teacherId: value?.teacherId ? value?.teacherId?.toString() : null,
      });
    });

    const filteredData = scheduleDetails.filter(item => (item.classroomId !== null ));
    return { details: filteredData };
  };

  async function getDisciplineData(e) {
    let disciplineS = classes.filter(elemento => elemento.subject.id === parseInt(e));
    
    setListDiscipline(disciplineS)
    setGroups(disciplineS[0]?.groups)
    resetBoard()
  }
  
  async function getStrandData(e) {
    let stranded = strand.filter(elemento => elemento.id === e);
    setSelectedStrand(stranded)
  }

  async function getPeriodData(e) {
    let periods = period?.filter(elemento => elemento.id === e);
    setSelectedPeriod(periods)
  }

  const INITIAL_FORM_STATE = {
    classes: listDiscipline[0]?.subject?.id || "",
    period: incommingData?.period?.id || "",
    strand: incommingData?.strand?.id || "",
  }
  
  const updateScheduleWithTeacher = (timeSlotId, teacherId) => {
    setSchedule((prevSchedule) => {
      const newSchedule = { ...prevSchedule };
      // Assuming the schedule structure has a field for teacherId
      newSchedule[timeSlotId].teacherId = teacherId;
      return newSchedule;
    });
  };

  async function createSchedules(values) {
    const { details: scheduleDetails } = exportSchedule(scheduleMap);
    const hasMissingTeacherId = scheduleDetails.some(detail => detail.teacherId === null);

    if (hasMissingTeacherId) {
      handleClick(i18n.t("schedules.missingTeacher"), "success");
      return; 
    }

    const formData = {
      year: listDiscipline[0]?.year,
      courseId: listDiscipline[0]?.course.id,
      semester: listDiscipline[0]?.semester,
      strandId: values.strand,
      periodId: values.period,
      scheduleDetails
    };

    if (selectedStrand[0]?.name === "Prática") {
      formData.subjectId = values.classes;
    }

    if(formData.scheduleDetails.length === 0) {
      setTextBox((prev) => {
        const textCopy = [...prev];
        textCopy[1] = i18n.t("schedules.noSchedule");
        return textCopy;
      });

    } else {
      const baseUrl = selectedStrand[0]?.name === "Teórica" ? "scheduler" : "scheduler-p";
      const urlToPost = id ? `${baseUrl}/${id}` : baseUrl;

      setStatus((prev) => ({ ...prev, isLoading: true }));
      const methodToSend = id ? "put" : "post";

      await axios[methodToSend](urlToPost, formData).then(function (response) {
        if (response.data.id) {
          setTextBox((prev) => {
            const textCopy = [...prev];
            textCopy[1] = i18n.t("schedules.createSuccessMessage");
            return textCopy;
          });
          setStatus((prev) => ({ ...prev, isLoading: false }));
        } else {
          setTextBox([i18n.t("messages.wrong"), handleFailure(response)]);
          setStatus((prev) => ({ ...prev, isLoading: false }));
        }
      }).catch(function (error) {
        console.log("error - ", error)
        setTextBox([i18n.t("messages.wrong"), getErrorMessage(error)]);
        setStatus((prev) => ({ ...prev, isLoading: false }));
      });
    }
   
  }

  const typeSchedules = type ? "schedulerPDetails" : "schedulerDetails"
  const scheduleMap = transformScheduleData(incommingData?.[`${typeSchedules}`], days, times);
  
  updateScheduleWithNewData(scheduleMap, schedule);

  return (
    <div>

      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={toast?.severity} sx={{ width: "100%" }}>
            {toast?.message}
        </Alert>
      </Snackbar>

      <DndContext onDragEnd={handleDragEnd}>
        <Grid container spacing={2}>
          <Grid xs={isDisabled ? 12 : 10}>
            <Formik
              initialValues={{
                ...INITIAL_FORM_STATE
              }}
              
              onSubmit={values => {
                createSchedules(values)
              }}
              enableReinitialize
            >
            <Form>
              <Typography variant="h1"> Novo Horário </Typography>

              <Grid style={{ marginTop: "10px", marginLeft: "-10px", gap: "5px"}} container>
                <Grid xs={3}>
                  <Selected
                    name="classes"
                    options={classes}
                    label={"Turma"}
                    disabled={isDisabled}
                    onChange={(e) => getDisciplineData(e.target.value)}
                  >
                  </Selected>

                </Grid>
                <Grid xs={3}>
                  <Selected
                    name="strand"
                    options={strand}
                    label={"Vertente"}
                    onChange={ (e) => getStrandData(e.target.value)}
                    disabled={isDisabled}
                  >
                  </Selected>
                </Grid>
                <Grid xs={3}>
                  <Selected
                    name="period"
                    options={period}
                    label={"Periodo"}
                    // disabled={isDisabled}
                    onChange={ (e) => getPeriodData(e.target.value)}
                  >
                  </Selected>
                </Grid>
                <Grid xs="2.8">
                  {listDiscipline.length !== 0 && (
                  <h4 style={{
                    display:"flex", 
                    alignItems: "center", 
                    justifyContent: "center", 
                    border: "1px solid #ccc", 
                    padding: "9px",
                    backgroundColor: "#fff",
                    borderRadius: "5px"
                  }}>
                    {listDiscipline.length !== 0 && listDiscipline[0]?.subject?.name} 
                    </h4>
                  )}
                </Grid>
              </Grid>

              <CardSchedules
                schedule={scheduleMap}
                groups={groups}
                classrooms={classrooms}
                disciplines={listDiscipline}
                setSchedule={setSchedule}
                updateScheduleWithTeacher={updateScheduleWithTeacher}
                updateScheduleWithNewData={updateScheduleWithNewData}
                selectedStrand={selectedStrand}
                selectedPeriod={selectedPeriod}
                viewOnly={isDisabled ? true : false}
              />

              {!isDisabled && (
                <Grid item xs={12}>
                <div style={{ marginTop: "50px", marginRight: "40px",   display: 'flex', justifyContent: 'flex-end', gap: '8px'}}>
                  <span>

                  <span>
                    <AlertDialog
                      show={true}
                      button={id ? i18n.t("button.update") : i18n.t("button.register")}
                      head={textBox[0]}
                      body={
                        textBox[1] || (
                          <FallBackLoader isLoading={status.isLoading} />
                        )
                      }
                      goBack={i18n.t("schedules.urlGoBack")}
                      urlGoBack="/schedules"
                      stay={i18n.t("schedules.urlStay")}
                      urlStay="/schedules/create"
                      isValid={true}
                      type="submit"
                    />
                  </span>

                  </span>
                </div>
              </Grid>
              )}
            </Form>
          </Formik>
          </Grid>

          {!isDisabled && (
          <Grid xs={2}>
            { selectedStrand[0]?.name == "Prática" && (
              <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', padding: "10px" }}>
                <Typography variant="h3"> Adicionar Grupos </Typography>
                <p style={{ color: "#42474B", fontSize: "12px" }}> Arraste para adicionar </p>
                {groups?.map((group) => (
                  <DraggableItem key={group.id} id={group.id} name={group.name} />
                ))}
              </List>
            )}

            { selectedStrand[0]?.name === "Teórica" &&  listDiscipline.length > 0 && (
              <List sx={{  width: '100%', maxWidth: 360, marginTop: "15px", bgcolor: 'background.paper', padding: "10px" }}>
                <Typography variant="h3"> Adicionar Disciplina </Typography>
                <p style={{ color: "#42474B", fontSize: "12px" }}> Arraste para adicionar </p>
                {listDiscipline?.slice(0, 1).map((discipline) => (
                  <DraggableItem key={discipline.id} id={discipline.subject.id} name={discipline.subject.name} />
                ))}
              </List>
            )}

            <List sx={{ width: '100%', maxWidth: 360, marginTop: "15px", bgcolor: 'background.paper', padding: "10px" }}>
              <Typography variant="h3"> Adicionar Sala de Aula </Typography>
              <p style={{ color: "#42474B", fontSize: "12px" }}> Arraste para adicionar </p>
              <Grid container spacing={1}>
                {classrooms.map((classroom, index) => (
                  <Grid item xs={2} sm={5} md={5} key={index}>
                    <DraggableItem
                      key={classroom.id}
                      id={classroom.name}
                      name={classroom.name}
                    />
                  </Grid>
                ))}
              </Grid>
            </List>

          </Grid>
          )}
        </Grid>
      </DndContext>

    </div>
  );
};

export default ScheduleTable;
